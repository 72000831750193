import React, { useCallback } from 'react';

import { Stack, Text, useDisclosure } from '@chakra-ui/react';
import { CalendarRangeIcon } from 'lucide-react';

import {
  AnalyticsJobTimePeriodType,
  DataExportTimePeriodType,
} from '@m3ter-com/m3ter-api';
import { Button } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { UsageQueryBuilderSection } from '@/components/features/usage/query-builder/UsageQueryBuilderSection/UsageQueryBuilderSection';
import {
  UsageQueryBuilderMenu,
  UsageQueryBuilderMenuButton,
} from '@/components/features/usage/query-builder/UsageQueryBuilderMenu/UsageQueryBuilderMenu';

export type TimePeriodType =
  | AnalyticsJobTimePeriodType
  | DataExportTimePeriodType;

const nonCustomTimePeriods: Array<AnalyticsJobTimePeriodType> = Object.values(
  AnalyticsJobTimePeriodType
).filter((period) => period !== AnalyticsJobTimePeriodType.Custom);

export interface UsageQueryBuilderRelativeTimePeriodProps {
  value: TimePeriodType;
  onChange: (value: TimePeriodType) => void;
  periods?: Array<TimePeriodType>;
}

export const UsageQueryBuilderRelativeTimePeriod: React.FC<
  UsageQueryBuilderRelativeTimePeriodProps
> = ({ value, onChange, periods = nonCustomTimePeriods }) => {
  const { t } = useTranslation();

  const { isOpen, onClose, onToggle } = useDisclosure();

  const onSelect = useCallback(
    (period: TimePeriodType) => {
      onChange(period);
      onClose();
    },
    [onChange, onClose]
  );

  return (
    <UsageQueryBuilderSection
      colorScheme="blue"
      title={t('forms:labels.timePeriod')}
      icon={CalendarRangeIcon}
    >
      <UsageQueryBuilderMenu
        isOpen={isOpen}
        onClose={onClose}
        trigger={
          <Button
            size="sm"
            colorScheme="blue"
            variant="subtle"
            onClick={onToggle}
          >
            <Text fontWeight="semibold">
              {t(`features:dataExplorer.timePeriod.${value}`)}
            </Text>
          </Button>
        }
      >
        <Stack>
          {periods.map((period) => (
            <UsageQueryBuilderMenuButton
              key={period}
              onClick={() => onSelect(period)}
            >
              {t(`features:dataExplorer.timePeriod.${period}`)}
            </UsageQueryBuilderMenuButton>
          ))}
        </Stack>
      </UsageQueryBuilderMenu>
    </UsageQueryBuilderSection>
  );
};
