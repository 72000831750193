import { ReactElement, useCallback } from 'react';

import { HStack, Stack } from '@chakra-ui/react';
import { RefreshCwIcon } from 'lucide-react';

import { DataType, Entity, SearchSortOrder } from '@m3ter-com/m3ter-api';
import {
  DataGrid,
  DataGridColumnDefinition,
  IconButton,
} from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BaseEntityListProps } from '@/types/lists';
import { SearchFieldDefinition } from '@/types/search';

import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { EntityListEmptyContent } from '@/components/common/data/EntityListEmptyContent/EntityListEmptyContent';
import { EntityListFooter } from '@/components/common/data/EntityListFooter/EntityListFooter';

import useEntitySearchList from './useEntitySearchList';
import { EntitySearchListSearch } from './EntitySearchListSearch';

// Re-export to avoid needing to import from UI components.
export type ColumnDefinition<E extends Entity = Entity> =
  DataGridColumnDefinition<E>;

export interface EntitySearchListProps<DT extends DataType>
  extends BaseEntityListProps<DT> {
  searchFieldDefinitions: Array<SearchFieldDefinition>;
  defaultSortBy?: string;
  defaultSortOrder?: SearchSortOrder;
  relationships?: Array<string>;
}

export function EntitySearchList<DT extends DataType>({
  dataType,
  columnDefinitions,
  searchFieldDefinitions,
  defaultSortBy,
  defaultSortOrder,
  relationships,
  headerActions,
  footerActions,
  styleProps,
  ...dataGridProps
}: EntitySearchListProps<DT>): ReactElement<any, any> {
  const { t } = useTranslation();

  const entityNamings = useEntityNamings(dataType);

  const {
    columnDisplay,
    criteria,
    currentPage,
    currentPageData,
    error,
    goToNextPage,
    goToPage,
    hasMore,
    isFetching,
    knownPageCount,
    onColumnDisplayChange,
    onPageSizeChange,
    onSortChange,
    operator,
    pageSize,
    refetch,
    setCriteria,
    setOperator,
    sortBy,
    sortOrder,
  } = useEntitySearchList({
    dataType,
    relationships,
    defaultSortBy,
    defaultSortOrder,
  });

  const clearFilters = useCallback(() => {
    setCriteria([]);
  }, [setCriteria]);

  return (
    <Stack spacing={4} {...styleProps}>
      {error && <ErrorAlert error={error} />}
      <HStack spacing={4} alignItems="start">
        <EntitySearchListSearch
          criteria={criteria}
          onCriteriaChange={setCriteria}
          operator={operator}
          onOperatorChange={setOperator}
          searchFieldDefinitions={searchFieldDefinitions}
          placeholder={t('common:searchEntity', {
            entity: entityNamings.pluralLower,
          })}
          flex={1}
        />
        <IconButton
          aria-label={t('common:refresh')}
          icon={<RefreshCwIcon size={16} />}
          isDisabled={isFetching}
          onClick={() => {
            refetch();
          }}
        />
        {headerActions}
      </HStack>
      <DataGrid
        {...dataGridProps}
        isLoading={isFetching}
        items={currentPageData}
        columnDefinitions={columnDefinitions}
        idAccessor="id"
        emptyContent={
          <EntityListEmptyContent
            dataType={dataType}
            isSearching={criteria.length > 0}
            onClearSearch={clearFilters}
          />
        }
        // Sorting
        sortColumn={sortBy}
        sortDescending={sortOrder === 'DESC'}
        onSortChange={onSortChange}
        // Column settings
        enableColumnOrdering
        enableColumnToggling
        columnDisplay={columnDisplay}
        onColumnDisplayChange={onColumnDisplayChange}
        // Styling
        flex={1}
        borderRadius="md"
      />
      <EntityListFooter
        currentPage={currentPage}
        goToNextPage={goToNextPage}
        goToPage={goToPage}
        hasMore={hasMore}
        knownPageCount={knownPageCount}
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
        actions={footerActions}
      />
    </Stack>
  );
}
