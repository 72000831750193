import React from 'react';

import { Balance } from '@m3ter-com/m3ter-api';

import { CrudRetrieve } from '@/components/common/crud/CrudRetrieve/CrudRetrieve';

const relationships = ['products'];

export const BalanceRetrieveRoute: React.FC = () => (
  <CrudRetrieve<Balance> relationships={relationships} titleAccessor="name" />
);
