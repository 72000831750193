import { Checkbox, Radio } from '@chakra-ui/react';

import type {
  SelectionType,
  SelectionControlProps as BaseSelectionControlProps,
} from '../../../types/tables';

export interface SelectionControlProps extends BaseSelectionControlProps {
  selectionType: SelectionType;
}

export const SelectionControl: React.FC<SelectionControlProps> = ({
  selectionType,
  ...controlProps
}) =>
  selectionType === 'multi' ? (
    <Checkbox {...controlProps} />
  ) : (
    <Radio {...controlProps} />
  );
