import { useMemo } from 'react';

import merge from 'lodash/merge';

import { DataType, IntegrationConfig } from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Form, FormActions } from '@m3ter-com/console-core/components';
import { FormStack } from '@m3ter-com/ui-components';

import { BaseFormProps } from '@/types/forms';

import validationSchema from '@/validation/integrationConfig';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext/IntegrationConfigSchemaContext';

import { IntegrationConfigFormConfigureFields } from './IntegrationConfigFormConfigureFields';

interface IntegrationConfigEditFormProps
  extends BaseFormProps<IntegrationConfig> {}

export const IntegrationConfigForm: React.FC<
  IntegrationConfigEditFormProps
> = ({ isEdit, isSaving, initialValues, onCancel, onSave }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Integration);

  const { configSchema } = useIntegrationConfigSchemaContext();

  const defaultedInitialValues = useMemo<DeepPartial<IntegrationConfig>>(() => {
    const defaultIntegrationConfig: DeepPartial<IntegrationConfig> = {
      configData: {},
    };

    // The integration config schema contains default values for some pieces of the
    // configData.
    // So, we need to go through all the parameters and set the default values when creating
    // a brand new integration config.
    if (!isEdit) {
      // First we go through the global parameters
      configSchema.globalConfigurationOptions.forEach((parameterSchema) => {
        if (Object.hasOwn(parameterSchema, 'defaultValue')) {
          defaultIntegrationConfig.configData![parameterSchema.name] =
            parameterSchema.defaultValue;
        }
      });

      if (initialValues?.entityType) {
        const entitySchema =
          configSchema.supportedEntities[initialValues.entityType];
        if (entitySchema) {
          // If we have an entity type and it matches a supported entity, we can go through
          // the entity-specific parameters and set those default values
          entitySchema.entityConfigurationOptions?.forEach(
            (parameterSchema) => {
              if (Object.hasOwn(parameterSchema, 'defaultValue')) {
                defaultIntegrationConfig.configData![parameterSchema.name] =
                  parameterSchema.defaultValue;
              }
            }
          );

          if (initialValues?.destination) {
            const destinationSchema = entitySchema.destinations.find(
              (d) => d.destination === initialValues.destination
            );
            if (destinationSchema) {
              // Finally, iff we have a destination and it matches a supported destination for
              // this entity type, we can go through the destination-specific parameters and set
              // those default values
              (destinationSchema.destinationConfigOptions || []).forEach(
                (parameterSchema) => {
                  if (Object.hasOwn(parameterSchema, 'defaultValue')) {
                    defaultIntegrationConfig.configData![parameterSchema.name] =
                      parameterSchema.defaultValue;
                  }
                }
              );
            }
          }
        }
      }
    }

    // Merge the default config we've built with any provided initialValues
    return merge(defaultIntegrationConfig, initialValues);
  }, [configSchema, initialValues, isEdit]);

  return (
    <Form
      onSubmit={onSave}
      initialValues={defaultedInitialValues}
      validationSchema={validationSchema}
    >
      <FormStack>
        <IntegrationConfigFormConfigureFields
          destination={defaultedInitialValues.destination ?? ''}
          entityType={defaultedInitialValues.entityType ?? ''}
        />
        <FormActions
          cancelText={t('common:cancel')}
          submitText={t(
            isEdit
              ? 'forms:buttons.updateEntity'
              : 'forms:buttons.createEntity',
            {
              entityName: entityNamings.singularLower,
            }
          )}
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
