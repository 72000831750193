import React, { PropsWithChildren } from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import { DataType, PlanTemplate } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import {
  getBillInAdvanceDescription,
  getFrequencyDescription,
  getIntervalAndOffsetDescription,
} from '@/util/billing';
import useCurrencies from '@/hooks/util/useCurrencies';
import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';
import { PlanTemplateOrOrgConfigValue } from '@/components/features/pricing/PlanDetails/PlanTemplateOrOrgConfigValue';

interface PlanTemplateDetailsProps {
  planTemplate: PlanTemplate;
  showEditButton?: boolean;
  showMetadata?: boolean;
}

export const PlanTemplateDetails: React.FC<
  PropsWithChildren<PlanTemplateDetailsProps>
> = ({ children, planTemplate, showEditButton, showMetadata }) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();

  const {
    currency,
    standingCharge,
    standingChargeInterval,
    standingChargeOffset,
    billFrequencyInterval,
    billFrequency,
    minimumSpend,
  } = planTemplate;

  return (
    <DetailsCard
      data={planTemplate}
      dataType={DataType.PlanTemplate}
      showEditButton={showEditButton}
      showMetadata={showMetadata}
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={planTemplate.name} />
          <KeyValue
            label={t('forms:labels.code')}
            value={planTemplate.code ?? '-'}
          />
          {planTemplate.productId && (
            <KeyValue
              label={t('common:product')}
              value={
                <ReferenceLink
                  dataType={DataType.Product}
                  id={planTemplate.productId}
                  accessor="name"
                />
              }
            />
          )}
          <KeyValue label={t('forms:labels.currency')} value={currency} />
          <KeyValue
            label={t('forms:labels.billFrequency')}
            value={getFrequencyDescription(
              billFrequencyInterval,
              billFrequency
            )}
          />
          <KeyValue
            label={t('forms:labels.productMinimumSpend')}
            value={formatCurrency(minimumSpend ?? 0, currency)}
          />
          <KeyValue
            label={t('forms:labels.minimumSpendBilling')}
            value={
              <PlanTemplateOrOrgConfigValue
                fieldName="minimumSpendBillInAdvance"
                format={getBillInAdvanceDescription}
                planTemplate={planTemplate}
              />
            }
          />
          <KeyValue
            label={t('forms:labels.minimumSpendDescription')}
            value={planTemplate.minimumSpendDescription || '-'}
          />
          <KeyValue
            label={t('forms:labels.standingCharge')}
            value={formatCurrency(standingCharge, currency)}
            gridColumn={1}
          />
          <KeyValue
            label={t('forms:labels.standingChargeBilling')}
            value={getIntervalAndOffsetDescription(
              standingChargeInterval,
              standingChargeOffset
            )}
          />
          <KeyValue
            label={t('forms:labels.standingChargeBilling')}
            value={
              <PlanTemplateOrOrgConfigValue
                fieldName="standingChargeBillInAdvance"
                format={getBillInAdvanceDescription}
                planTemplate={planTemplate}
              />
            }
          />
          <KeyValue
            label={t('forms:labels.standingChargeDescription')}
            value={planTemplate.standingChargeDescription || '-'}
          />
        </SimpleGrid>
      }
    >
      {children}
    </DetailsCard>
  );
};
