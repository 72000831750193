import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@chakra-ui/react';

import { DataType, Id } from '@m3ter-com/m3ter-api';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import useQueryString from '@/hooks/navigation/useQueryString';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { NamedNavigate } from '@/components/common/navigation/NamedNavigate/NamedNavigate';

interface IntegrationRunQueryParams {
  entityId: Id;
  entityType: string;
}

export const IntegrationRunDetailsRedirectRoute: React.FC = () => {
  const { entityType, entityId } = useQueryString<IntegrationRunQueryParams>();

  const latestRunPathParams = useOrgPathParams({ entityId, entityType });
  const { data: latestIntegrationRun } = useQuery(
    dataTypeRetrieveQuery(
      {
        dataType: DataType.IntegrationRun,
        actionName: 'getLatestEntityRun',
        pathParams: latestRunPathParams,
      },
      { enabled: !!entityId }
    )
  );

  const detailsRouteName = getCrudRouteName(
    DataType.IntegrationRun,
    CrudRouteType.Details
  );

  return latestIntegrationRun ? (
    <NamedNavigate
      replace
      name={detailsRouteName}
      params={{
        integrationRunId: latestIntegrationRun?.id,
      }}
    />
  ) : (
    <Spinner />
  );
};
