import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { DataType } from '@m3ter-com/m3ter-api';
import { Button } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { dataTypeRetrieveQuery } from '@/queries/crud';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { PageWithBreadcrumbs } from '@/components/common/breadcrumbs/PageWithBreadcrumbs/PageWithBreadcrumbs';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import { CrudEditLink } from '@/components/common/navigation/CrudEditLink/CrudEditLink';
import { ProductDetails } from '@/components/features/products/ProductDetails/ProductDetails';

export const ProductsDetailsRoute: React.FC = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const { singularLower } = useEntityNamings(DataType.Product);

  const pathParams = useOrgPathParams();
  const {
    isLoading,
    data: product,
    error,
  } = useQuery(
    dataTypeRetrieveQuery({
      dataType: DataType.Product,
      id: productId,
      pathParams,
    })
  );

  return (
    <LoadingErrorContentSwitch
      isLoading={isLoading}
      error={error}
      errorTitle={t('errors:generic.problemLoadingData', {
        entityName: singularLower,
      })}
    >
      {product && (
        <PageWithBreadcrumbs
          title={product.name}
          actions={
            <Button
              intent="primary"
              as={CrudEditLink}
              dataType={DataType.Product}
              id={product.id}
            >
              {t('common:edit')}
            </Button>
          }
        >
          <ProductDetails data={product} />
        </PageWithBreadcrumbs>
      )}
    </LoadingErrorContentSwitch>
  );
};
