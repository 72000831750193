import { Fragment, useMemo } from 'react';

import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';

import { DataType, IntegrationConfig } from '@m3ter-com/m3ter-api';
import { useFormError, useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, SelectOption } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormAddRowButton,
  FormMultiRowGridWrapper,
  FormMultiRowWrapper,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import { createStepTwo } from '@/validation/externalMappings';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { ExternalMappingM3terIdField } from '@/components/features/integrations/ExternalMappingM3terIdField/ExternalMappingM3terIdField';
import { ExternalMappingExternalIdField } from '@/components/features/integrations/ExternalMappingExternalIdField/ExternalMappingExternalIdField';

import { ExternalMappingsCreateFormValues } from './useExternalMappingsCreate';
import { ExternalMappingsFormSettingsDisplay } from './ExternalMappingsFormSettingsDisplay';
import { ExternalMappingsCreateFormSettingsStepValues } from './ExternalMappingsCreateFormSettingsStep';

export enum ExternalMappingsCreateMode {
  Bulk = 'BULK',
  Single = 'SINGLE',
}

export type ExternalMappingsCreateFormIdsStepValues = Pick<
  ExternalMappingsCreateFormValues,
  'mappings'
>;

interface ExternalMappingsCreateFormIdsStepFieldsProps {
  isSaving: boolean;
  linkedIntegration?: IntegrationConfig;
  settings: ExternalMappingsCreateFormSettingsStepValues;
  onBack: () => void;
  onCancel?: () => void;
}

export interface ExternalMappingsCreateFormIdsStepProps
  extends BaseFormProps<ExternalMappingsCreateFormIdsStepValues> {
  linkedIntegration?: IntegrationConfig;
  mode: ExternalMappingsCreateMode;
  settings?: ExternalMappingsCreateFormSettingsStepValues;
  onBack: () => void;
}

const MAX_MAPPINGS = 20;

const ExternalMappingsCreateFormBulkIdFields: React.FC<
  ExternalMappingsCreateFormIdsStepFieldsProps
> = ({ isSaving, linkedIntegration, onBack, onCancel, settings }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExternalMapping);

  const { control } = useFormContext<ExternalMappingsCreateFormIdsStepValues>();
  const mappingsFieldName = 'mappings';
  const { fields, append, remove } =
    useFieldArray<ExternalMappingsCreateFormIdsStepValues>({
      control,
      name: mappingsFieldName,
    });
  const { isInvalid: isRootFieldInvalid, message: rootFieldErrorMessage } =
    useFormError(mappingsFieldName);

  return (
    <Fragment>
      <FormStack>
        <ExternalMappingsFormSettingsDisplay
          externalSystem={settings.externalSystem}
          externalTable={settings.externalTable}
          linkedIntegration={linkedIntegration}
          m3terEntity={settings.m3terEntity}
        />
      </FormStack>
      <FormSection
        heading={`${t('common:externalMappings')} ${t(
          'forms:labels.maximumWithNumber',
          {
            max: MAX_MAPPINGS,
          }
        )}`}
        my={6}
      >
        <FormMultiRowWrapper
          emptyContentMessage={t('features:integrations.noExternalMappings')}
          hasFields={fields.length > 0}
        >
          {fields.map((field, fieldIndex) => {
            const isFirstField = fieldIndex === 0;
            const canRemove = !isFirstField;
            const usedM3terIds = fields
              .map((f) => f.m3terId)
              .filter((m3terId, i) => !!m3terId && i !== fieldIndex);
            const m3terIdOptionsFilter = (option: SelectOption) =>
              !usedM3terIds.includes(option.value);

            return (
              <FormMultiRowGridWrapper
                key={field.id}
                alignItems="end"
                columnCount={2}
                onRemove={() => remove(fieldIndex)}
              >
                <ExternalMappingM3terIdField
                  entityType={settings.m3terEntity}
                  externalSystem={settings.externalSystem}
                  externalTable={settings.externalTable}
                  hideLabel={canRemove}
                  name={`mappings.${fieldIndex}.m3terId`}
                  optionsFilter={m3terIdOptionsFilter}
                />
                <ExternalMappingExternalIdField
                  externalSystem={settings.externalSystem}
                  hideLabel={canRemove}
                  integrationCredentialId={
                    linkedIntegration?.integrationCredentialsId
                  }
                  m3terEntityType={settings.m3terEntity}
                  name={`mappings.${fieldIndex}.externalId`}
                />
              </FormMultiRowGridWrapper>
            );
          })}
        </FormMultiRowWrapper>
        <FormAddRowButton
          isDisabled={fields.length >= MAX_MAPPINGS}
          maxRows={MAX_MAPPINGS}
          onAdd={() => {
            append({});
          }}
        />
        {isRootFieldInvalid && (
          <FormControl isInvalid={isRootFieldInvalid}>
            <FormErrorMessage>{rootFieldErrorMessage}</FormErrorMessage>
          </FormControl>
        )}
      </FormSection>
      <FormActions
        backText={t('common:back')}
        cancelText={t('common:cancel')}
        isSaving={isSaving}
        onBack={onBack}
        onCancel={onCancel}
        submitText={t('forms:buttons.createEntity', {
          entityName: entityNamings.singularLower,
        })}
      />
    </Fragment>
  );
};

const ExternalMappingsCreateFormIdsStepSingleIdFields: React.FC<
  ExternalMappingsCreateFormIdsStepFieldsProps
> = ({ isSaving, linkedIntegration, onBack, onCancel, settings }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExternalMapping);

  return (
    <FormStack>
      <ExternalMappingsFormSettingsDisplay
        externalSystem={settings.externalSystem}
        externalTable={settings.externalTable}
        linkedIntegration={linkedIntegration}
        m3terEntity={settings.m3terEntity}
      />
      <FormSection
        heading={t('common:entityDetails', {
          entityName: entityNamings.singular,
        })}
      >
        <ExternalMappingM3terIdField
          entityType={settings.m3terEntity}
          externalSystem={settings.externalSystem}
          externalTable={settings.externalTable}
          name="mappings.0.m3terId"
        />
        <ExternalMappingExternalIdField
          externalSystem={settings.externalSystem}
          integrationCredentialId={linkedIntegration?.integrationCredentialsId}
          m3terEntityType={settings.m3terEntity}
          name="mappings.0.externalId"
        />
      </FormSection>
      <FormActions
        backText={t('common:back')}
        cancelText={t('common:cancel')}
        isSaving={isSaving}
        onBack={onBack}
        onCancel={onCancel}
        submitText={t('forms:buttons.createEntity', {
          entityName: entityNamings.singularLower,
        })}
      />
    </FormStack>
  );
};

export const ExternalMappingsCreateFormIdsStep: React.FC<
  ExternalMappingsCreateFormIdsStepProps
> = ({
  initialValues,
  isSaving,
  linkedIntegration,
  mode,
  onBack,
  onCancel,
  onSave,
  settings,
}) => {
  const defaultedInitialValues = useMemo(
    () => ({
      mappings: initialValues?.mappings || [],
    }),
    [initialValues]
  );

  return (
    <Form
      initialValues={defaultedInitialValues}
      onSubmit={onSave}
      validationSchema={createStepTwo}
    >
      {!!settings && mode === ExternalMappingsCreateMode.Bulk && (
        <ExternalMappingsCreateFormBulkIdFields
          isSaving={isSaving}
          onBack={onBack}
          onCancel={onCancel}
          settings={settings}
        />
      )}
      {!!settings && mode === ExternalMappingsCreateMode.Single && (
        <ExternalMappingsCreateFormIdsStepSingleIdFields
          isSaving={isSaving}
          linkedIntegration={linkedIntegration}
          onBack={onBack}
          onCancel={onCancel}
          settings={settings}
        />
      )}
    </Form>
  );
};
