import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const parts = [
  'wrapper',
  'header',
  'grid',
  'row',
  'cell',
  'details',
  'sidebar',
  'sidebarButton',
  'footer',
  'loading',
  'empty',
  'settings',
];

const dataGrid: ComponentMultiStyleConfig = {
  parts,
  baseStyle: (props) => {
    const headBg = mode('gray.100', 'gray.800')(props);

    const rowBaseStyle = {
      gridColumn: '1 / -1',
      display: 'grid',
      gridTemplateColumns: 'subgrid',
      borderBottomWidth: 1,
      alignItems: 'stretch',
    };

    return {
      wrapper: {
        display: 'flex',
        borderWidth: 1,
        overflow: 'hidden',
        position: 'relative',
      },
      grid: {
        display: 'grid',
        alignContent: 'start', // Prevents the grid filling a fixed height.
        flex: 1,
        overflow: 'scroll',
        position: 'relative',
        minH: '120px',
        // Remove double borders. Needs the hidden overflow on the wrapper.
        marginRight: '-1px',
        marginBottom: '-1px',
      },
      head: {
        ...rowBaseStyle,
        position: 'sticky',
        top: 0,
        zIndex: 2,
        fontWeight: 'bold',
        '[role=columnheader]': {
          bg: headBg,
        },
      },
      row: {
        ...rowBaseStyle,
        '[role=cell]': {
          bg: mode('gray.50', 'gray.600')(props),
        },
        '&:nth-of-type(even) [role=cell]': {
          bg: mode('white', 'gray.700')(props),
        },
        _hover: {
          '[role=cell]': {
            bg: 'misc-hover',
          },
        },
      },
      details: {
        gridColumn: '2 / -1',
        padding: 2,
        borderTopWidth: 1,
      },
      cell: {
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        borderRightWidth: 1,
      },
      sidebar: {
        position: 'relative',
        padding: 1,
        borderLeftWidth: 1,
        bg: headBg,
        zIndex: 2,
      },
      sidebarButton: {
        height: 'auto',
        writingMode: 'vertical-lr',
      },
      settings: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: '100%',
        overflowY: 'scroll',
        borderInlineWidth: 1,
        marginRight: 0,
        paddingX: 4,
        paddingY: 2,
        bg: headBg,
      },
      loading: {
        position: 'absolute',
        inset: 0,
        top: 10, // Push down below header.
        display: 'flex',
        backgroundColor: mode('whiteAlpha.600', 'blackAlpha.600')(props),
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
      },
      empty: {
        gridColumn: '1 / -1',
        padding: 4,
      },
    };
  },
};

export default dataGrid;
