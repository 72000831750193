import React, { useCallback, useMemo } from 'react';

import {
  Box,
  Card,
  CardBody,
  Grid,
  Heading,
  Link,
  Text,
  Stack,
  StackDivider,
  ButtonGroup,
  Flex,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { isFuture } from 'date-fns';
import { RefreshCwIcon } from 'lucide-react';

import {
  DataType,
  BalanceTransactionSchedule,
  Balance,
  BalanceChargeSchedule,
} from '@m3ter-com/m3ter-api';
import {
  Button,
  CardActionsHeader,
  IconButton,
  KeyValue,
} from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { getDatesSortOrder } from '@m3ter-com/console-core/utils';

import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { CrudListLink } from '@/components/common/navigation/CrudListLink/CrudListLink';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useCurrencies from '@/hooks/util/useCurrencies';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useEntityDeleteMutation from '@/hooks/data/useEntityDeleteMutation';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import { getFrequencyDescription } from '@/util/billing';
import { dataTypeListQuery } from '@/queries/crud';

interface BalanceSchedulesProps {
  balance: Balance;
}

export const BalanceSchedules: React.FC<BalanceSchedulesProps> = ({
  balance,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDateTime } = useDateFormatter();
  const transactionScheduleNamings = useEntityNamings(
    DataType.BalanceTransactionSchedule
  );
  const chargeScheduleNamings = useEntityNamings(
    DataType.BalanceChargeSchedule
  );
  const { deleteEntity: deleteChargeSchedule } = useEntityDeleteMutation(
    DataType.BalanceChargeSchedule
  );

  const onDeleteChargeSchedule = useCallback(
    (item: BalanceChargeSchedule) => {
      deleteChargeSchedule({
        entity: item,
        pathParams: { balanceId: balance.id },
      });
    },
    [balance, deleteChargeSchedule]
  );

  const { deleteEntity: deleteTransactionSchedule } = useEntityDeleteMutation(
    DataType.BalanceTransactionSchedule
  );

  const onDeleteTransactionSchedule = useCallback(
    (item: BalanceTransactionSchedule) => {
      deleteTransactionSchedule({
        entity: item,
        pathParams: { balanceId: balance.id },
      });
    },
    [balance, deleteTransactionSchedule]
  );

  const pathParams = useOrgPathParams({ balanceId: balance.id });

  const {
    error: chargeSchedulesError,
    isLoading: isLoadingChargeSchedules,
    data: chargeSchedules = [],
    refetch: refetchChargeSchedules,
    isFetching: isFetchingChargeSchedules,
  } = useQuery(
    dataTypeListQuery({ dataType: DataType.BalanceChargeSchedule, pathParams })
  );

  const {
    error: transactionSchedulesError,
    isLoading: isLoadingTransactionSchedules,
    data: transactionSchedules = [],
    refetch: refetchTransactionSchedules,
    isFetching: isFetchingTransactionSchedules,
  } = useQuery(
    dataTypeListQuery({
      dataType: DataType.BalanceTransactionSchedule,
      pathParams,
    })
  );

  const refreshList = useCallback(() => {
    refetchChargeSchedules();
    refetchTransactionSchedules();
  }, [refetchChargeSchedules, refetchTransactionSchedules]);

  const sortedTransactionSchedule = useMemo<Array<BalanceTransactionSchedule>>(
    () =>
      transactionSchedules
        .filter((schedule) => isFuture(new Date(schedule.endDate)))
        .sort((a, b) => getDatesSortOrder(a.startDate, b.startDate)),
    [transactionSchedules]
  );

  const sortedChargeSchedules = useMemo<Array<BalanceChargeSchedule>>(
    () =>
      chargeSchedules
        .filter((schedule) => isFuture(new Date(schedule.servicePeriodEndDate)))
        .sort((a, b) =>
          getDatesSortOrder(a.servicePeriodStartDate, b.servicePeriodStartDate)
        ),
    [chargeSchedules]
  );

  const params = useMemo(() => ({ balanceId: balance.id }), [balance]);

  return (
    <Card>
      <CardActionsHeader
        actions={
          <ButtonGroup size="sm">
            <Button
              as={CrudCreateLink}
              addReturnPath
              dataType={DataType.BalanceTransactionSchedule}
            >
              {t('forms:buttons.createEntity', {
                entityName: t(
                  'features:balanceSchedules.transactionSchedule'
                ).toLowerCase(),
              })}
            </Button>
            <Button
              as={CrudCreateLink}
              addReturnPath
              dataType={DataType.BalanceChargeSchedule}
            >
              {t('forms:buttons.createEntity', {
                entityName: t(
                  'features:balanceSchedules.chargeSchedule'
                ).toLowerCase(),
              })}
            </Button>
            <IconButton
              aria-label={t('common:refresh')}
              icon={<RefreshCwIcon size={16} />}
              onClick={refreshList}
            />
          </ButtonGroup>
        }
      >
        <Heading size="md">
          {t('features:balanceSchedules.activeAndPendingBalanceSchedules')}
        </Heading>
      </CardActionsHeader>
      <CardBody>
        <Stack spacing={4} divider={<StackDivider />}>
          <Box>
            <Flex width="100%" justify="space-between" alignItems="flex-end">
              <Heading mb={4} size="sm">
                {transactionScheduleNamings.plural}
              </Heading>
              <Link
                as={CrudListLink}
                dataType={DataType.BalanceTransactionSchedule}
              >
                {t('common:viewAll')}
              </Link>
            </Flex>
            <LoadingErrorContentSwitch
              isLoading={
                isLoadingTransactionSchedules || isFetchingTransactionSchedules
              }
              error={transactionSchedulesError}
            >
              <Stack spacing={4} divider={<StackDivider />}>
                {sortedTransactionSchedule.length > 0 ? (
                  sortedTransactionSchedule.map((schedule) => (
                    <Grid
                      key={schedule.id}
                      gap={4}
                      gridTemplateColumns="repeat(5, 1fr) auto"
                    >
                      <KeyValue
                        label={t('forms:labels.name')}
                        value={
                          <Link
                            as={CrudDetailsLink}
                            dataType={DataType.BalanceTransactionSchedule}
                            id={schedule.id}
                            params={params}
                          >
                            {schedule.name}
                          </Link>
                        }
                      />
                      <KeyValue
                        label={t('forms:labels.startDateInclusive')}
                        value={toLongDateTime(schedule.startDate)}
                      />
                      <KeyValue
                        label={t('forms:labels.endDateExclusive')}
                        value={toLongDateTime(schedule.endDate)}
                      />
                      <KeyValue
                        label={t('forms:labels.frequency')}
                        value={getFrequencyDescription(
                          schedule.frequencyInterval,
                          schedule.frequency
                        )}
                      />
                      <KeyValue
                        label={t('forms:labels.amount')}
                        value={formatCurrency(
                          schedule.amount,
                          balance.currency
                        )}
                      />
                      <EntityCrudActions<BalanceTransactionSchedule>
                        addReturnPath
                        dataType={DataType.BalanceTransactionSchedule}
                        item={schedule}
                        editRouteName={getCrudRouteName(
                          DataType.BalanceTransactionSchedule,
                          CrudRouteType.Edit
                        )}
                        onDelete={onDeleteTransactionSchedule}
                      />
                    </Grid>
                  ))
                ) : (
                  <Text textAlign="center">
                    {t('errors:generic.noDataToDisplay', {
                      entityName: transactionScheduleNamings.pluralLower,
                    })}
                  </Text>
                )}
              </Stack>
            </LoadingErrorContentSwitch>
          </Box>
          <Box>
            <Flex width="100%" justify="space-between" alignItems="flex-end">
              <Heading mb={4} size="sm">
                {chargeScheduleNamings.plural}
              </Heading>
              <Link as={CrudListLink} dataType={DataType.BalanceChargeSchedule}>
                {t('common:viewAll')}
              </Link>
            </Flex>
            <LoadingErrorContentSwitch
              isLoading={isLoadingChargeSchedules || isFetchingChargeSchedules}
              error={chargeSchedulesError}
            >
              <Stack spacing={4} divider={<StackDivider />}>
                {sortedChargeSchedules.length > 0 ? (
                  sortedChargeSchedules.map((schedule) => (
                    <Grid
                      key={schedule.id}
                      gap={4}
                      gridTemplateColumns="repeat(5, 1fr) auto"
                    >
                      <KeyValue
                        label={t('forms:labels.name')}
                        value={
                          <Link
                            as={CrudDetailsLink}
                            dataType={DataType.BalanceChargeSchedule}
                            id={schedule.id}
                            params={params}
                          >
                            {schedule.name}
                          </Link>
                        }
                      />
                      <KeyValue
                        label={t('forms:labels.servicePeriodStart')}
                        value={toLongDateTime(schedule.servicePeriodStartDate)}
                      />
                      <KeyValue
                        label={t('forms:labels.servicePeriodEnd')}
                        value={toLongDateTime(schedule.servicePeriodEndDate)}
                      />
                      <KeyValue
                        label={t('forms:labels.billFrequency')}
                        value={getFrequencyDescription(
                          schedule.billFrequencyInterval,
                          schedule.billFrequency
                        )}
                      />
                      <KeyValue
                        label={t('forms:labels.amount')}
                        value={formatCurrency(
                          schedule.amount,
                          schedule.currency
                        )}
                      />
                      <EntityCrudActions<BalanceChargeSchedule>
                        addReturnPath
                        dataType={DataType.BalanceChargeSchedule}
                        item={schedule}
                        editRouteName={getCrudRouteName(
                          DataType.BalanceChargeSchedule,
                          CrudRouteType.Edit
                        )}
                        onDelete={onDeleteChargeSchedule}
                      />
                    </Grid>
                  ))
                ) : (
                  <Text textAlign="center">
                    {t('errors:generic.noDataToDisplay', {
                      entityName: chargeScheduleNamings.pluralLower,
                    })}
                  </Text>
                )}
              </Stack>
            </LoadingErrorContentSwitch>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};
