import { ReactNode, useMemo, useState } from 'react';

import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  Link,
  Radio,
  RadioGroup,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { CircleCheckIcon } from 'lucide-react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { getAvailableIntegrationDestinationSchemas } from '@/util/integrations';
import { useIntegrationSystemsContext } from '@/components/features/integrations/IntegrationSystemsContext/IntegrationSystemsContext';
import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';
import { ExternalSystemLogo } from '@/components/common/brand/ExternalSystemLogo/ExternalSystemLogo';

export const IntegrationSystemsGrid: React.FC = () => {
  const { t } = useTranslation();
  const { configs, configSchema, credentials } = useIntegrationSystemsContext();

  const availableSystemSchemas = useMemo(() => {
    const available = getAvailableIntegrationDestinationSchemas(configSchema);
    const availableWithConfiguredChecks = available.map((systemSchema) => {
      const hasConfiguredCredentials = credentials.some(
        (credential) => credential.destination === systemSchema.apiName
      );
      const hasConfiguredConfigs = configs.some(
        (config) =>
          config.destination.toLowerCase() ===
          systemSchema.destination.toLowerCase()
      );
      return {
        ...systemSchema,
        hasConfiguredCredentials,
        hasConfiguredConfigs,
      };
    });
    // Split the available system schemas by whether or not credentials / configs
    // exist for that system.
    const [availableAndConfigured, availableAndUnconfigured] = partition(
      availableWithConfiguredChecks,
      (systemSchema) =>
        systemSchema.hasConfiguredCredentials ||
        systemSchema.hasConfiguredConfigs
    );
    // Return the system schemas that have credentials / configs first, then the rest,
    // with both ordered alphabetically.
    // This makes sure that it's easier to go into a system which you've already set up because
    // they'll be the first systems in the grid.
    return [
      ...orderBy(availableAndConfigured, 'apiName'),
      ...orderBy(availableAndUnconfigured, 'apiName'),
    ];
  }, [configSchema, configs, credentials]);

  const [categoryFilter, setCategoryFilter] = useState<string>('');
  const availableSystemCategories = useMemo(
    () =>
      uniq(
        availableSystemSchemas.flatMap((schema) => schema.categories || [])
      ).sort(),
    [availableSystemSchemas]
  );

  const systemDescriptionsMap = useMemo<Partial<Record<string, ReactNode>>>(
    () => ({
      chargebee: (
        <Text>
          {t('features:integrations.integrationSystemDescriptions.chargebee')}
          <br />
          <Link
            isExternal
            href="https://www.m3ter.com/docs/guides/integrations/setting-up-native-integrations/configuring-chargebee-integrations"
          >
            {t('common:readDocs')}
          </Link>
        </Text>
      ),
      netsuite: (
        <Text>
          {t('features:integrations.integrationSystemDescriptions.netsuite')}
          <br />
          <Link
            isExternal
            href="https://www.m3ter.com/docs/guides/integrations/setting-up-native-integrations/configuring-netsuite-integrations"
          >
            {t('common:readDocs')}
          </Link>
        </Text>
      ),
      paddle: (
        <Text>
          {t('features:integrations.integrationSystemDescriptions.paddle')}
          <br />
          <Link
            isExternal
            href="https://www.m3ter.com/docs/guides/integrations/setting-up-native-integrations/configuring-paddle-integrations"
          >
            {t('common:readDocs')}
          </Link>
        </Text>
      ),
      paddle_sandbox: (
        <Text>
          {t(
            'features:integrations.integrationSystemDescriptions.paddlesandbox'
          )}
        </Text>
      ),
      quickbooks: (
        <Text>
          {t('features:integrations.integrationSystemDescriptions.quickbooks')}
          <br />
          <Link
            isExternal
            href="https://www.m3ter.com/docs/guides/integrations/setting-up-native-integrations/configuring-quickbooks-integrations"
          >
            {t('common:readDocs')}
          </Link>
        </Text>
      ),
      quickbooks_sandbox: (
        <Text>
          {t(
            'features:integrations.integrationSystemDescriptions.quickbookssandbox'
          )}
        </Text>
      ),
      salesforce: (
        <Text>
          <Trans
            t={t as TFunction}
            i18nKey="features:integrations.integrationSystemDescriptions.salesforce"
            components={{
              managedPackageLink: (
                <Link
                  isExternal
                  href="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000HhgLnUAJ"
                />
              ),
            }}
          />
          <br />
          <Link
            isExternal
            href="https://www.m3ter.com/docs/guides/integrations/setting-up-native-integrations/configuring-salesforce-integrations"
          >
            {t('common:readDocs')}
          </Link>
        </Text>
      ),
      stripe: (
        <Text>
          {t('features:integrations.integrationSystemDescriptions.stripe')}
          <br />
          <Link
            isExternal
            href="https://www.m3ter.com/docs/guides/integrations/setting-up-native-integrations/configuring-stripe-integrations"
          >
            {t('common:readDocs')}
          </Link>
        </Text>
      ),
      stripe_test: (
        <Text>
          {t('features:integrations.integrationSystemDescriptions.stripetest')}
        </Text>
      ),
      xero: (
        <Text>
          {t('features:integrations.integrationSystemDescriptions.xero')}
          <br />
          <Link
            isExternal
            href="https://www.m3ter.com/docs/guides/integrations/setting-up-native-integrations/configuring-xero-integrations"
          >
            {t('common:readDocs')}
          </Link>
        </Text>
      ),
    }),
    [t]
  );

  const visibleSystemSchemas = useMemo(() => {
    let visible = [...availableSystemSchemas];
    if (categoryFilter) {
      visible = visible.filter((schema) =>
        schema.categories?.includes(categoryFilter)
      );
    }
    return visible;
  }, [availableSystemSchemas, categoryFilter]);

  return (
    <VStack
      alignItems="stretch"
      divider={<StackDivider />}
      spacing={4}
      justifyContent="flex-start"
      pb={16}
    >
      <RadioGroup
        aria-label={t('features:integrations.integrationCategory')}
        onChange={setCategoryFilter}
        value={categoryFilter}
      >
        <HStack spacing={4}>
          <Radio value="">
            <Badge textTransform="none">{t('common:all')}</Badge>
          </Radio>
          {availableSystemCategories.map((category) => (
            <Radio key={category} value={category}>
              <Badge textTransform="none">{category}</Badge>
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
      <Grid
        templateColumns="repeat(auto-fill, minmax(400px, 1fr))"
        gap={4}
        alignItems="stretch"
      >
        {visibleSystemSchemas.map((systemSchema) => (
          <Card
            key={systemSchema.apiName}
            background="transparent"
            overflow="hidden"
          >
            <CardHeader
              alignItems="center"
              background="whiteAlpha.100"
              display="flex"
              flexFlow="row nowrap"
              gap={2}
              justifyContent="center"
              py={8}
              _hover={{ bg: 'misc-hover' }}
              as={NamedLink}
              aria-label={t('features:integrations.systemIntegration', {
                system: systemSchema.displayName,
              })}
              name="integrations.system"
              params={{ system: systemSchema.apiName }}
            >
              <HStack
                alignItems="center"
                divider={<StackDivider />}
                spacing={2}
              >
                <ExternalSystemLogo
                  height="30px"
                  system={systemSchema.apiName}
                />
                {(systemSchema.hasConfiguredCredentials ||
                  systemSchema.hasConfiguredConfigs) && (
                  <Icon
                    aria-label={t(
                      'features:integrations.systemIntegrationIsConfigured',
                      { system: systemSchema.displayName }
                    )}
                    as={CircleCheckIcon}
                    color={
                      systemSchema.hasConfiguredCredentials &&
                      systemSchema.hasConfiguredConfigs
                        ? 'green-adaptive'
                        : 'yellow-adaptive'
                    }
                    size={20}
                  />
                )}
              </HStack>
            </CardHeader>
            <CardBody
              alignItems="stretch"
              background="chakra-subtle-bg"
              display="flex"
              flexFlow="column nowrap"
              gap={2}
              justifyContent="flex-start"
            >
              <Flex
                alignItems="center"
                flexFlow="row wrap"
                gap={2}
                justifyContent="flex-start"
              >
                {systemSchema.categories?.map((category) => (
                  <Badge key={category} textTransform="none">
                    {category}
                  </Badge>
                ))}
              </Flex>
              <Heading as="h4" size="sm">
                {systemSchema.displayName}
              </Heading>
              {systemSchema.apiName.toLowerCase() in systemDescriptionsMap
                ? systemDescriptionsMap[systemSchema.apiName.toLowerCase()]
                : null}
            </CardBody>
          </Card>
        ))}
      </Grid>
    </VStack>
  );
};
