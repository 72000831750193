import { useCallback, useId, useMemo } from 'react';

import type { SelectionControlProps, SelectionProps } from '../types/tables';

import { getAccessorValue, type Accessor } from '../utils/data';

interface UseSelectionOptions<D> extends SelectionProps<D> {
  items: ReadonlyArray<D>;
  idAccessor: Accessor<D, string>;
}

interface UseSelectionOptions<D> extends SelectionProps<D> {
  items: ReadonlyArray<D>;
  idAccessor: Accessor<D, string>;
}

const defaultIsItemDisabled = () => false;

const useSelection = <D>({
  items,
  selectionType,
  selectedItems = [],
  idAccessor,
  onSelectedItemsChange,
  isItemDisabled = defaultIsItemDisabled,
}: UseSelectionOptions<D>) => {
  // Use a unique ID to build checkbox / radio names.
  const id = useId();

  const isItemSelected = useCallback(
    (item: D) => selectedItems.includes(getAccessorValue(item, idAccessor)),
    [selectedItems, idAccessor]
  );

  const selectableItems = useMemo(
    () => items.filter((item) => !isItemDisabled(item)),
    [items, isItemDisabled]
  );

  const allSelectableSelected = useMemo(
    () => selectableItems.length > 0 && selectableItems.every(isItemSelected),
    [selectableItems, isItemSelected]
  );

  const onToggleItem = useCallback(
    (item: D) => {
      if (!onSelectedItemsChange) return;

      const isSelected = isItemSelected(item);
      const itemId = getAccessorValue(item, idAccessor);

      if (selectionType === 'single') {
        // Only change if the item isn't already selected.
        if (!isSelected) {
          onSelectedItemsChange([itemId]);
        }
      } else if (isSelected) {
        // Remove the item.
        onSelectedItemsChange(
          selectedItems.filter((selectedId) => selectedId !== itemId)
        );
      } else {
        // Add the item.
        onSelectedItemsChange([...selectedItems, itemId]);
      }
    },
    [
      isItemSelected,
      onSelectedItemsChange,
      selectedItems,
      idAccessor,
      selectionType,
    ]
  );

  const anySelected = selectedItems.length > 0;

  const onToggleAll = useCallback(() => {
    if (!onSelectedItemsChange) return;

    if (allSelectableSelected) {
      onSelectedItemsChange([]);
    } else {
      onSelectedItemsChange(
        selectableItems.map((item: D) => getAccessorValue(item, idAccessor))
      );
    }
  }, [
    allSelectableSelected,
    selectableItems,
    idAccessor,
    onSelectedItemsChange,
  ]);

  const getSelectAllControlProps = useCallback((): SelectionControlProps => {
    return {
      name: `select-all-${id}`,
      isChecked: allSelectableSelected,
      isIndeterminate: anySelected && !allSelectableSelected,
      onChange: onToggleAll,
    };
  }, [id, allSelectableSelected, anySelected, onToggleAll]);

  const getSelectItemControlProps = useCallback(
    (item: D): SelectionControlProps => {
      return {
        name: `select-${id}`,
        isDisabled: isItemDisabled(item),
        isChecked: isItemSelected(item),
        onChange: () => {
          onToggleItem(item);
        },
        value: getAccessorValue(item, idAccessor),
      };
    },
    [id, idAccessor, isItemDisabled, isItemSelected, onToggleItem]
  );

  return {
    getSelectAllControlProps,
    getSelectItemControlProps,
    isItemSelected,
  };
};

export default useSelection;
