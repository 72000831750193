import React from 'react';

import { DataType, NotificationRule } from '@m3ter-com/m3ter-api';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';
import { NotificationRulesRoute } from '@/components/routes/events/notification-rules/NotificationRulesRoute';
import { NotificationRulesListRoute } from '@/components/routes/events/notification-rules/NotificationRulesListRoute';

const LazyNotificationRuleDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "NotificationRuleDetailsRoute" */ '@/components/routes/events/notification-rules/NotificationRuleDetailsRoute'
  ).then((module) => ({ default: module.NotificationRuleDetailsRoute }))
);

const LazyNotificationRuleCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "NotificationRuleCreateRoute" */ '@/components/routes/events/notification-rules/NotificationRuleCreateRoute'
  ).then((module) => ({ default: module.NotificationRuleCreateRoute }))
);

const LazyNotificationRuleEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "NotificationRuleEditRoute" */ '@/components/routes/events/notification-rules/NotificationRuleEditRoute'
  ).then((module) => ({ default: module.NotificationRuleEditRoute }))
);

export default () =>
  createCrudRoute<NotificationRule>({
    path: 'notification-rules',
    dataType: DataType.NotificationRule,
    element: <NotificationRulesRoute />,
    list: <NotificationRulesListRoute />,
    details: <LazyNotificationRuleDetailsRoute />,
    create: <LazyNotificationRuleCreateRoute />,
    edit: <LazyNotificationRuleEditRoute />,
  });
