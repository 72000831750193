import { ReactNode } from 'react';

import { Box, Flex, FormControl, FormLabel, Spacer } from '@chakra-ui/react';

import { Pagination } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { PageSizeSelect } from '@/components/common/controls/PageSizeSelect/PageSizeSelect';

const DEFAULT_PAGE_SIZE_OPTIONS = [20, 50, 100, 200];

export interface EntityListFooterProps {
  currentPage: number;
  goToNextPage: () => void;
  goToPage: (page: number) => void;
  knownPageCount: number;
  onPageSizeChange: (size: number) => void;
  pageSize: number;
  actions?: ReactNode;
  hasMore?: boolean;
  pageSizeOptions?: Array<number>;
}

export const EntityListFooter: React.FC<EntityListFooterProps> = ({
  actions,
  currentPage,
  goToNextPage,
  goToPage,
  hasMore,
  knownPageCount,
  onPageSizeChange,
  pageSize,
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
}) => {
  const { t } = useTranslation();

  return (
    <Flex gap={4}>
      {actions}
      <Spacer />
      <FormControl
        alignItems="center"
        display="flex"
        flexFlow="row nowrap"
        width="max-content"
      >
        <FormLabel fontWeight="normal" marginBottom="none" whiteSpace="nowrap">
          {t('common:itemsPerPage')}
        </FormLabel>
        <Box width="150px">
          <PageSizeSelect
            sizeOptions={pageSizeOptions}
            value={pageSize}
            onChange={onPageSizeChange}
            menuPlacement="top"
          />
        </Box>
      </FormControl>
      <Pagination
        currentPage={currentPage}
        pageCount={knownPageCount}
        onChange={goToPage}
        onNext={goToNextPage}
        hasMore={hasMore}
      />
    </Flex>
  );
};
