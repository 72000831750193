import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Flex, Link, StackDivider, VStack } from '@chakra-ui/react';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { CrudListLink } from '@/components/common/navigation/CrudListLink/CrudListLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { ExternalSystemLogo } from '@/components/common/brand/ExternalSystemLogo/ExternalSystemLogo';
import { IntegrationRunStatusBadge } from '@/components/features/integrations/IntegrationRunStatusBadge/IntegrationRunStatusBadge';

export interface EntityLatestIntegrationRunLinkProps {
  entityId?: string;
  entityType: string;
}

export const EntityLatestIntegrationRunLink: React.FC<
  EntityLatestIntegrationRunLinkProps
> = ({ entityId, entityType }) => {
  const { t } = useTranslation();
  const { toLongDateTime } = useDateFormatter();

  const runsListLinkQueryParams = useMemo(
    () => ({ entityId, entityType }),
    [entityId, entityType]
  );

  const latestRunPathParams = useOrgPathParams(runsListLinkQueryParams);
  const { data: latestIntegrationRun } = useQuery(
    dataTypeRetrieveQuery(
      {
        dataType: DataType.IntegrationRun,
        actionName: 'getLatestEntityRun',
        pathParams: latestRunPathParams,
      },
      { enabled: !!entityId }
    )
  );

  if (!latestIntegrationRun) {
    return '-';
  }

  return (
    <VStack
      alignItems="flex-start"
      divider={<StackDivider />}
      justifyContent="center"
      width="max-content"
    >
      <VStack alignItems="flex-start" justifyContent="center">
        <Link
          as={CrudDetailsLink}
          dataType={DataType.IntegrationRun}
          id={latestIntegrationRun.id}
        >
          {toLongDateTime(latestIntegrationRun.dtStarted)}
        </Link>
        <Flex
          alignItems="center"
          flexFlow="row"
          gap={1}
          justifyContent="flex-start"
        >
          <ExternalSystemLogo
            height="20px"
            system={latestIntegrationRun.destination}
          />
          <IntegrationRunStatusBadge
            fontSize="2xs"
            status={latestIntegrationRun.status}
          />
        </Flex>
      </VStack>
      <Link
        as={CrudListLink}
        dataType={DataType.IntegrationRun}
        queryParams={runsListLinkQueryParams}
      >
        {t('features:integrations.viewAllRuns')}
      </Link>
    </VStack>
  );
};
