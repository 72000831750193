import { Fragment, ReactElement, useCallback } from 'react';

import { ButtonGroup, useDisclosure } from '@chakra-ui/react';
import { PencilIcon, TrashIcon } from 'lucide-react';

import { DataType, DataTypeToEntity } from '@m3ter-com/m3ter-api';
import { ConfirmationDialog, IconButton } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudEditLink } from '@/components/common/navigation/CrudEditLink/CrudEditLink';
import useEntityDeleteMutation from '@/hooks/data/useEntityDeleteMutation';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { Accessor, getAccessorValue } from '@/util/data';

export interface EntityActionsProps<DT extends DataType> {
  dataType: DT;
  entity: DataTypeToEntity[DT];
  addReturnPath?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  editQueryParams?: Record<string, any>;
  nameAccessor?: Accessor<DataTypeToEntity[DT]>;
}

export function EntityActions<DT extends DataType>({
  dataType,
  entity,
  addReturnPath = true,
  canDelete = true,
  canEdit = true,
  editQueryParams,
  nameAccessor = 'id',
}: EntityActionsProps<DT>): ReactElement<any, any> | null {
  const { t } = useTranslation();
  const { singularLower } = useEntityNamings(dataType);
  const { deleteEntity } = useEntityDeleteMutation(dataType);

  const {
    isOpen: isConfirmOpen,
    onOpen: onConfirmOpen,
    onClose: onConfirmClose,
  } = useDisclosure();

  const onDelete = useCallback(() => {
    deleteEntity({ entity });
    onConfirmClose();
  }, [deleteEntity, entity, onConfirmClose]);

  if (!canEdit && !canDelete) {
    return null;
  }

  return (
    <ButtonGroup size="sm">
      {canEdit && (
        <IconButton
          aria-label={t('common:edit')}
          icon={<PencilIcon size={16} />}
          as={CrudEditLink}
          dataType={dataType}
          id={entity.id}
          addReturnPath={addReturnPath}
          queryParams={editQueryParams}
        />
      )}
      {canDelete && (
        <Fragment>
          <ConfirmationDialog
            isOpen={isConfirmOpen}
            onClose={onConfirmClose}
            onConfirm={onDelete}
            message={t('common:sureDeleteEntity', {
              entityName: singularLower,
              name: getAccessorValue(entity, nameAccessor),
            })}
            cancelText={t('common:cancel')}
            confirmText={t('common:delete')}
          />
          <IconButton
            onClick={onConfirmOpen}
            aria-label={t('common:delete')}
            icon={<TrashIcon size={16} />}
          />
        </Fragment>
      )}
    </ButtonGroup>
  );
}
