import {
  DataType,
  EventTypes,
  Id,
  ListResponse,
  M3terEvent,
  NotificationRuleWebhookLink,
  OutboundWebhook,
  UnsavedEntity,
} from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post, put } from '../client';

import { del, listAll } from './common';

interface DownloadResponse {
  url: string;
}

interface EvaluateNotificationCalculationRequestBody {
  eventName: string;
  calculation: string;
}

interface EvaluateNotificationCalculationResponse {
  success: boolean;
  errorMessage?: string;
}

export const actionEvents = (organizationId: Id, ids: Array<Id>) =>
  put({
    path: getOrganizationPath('/events/action'),
    pathParams: { organizationId },
    queryParams: { ids },
  }) as Promise<ListResponse<M3terEvent>>;

export const deleteWebhookNotificationRuleLink = async (
  organizationId: Id,
  notificationRuleId: Id,
  webhookId: Id
): Promise<NotificationRuleWebhookLink> => {
  const notificationRuleWebhookLinksResponse = await listAll({
    dataType: DataType.Integration,
    actionName: 'getEntityIntegrations',
    pathParams: { entityType: 'Notification', organizationId },
    queryParams: { entityId: notificationRuleId },
  });
  const linkToDelete = notificationRuleWebhookLinksResponse.data.find(
    (link) => link.destinationId === webhookId
  );
  if (!linkToDelete) {
    throw new Error('');
  }

  return del({
    dataType: DataType.Integration,
    id: linkToDelete.id,
    pathParams: { organizationId },
  }) as Promise<NotificationRuleWebhookLink>;
};

export const evaluationNotificationCalculation = (
  organizationId: Id,
  body: EvaluateNotificationCalculationRequestBody
) =>
  post({
    path: getOrganizationPath('/notifications/evaluate'),
    pathParams: { organizationId },
    body,
  }) as Promise<EvaluateNotificationCalculationResponse>;

// This function expects a full URL, which the client will use without prepending the base URL.
export const getEventDownloadUrl = async (url: string) => {
  const response = await (get({ path: url }) as Promise<DownloadResponse>);
  return response.url;
};

export const getEventTypes = (organizationId: Id) =>
  get({
    path: getOrganizationPath('/events/types'),
    pathParams: { organizationId },
  }) as Promise<EventTypes>;

export const getNotificationRuleLinkedWebhooks = async (
  organizationId: Id,
  notificationRuleId: Id
): Promise<Array<OutboundWebhook>> => {
  const notificationRuleWebhookLinksResponse = await listAll({
    dataType: DataType.Integration,
    actionName: 'getEntityIntegrations',
    pathParams: { entityType: 'Notification', organizationId },
    queryParams: { entityId: notificationRuleId },
  });
  const linkedWebhookIds = notificationRuleWebhookLinksResponse.data
    .filter((link) => !!link.destinationId)
    .map((link) => link.destinationId!);
  if (linkedWebhookIds.length === 0) {
    return [];
  }

  const linkedWebhooksResponse = await listAll({
    dataType: DataType.OutboundWebhook,
    pathParams: { organizationId },
    queryParams: { ids: linkedWebhookIds },
  });
  return linkedWebhooksResponse.data;
};

export const linkWebhooksToNotificationRule = async (
  organizationId: Id,
  notificationRuleId: Id,
  webhookIds: Array<Id>
): Promise<Array<NotificationRuleWebhookLink>> => {
  const notificationRuleWebhookLinkBodies = webhookIds.map<
    Omit<
      UnsavedEntity<NotificationRuleWebhookLink>,
      'authorized' | 'configData'
    >
  >((webhookId) => ({
    entityId: notificationRuleId,
    entityType: 'Notification',
    destination: 'Webhook',
    destinationId: webhookId,
  }));
  const results = await Promise.allSettled(
    notificationRuleWebhookLinkBodies.map(
      (body) =>
        post({
          path: getOrganizationPath('/integrationconfigs'),
          pathParams: { organizationId },
          body,
        }) as Promise<NotificationRuleWebhookLink>
    )
  );
  return results
    .filter((result) => result.status === 'fulfilled')
    .map((result) => result.value);
};
