import React, { useMemo } from 'react';

import {
  AggregationFrequency,
  DataExportTimePeriodType,
  ExportFileFormat,
  SourceType,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { getDateValuesForTimePeriod } from '@m3ter-com/console-core/utils';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import {
  ExportAdhocFormValues,
  ExportScheduleForm,
} from '@/components/features/data-exports/ExportScheduleForm/ExportScheduleForm';
import useAdhocExport from '@/hooks/features/dataExports/useAdhocExport';
import useDateFormatter from '@/hooks/util/useDateFormatter';

export const AdhocExportCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const { error, onCancel, onAdhocExport, isSaving } = useAdhocExport();
  const { timeZone } = useDateFormatter();

  const initialValues = useMemo<Partial<ExportAdhocFormValues>>(() => {
    const { startDate, endDate } = getDateValuesForTimePeriod(
      DataExportTimePeriodType.Today,
      timeZone
    );
    return {
      dateRange: {
        startDate,
        endDate,
        type: DataExportTimePeriodType.Today,
      },
      aggregationFrequency: AggregationFrequency.Original,
      sourceType: SourceType.Usage,
      exportFileFormat: ExportFileFormat.Csv,
    };
  }, [timeZone]);

  return (
    <React.Fragment>
      <BreadcrumbItem title={t('features:dataExports.runAdhocExport')} />
      {error && (
        <ErrorAlert
          mb={4}
          error={error}
          title={t('errors:dataExports.adhocExportFailed')}
        />
      )}
      <ExportScheduleForm
        isAdhoc
        initialValues={initialValues}
        isSaving={isSaving}
        onCancel={onCancel}
        onSave={onAdhocExport}
      />
    </React.Fragment>
  );
};
