import { useMemo } from 'react';

import { NumericSelect, NumericSelectProps } from '@m3ter-com/ui-components';

export interface PageSizeSelectProps
  extends Omit<NumericSelectProps, 'options' | 'onChange'> {
  value: number;
  sizeOptions: Array<number>;
  onChange: (size: number) => void;
}

export const PageSizeSelect: React.FC<PageSizeSelectProps> = ({
  value,
  sizeOptions,
  onChange,
  ...selectProps
}) => {
  const listSizeOptions = useMemo(
    () =>
      sizeOptions.map((size) => ({
        label: `${size}`,
        value: size,
      })),
    [sizeOptions]
  );

  return (
    <NumericSelect
      onChange={onChange as (newListSize: number | null) => void} // We can safely cast here since we aren't making the <Select> clearable
      options={listSizeOptions}
      value={value}
      {...selectProps}
    />
  );
};
