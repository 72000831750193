import React, {
  useMemo,
  useCallback,
  useState,
  KeyboardEvent,
  useEffect,
} from 'react';

import debounce from 'lodash/debounce';
import {
  forwardRef,
  Icon,
  IconButton,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightAddon,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import { SearchIcon, XIcon } from 'lucide-react';

import { Input } from '../../forms/Input/Input';

export interface SearchBarProps extends InputGroupProps {
  placeholder: string;
  initialValue?: string;
  searchOnType?: boolean;
  isLoading?: boolean;
  debounceWait?: number;
  onSearch: (query: string) => void;
  onBlur?: () => void;
  onClear?: () => void;
  onFocus?: () => void;
}

export const SearchBar = forwardRef<SearchBarProps, typeof InputGroup>(
  (
    {
      initialValue = '',
      placeholder,
      searchOnType = false,
      isLoading = false,
      debounceWait = 500,
      onBlur,
      onClear,
      onFocus,
      onSearch,
      size,
      ...chakraProps
    },
    ref
  ) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    const debouncedSearch = useMemo(
      () => debounce(onSearch, debounceWait),
      [onSearch, debounceWait]
    );

    const onChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value: newValue } = event.target;
        if (searchOnType) debouncedSearch(newValue);
        setValue(newValue);
      },
      [searchOnType, debouncedSearch]
    );

    const search = useCallback(() => {
      if (value.length > 0) {
        onSearch(value);
      } else if (onClear) {
        onClear();
      }
    }, [value, onSearch, onClear]);

    const clearSearch = useCallback(() => {
      setValue('');
      if (onClear) {
        onClear();
      }
    }, [onClear]);

    const onKeyDown = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
          search();
          // Prevent submitting any `form`s that wrap the search bar.
          event.preventDefault();
        }
      },
      [search]
    );

    return (
      <InputGroup size={size} {...chakraProps}>
        {searchOnType && (
          <InputLeftElement>
            <Icon
              data-testid="search-icon"
              as={SearchIcon}
              color="chakra-subtle-text"
            />
          </InputLeftElement>
        )}
        <Input
          data-testid="search-input"
          ref={ref}
          value={value}
          placeholder={placeholder}
          aria-label={placeholder}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
        {isLoading && (
          // Set right to avoid overlapping the button(s).
          <InputRightElement
            right={searchOnType ? '40px' : '80px'}
            data-testid="loading"
          >
            <Spinner size="sm" />
          </InputRightElement>
        )}
        <InputRightAddon p={0} borderRadius={searchOnType ? undefined : 0}>
          <IconButton
            data-testid="clear-button"
            aria-label="Clear search"
            borderRadius="inherit"
            icon={<Icon as={XIcon} />}
            onClick={clearSearch}
            size={size}
          />
        </InputRightAddon>
        {!searchOnType && (
          <InputRightAddon p={0}>
            <IconButton
              data-testid="search-button"
              aria-label="Perform search"
              borderRadius="inherit"
              icon={<Icon as={SearchIcon} />}
              onClick={search}
              size={size}
            />
          </InputRightAddon>
        )}
      </InputGroup>
    );
  }
);
