import React, { PropsWithChildren, ReactNode } from 'react';

import { Box, Flex, Heading, Spacer, StyleProps } from '@chakra-ui/react';

import { AppLayoutMain } from '../AppLayout/AppLayout';

export interface PageProps extends StyleProps {
  title: ReactNode;
  actions?: ReactNode;
  breadcrumbs?: ReactNode;
  meta?: ReactNode;
}

export const Page: React.FC<PropsWithChildren<PageProps>> = ({
  actions,
  meta,
  breadcrumbs,
  title,
  children,
  ...styleProps
}) => {
  return (
    <AppLayoutMain {...styleProps}>
      <Flex alignItems="end" gap={12} mb={4}>
        <Box>
          {breadcrumbs}
          <Heading as="h1">{title}</Heading>
        </Box>
        {meta}
        <Spacer />
        {actions}
      </Flex>
      {children}
    </AppLayoutMain>
  );
};
