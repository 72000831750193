import React from 'react';

import {
  BlocksIcon,
  CircleAlertIcon,
  Rotate3DIcon,
  RouteIcon,
  WebhookIcon,
  WorkflowIcon,
} from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Navigation } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import { NamedNavLink } from '@/components/common/navigation/NamedNavLink/NamedNavLink';

export const IntegrationsNavigation: React.FC = () => {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useFeatureFlags();

  return (
    <Navigation>
      <NamedNavLink name="integrations.systems-list" icon={<BlocksIcon />}>
        {t('common:integrations')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.OutboundWebhook, CrudRouteType.List)}
        icon={<WebhookIcon />}
      >
        {t('common:outboundWebhooks')}
      </NamedNavLink>
      {isFeatureEnabled(Feature.Transformations) && (
        <NamedNavLink
          name={getCrudRouteName(
            DataType.IntegrationTransformation,
            CrudRouteType.List
          )}
          icon={<Rotate3DIcon />}
        >
          {t('common:integrationTransformations')}
        </NamedNavLink>
      )}
      {isFeatureEnabled(Feature.M3sh) && (
        <NamedNavLink
          name={getCrudRouteName(
            DataType.IntegrationWorkflow,
            CrudRouteType.List
          )}
          icon={<WorkflowIcon />}
        >
          {t('features:integrations.workflows')}
        </NamedNavLink>
      )}
      <NamedNavLink name="integration.events" icon={<CircleAlertIcon />}>
        {t('features:integrations.integrationEvents')}
      </NamedNavLink>
      <NamedNavLink
        name={getCrudRouteName(DataType.ExternalMapping, CrudRouteType.List)}
        icon={<RouteIcon />}
      >
        {t('common:externalMappings')}
      </NamedNavLink>
    </Navigation>
  );
};
