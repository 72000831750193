import React, { useCallback } from 'react';

import { HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { CalendarRangeIcon } from 'lucide-react';

import {
  AnalyticsJobTimePeriodType,
  DataExportTimePeriodType,
  DateTimeISOString,
} from '@m3ter-com/m3ter-api';
import { Button, DatePicker } from '@m3ter-com/ui-components';
import { getDateValuesForTimePeriod } from '@m3ter-com/console-core/utils';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import { UsageQueryBuilderSection } from '@/components/features/usage/query-builder/UsageQueryBuilderSection/UsageQueryBuilderSection';
import {
  UsageQueryBuilderMenu,
  UsageQueryBuilderMenuButton,
} from '@/components/features/usage/query-builder/UsageQueryBuilderMenu/UsageQueryBuilderMenu';

export type TimePeriodType =
  | AnalyticsJobTimePeriodType
  | DataExportTimePeriodType;

export interface DateRange {
  type: TimePeriodType;
  startDate: DateTimeISOString | null;
  endDate: DateTimeISOString | null;
}

export interface RequiredDateRange {
  type: TimePeriodType;
  startDate: DateTimeISOString;
  endDate: DateTimeISOString;
}

export interface UsageQueryBuilderAbsoluteTimePeriodProps {
  value: DateRange;
  onChange: (value: DateRange) => void;
  periods?: Array<TimePeriodType>;
  isDatesRequired?: boolean;
  helpText?: string;
  showHelpText?: boolean | ((period: TimePeriodType) => boolean);
  isTodayFullyIncluded?: boolean;
}

const defaultPeriods = Object.values(AnalyticsJobTimePeriodType);

export const UsageQueryBuilderAbsoluteTimePeriod: React.FC<
  UsageQueryBuilderAbsoluteTimePeriodProps
> = ({
  value,
  onChange,
  helpText,
  showHelpText = false,
  isDatesRequired = true,
  isTodayFullyIncluded = true,
  periods = defaultPeriods,
}) => {
  const { t } = useTranslation();

  const { toLongDateTime, timeZone } = useDateFormatter();

  const { isOpen, onClose, onToggle } = useDisclosure();

  const onSelect = useCallback(
    (type: TimePeriodType) => {
      const { startDate, endDate } = getDateValuesForTimePeriod(
        type,
        timeZone,
        isTodayFullyIncluded
      );
      onChange({
        type,
        startDate,
        endDate,
      });
      onClose();
    },
    [onChange, onClose, timeZone, isTodayFullyIncluded]
  );

  const onStartDateChange = useCallback(
    (newStartDate: string | null) => {
      onChange({
        ...value,
        startDate: newStartDate,
      });
    },
    [onChange, value]
  );

  const onEndDateChange = useCallback(
    (newEndDate: string | null) => {
      onChange({
        ...value,
        endDate: newEndDate,
      });
    },
    [onChange, value]
  );

  const isCustom = value.type === AnalyticsJobTimePeriodType.Custom;
  const shouldDisplayHelpText =
    !!helpText &&
    (typeof showHelpText === 'function'
      ? showHelpText(value.type)
      : showHelpText);

  return (
    <UsageQueryBuilderSection
      colorScheme="blue"
      title={t('forms:labels.timePeriod')}
      icon={CalendarRangeIcon}
    >
      {shouldDisplayHelpText && (
        <Text
          data-testid="absolute-time-period-help-text"
          mb={2}
          fontSize="sm"
          colorScheme="blue"
        >
          {helpText}
        </Text>
      )}
      <UsageQueryBuilderMenu
        isOpen={isOpen}
        onClose={onClose}
        trigger={
          <HStack spacing={4}>
            <Button
              size="sm"
              colorScheme="blue"
              variant="subtle"
              onClick={onToggle}
              flexShrink={0}
            >
              <Text as="span" fontWeight="semibold">
                {t(`features:dataExplorer.timePeriod.${value.type}`)}
              </Text>
              {!isCustom && value.startDate && value.endDate && (
                <Text as="span" ml={2} fontSize="xs" variant="subtle">
                  ({toLongDateTime(value.startDate)} –{' '}
                  {toLongDateTime(value.endDate)})
                </Text>
              )}
            </Button>
            {isCustom && (
              <HStack spacing={4}>
                <DatePicker
                  showTimeSelect
                  isRequired={isDatesRequired}
                  value={value.startDate}
                  onChange={onStartDateChange}
                  timeZone={timeZone}
                  buttonVariant="subtle"
                  colorScheme="blue"
                  size="sm"
                />
                <DatePicker
                  showTimeSelect
                  isRequired={isDatesRequired}
                  value={value.endDate}
                  onChange={onEndDateChange}
                  timeZone={timeZone}
                  buttonVariant="subtle"
                  colorScheme="blue"
                  size="sm"
                />
              </HStack>
            )}
          </HStack>
        }
      >
        <Stack>
          {periods.map((period) => (
            <UsageQueryBuilderMenuButton
              key={period}
              onClick={() => onSelect(period)}
            >
              {t(`features:dataExplorer.timePeriod.${period}`)}
            </UsageQueryBuilderMenuButton>
          ))}
        </Stack>
      </UsageQueryBuilderMenu>
    </UsageQueryBuilderSection>
  );
};
