import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';
import { PermissionsRoute } from '@/components/routes/settings/permissions/PermissionsRoute';
import { PermissionsListRoute } from '@/components/routes/settings/permissions/PermissionsListRoute';
import { PermissionsDetailsRoute } from '@/components/routes/settings/permissions/PermissionsDetailsRoute';

const LazyPermissionsCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "PermissionsCreateRoute" */ '@/components/routes/settings/permissions/PermissionsCreateRoute'
  ).then((module) => ({ default: module.PermissionsCreateRoute }))
);
const LazyPermissionsEditRoute = lazyWithRetry(() =>
  /* webpackChunkName: "PermissionsEditRoute" */ import(
    '@/components/routes/settings/permissions/PermissionsEditRoute'
  ).then((module) => ({ default: module.PermissionsEditRoute }))
);

export default () =>
  createCrudRoute({
    path: 'permission-policies',
    dataType: DataType.PermissionPolicy,
    element: <PermissionsRoute />,
    list: <PermissionsListRoute />,
    details: <PermissionsDetailsRoute />,
    create: <LazyPermissionsCreateRoute />,
    edit: <LazyPermissionsEditRoute />,
  });
