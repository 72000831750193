export enum SearchFieldType {
  Id = 'id',
  Number = 'number',
  String = 'string',
}

export interface SearchFieldDefinition {
  field: string;
  label: string;
  includeInQuickSearch?: boolean;
  type?: SearchFieldType;
}
