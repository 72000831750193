import React from 'react';

import { DataType, PlanGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import planGroupSchema from '@/validation/planGroup';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { FormCustomFieldsEditor } from '@/components/forms/FormCustomFieldsEditor/FormCustomFieldsEditor';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect/FormPicklistSelect';
import { PlanGroupFormFields } from '@/components/features/pricing/PlanGroupFormFields/PlanGroupFormFields';

export interface PlanGroupFormProps extends BaseFormProps<Partial<PlanGroup>> {}

export const PlanGroupForm: React.FC<PlanGroupFormProps> = ({
  onSave,
  onCancel,
  isSaving = false,
  isEdit = false,
  initialValues = {},
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.PlanGroup);

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={planGroupSchema}
    >
      <FormStack mb={6}>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <NameCodeFields />
          <FormField
            isRequired
            name="currency"
            label={t('forms:labels.currency')}
            control={FormPicklistSelect}
            useCodeAsValue
            dataType={DataType.Currency}
          />
        </FormSection>
        <PlanGroupFormFields />
        <FormCustomFieldsEditor />
      </FormStack>
      <FormActions
        cancelText={t('common:cancel')}
        submitText={
          isEdit
            ? t('forms:buttons.updateEntity', {
                entityName: entityNamings.singularLower,
              })
            : t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })
        }
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </Form>
  );
};
