import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import {
  DataType,
  getIntegrationConfigSchema,
  IntegrationConfig,
  IntegrationConfigSchema,
  IntegrationCredential,
} from '@m3ter-com/m3ter-api';

import { dataTypeListAllQuery } from '@/queries/crud';
import useOrg from '@/hooks/data/crud/useOrg';

export interface IntegrationSystemsContextValues {
  configs: Array<IntegrationConfig>;
  configSchema: IntegrationConfigSchema;
  credentials: Array<IntegrationCredential>;
  error?: Error | null;
  isLoading: boolean;
}

const IntegrationSystemsContext =
  createContext<IntegrationSystemsContextValues | null>(null);

export const IntegrationSystemsContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { currentOrgId: organizationId } = useOrg();

  const {
    data: configSchema,
    error: configSchemaError,
    isPending: isLoadingConfigSchema,
  } = useQuery({
    queryKey: ['integration-config-schema', organizationId],
    queryFn: () => getIntegrationConfigSchema(organizationId),
  });

  const {
    data: credentials,
    error: credentialsError,
    isPending: isLoadingCredentials,
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.IntegrationCredential,
      pathParams: { organizationId },
    })
  );

  const {
    data: configs,
    error: configsError,
    isPending: isLoadingConfigs,
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.Integration,
      pathParams: { organizationId },
    })
  );

  const value = useMemo<IntegrationSystemsContextValues>(
    () => ({
      configs: configs || [],
      configSchema: configSchema || {
        globalConfigurationOptions: [],
        supportedEntities: {},
      },
      credentials: credentials || [],
      error: configSchemaError || credentialsError || configsError,
      isLoading:
        isLoadingConfigSchema || isLoadingCredentials || isLoadingConfigs,
    }),
    [
      configSchema,
      configSchemaError,
      configs,
      configsError,
      credentials,
      credentialsError,
      isLoadingConfigSchema,
      isLoadingConfigs,
      isLoadingCredentials,
    ]
  );

  return (
    <IntegrationSystemsContext.Provider value={value}>
      {children}
    </IntegrationSystemsContext.Provider>
  );
};

export const useIntegrationSystemsContext = () =>
  useContext(IntegrationSystemsContext)!;
