import { Navigate } from 'react-router-dom';

import { NamedRoute } from '@/types/routes';

import { IntegrationsRoute } from '@/components/routes/integrations/IntegrationsRoute';

import setupSystemsRoute from './systems';
import setupConfigsRoute from './configs';
import setupCredentialsRoute from './credentials';
import setupOutboundWebhooksRoute from './outbound-webhooks';
import setupEventsRoute from './events';
import setupExternalMappingsRoute from './external-mappings';
import setupIntegrationRunsRoute from './runs';
import setupIntegrationTransformationsRoute from './transformations';
import setupIntegrationWorkflowsRoute from './workflows';

export default (): NamedRoute => ({
  name: 'integrations',
  path: 'integrations',
  element: <IntegrationsRoute />,
  children: [
    { index: true, element: <Navigate replace to="systems" /> },
    setupSystemsRoute(),
    setupConfigsRoute(),
    setupCredentialsRoute(),
    setupOutboundWebhooksRoute(),
    setupEventsRoute(),
    setupExternalMappingsRoute(),
    setupIntegrationRunsRoute(),
    setupIntegrationTransformationsRoute(),
    setupIntegrationWorkflowsRoute(),
  ],
});
