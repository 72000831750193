import React, { useMemo } from 'react';

import { Link, SimpleGrid, Stack, Text, VStack } from '@chakra-ui/react';

import {
  DataType,
  Balance,
  DrawdownChargeTypes,
  Product,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';

import { OtherListIds } from '@/types/lists';

import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useCurrencies from '@/hooks/util/useCurrencies';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink/KeyValueReferenceLink';

import { BalanceChargesTable } from './BalanceChargesTable';
import { BalanceSchedules } from './BalanceSchedules';
import { BalanceTransactionsSummary } from './BalanceTransactionsSummary';
import { BalanceTransactionsTable } from './BalanceTransactionsTable';

export interface BalanceDetailsProps {
  data: EntityWithRelationships<Balance>;
}

export const BalanceDetails: React.FC<BalanceDetailsProps> = ({
  data: balance,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDateTime } = useDateFormatter();
  const { isFeatureEnabled } = useFeatureFlags();

  // TODO: Remove this when fully released
  const isChargesAndBalanceSchedulesEnabled =
    isFeatureEnabled(Feature.Charges) &&
    isFeatureEnabled(Feature.BalanceSchedules);

  const balanceChargeTypes = useMemo(
    () =>
      balance.lineItemTypes?.length
        ? balance.lineItemTypes
        : Object.values(DrawdownChargeTypes),
    [balance.lineItemTypes]
  );

  const rolloverRemainingAmount = useMemo(() => {
    if (!balance.rolloverAmount) {
      return undefined;
    }

    const now = new Date(Date.now());
    const isRolloverEndDateFuture =
      balance.rolloverEndDate && now < new Date(balance.rolloverEndDate);

    if (!isRolloverEndDateFuture) {
      return undefined;
    }

    return formatCurrency(
      Math.min(balance.amount, balance.rolloverAmount),
      balance.currency
    );
  }, [balance, formatCurrency]);

  return (
    <DetailsCard
      showEditButton
      data={balance}
      dataType={DataType.Balance}
      details={
        <Stack spacing={6} width="100%">
          <SimpleGrid columns={4} gap={6} width="100%">
            <KeyValue label={t('forms:labels.name')} value={balance.name} />
            <KeyValue label={t('forms:labels.code')} value={balance.code} />
            <KeyValue
              label={t('forms:labels.startDateInclusive')}
              value={toLongDateTime(balance.startDate)}
            />
            <KeyValue
              label={t('forms:labels.endDateExclusive')}
              value={toLongDateTime(balance.endDate)}
            />
            <KeyValue
              label={t('forms:labels.currency')}
              value={balance.currency}
            />
            <KeyValue
              label={t('forms:labels.drawDownDescription')}
              value={balance.balanceDrawDownDescription || '-'}
            />
            <KeyValue
              label={t('forms:labels.drawDownChargeTypes')}
              value={balanceChargeTypes
                .map((chargeType) =>
                  t(`features:billing.lineItemTypes.${chargeType}`)
                )
                .join(', ')}
            />
            <KeyValue
              label={t('forms:labels.drawDownProducts')}
              value={
                <Text as="span" whiteSpace="pre-line">
                  {!!balance.productIds?.length && balance.products
                    ? balance.products.map(
                        (product: Product, index: number) => (
                          <React.Fragment key={product.id}>
                            <Link
                              as={CrudDetailsLink}
                              dataType={DataType.Product}
                              id={product.id}
                            >
                              {product.name}
                            </Link>
                            {index < balance.products.length - 1 && ', '}
                          </React.Fragment>
                        )
                      )
                    : t('features:balances.allProducts')}
                </Text>
              }
            />
          </SimpleGrid>
          <BalanceTransactionsSummary balance={balance} />
          <SimpleGrid columns={4} gap={6} width="100%">
            <KeyValue
              label={t('forms:labels.rolloverAmount')}
              value={
                balance.rolloverAmount
                  ? formatCurrency(balance.rolloverAmount, balance.currency)
                  : '-'
              }
            />
            <KeyValue
              label={t('features:balances.rolloverRemainingAmount')}
              value={rolloverRemainingAmount || '-'}
            />
            <KeyValue
              label={t('forms:labels.rolloverEndDate')}
              value={
                balance.rolloverEndDate
                  ? toLongDateTime(balance.rolloverEndDate)
                  : '-'
              }
            />
            <KeyValue
              label={t('forms:labels.overageSurchargePercent')}
              value={
                balance.overageSurchargePercent !== undefined
                  ? `${balance.overageSurchargePercent}%`
                  : '-'
              }
            />
            <KeyValue
              label={t('forms:labels.overageDescription')}
              value={balance.overageDescription || '-'}
            />
            <KeyValueReferenceLink
              label={t('forms:labels.consumptionsAccountingProduct')}
              dataType={DataType.Product}
              accessor="name"
              id={balance.consumptionsAccountingProductId}
              defaultValue="-"
            />
            <KeyValue
              label={t('forms:labels.description')}
              value={balance.description || '-'}
            />
          </SimpleGrid>
        </Stack>
      }
    >
      <VStack spacing={4} width="100%" alignItems="stretch">
        <BalanceTransactionsTable
          currency={balance.currency}
          balanceId={balance.id}
        />
        {isChargesAndBalanceSchedulesEnabled && (
          <BalanceChargesTable
            showCreateButton
            accountId={balance.accountId}
            balanceId={balance.id}
            listId={OtherListIds.BalanceCharges}
          />
        )}
        {isChargesAndBalanceSchedulesEnabled && (
          <BalanceSchedules balance={balance} />
        )}
      </VStack>
    </DetailsCard>
  );
};
