import React from 'react';

import { useQuery } from '@tanstack/react-query';
import { SimpleGrid } from '@chakra-ui/react';

import { Balance, getBalanceTransactionsSummary } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import useOrg from '@/hooks/data/crud/useOrg';
import useCurrencies from '@/hooks/util/useCurrencies';

interface BalanceTransactionsSummaryProps {
  balance: Balance;
}

export const BalanceTransactionsSummary: React.FC<
  BalanceTransactionsSummaryProps
> = ({ balance }) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { currentOrgId } = useOrg();

  const {
    data: transactionsSummary,
    error: transactionSummaryLoadingError,
    isLoading: isLoadingTransactionsSummary,
  } = useQuery({
    queryFn: () => getBalanceTransactionsSummary(currentOrgId, balance.id),
    queryKey: ['balance-transaction-summary', currentOrgId, balance.id],
  });

  return (
    <LoadingErrorContentSwitch
      showContentOnError
      isLoading={isLoadingTransactionsSummary}
      error={transactionSummaryLoadingError}
    >
      <SimpleGrid columns={4} gap={6} w="100%">
        <KeyValue
          label={t('features:balances.currentBalanceAmount')}
          value={formatCurrency(balance.amount, balance.currency)}
        />
        <KeyValue
          label={t('features:balances.initialCreditAmount')}
          value={
            transactionsSummary &&
            transactionsSummary.initialCreditAmount !== undefined
              ? formatCurrency(
                  transactionsSummary.initialCreditAmount,
                  balance.currency
                )
              : '-'
          }
        />
        <KeyValue
          label={t('features:balances.totalCreditAmount')}
          value={
            transactionsSummary
              ? formatCurrency(
                  transactionsSummary.totalCreditAmount,
                  balance.currency
                )
              : '-'
          }
        />
        <KeyValue
          label={t('features:balances.totalDebitAmount')}
          value={
            transactionsSummary
              ? formatCurrency(
                  transactionsSummary.totalDebitAmount,
                  balance.currency
                )
              : '-'
          }
        />
      </SimpleGrid>
    </LoadingErrorContentSwitch>
  );
};
