import { Fragment } from 'react';

import { Outlet } from 'react-router-dom';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';

export const ProductsRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <BreadcrumbItem title={t('common:products')} />
      <Outlet />
    </Fragment>
  );
};
