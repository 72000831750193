import { Outlet } from 'react-router-dom';

import { IntegrationSystemsContextProvider } from '@/components/features/integrations/IntegrationSystemsContext/IntegrationSystemsContext';

export const IntegrationSystemsRoute: React.FC = () => {
  return (
    <IntegrationSystemsContextProvider>
      <Outlet />
    </IntegrationSystemsContextProvider>
  );
};
