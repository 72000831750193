import React, { useCallback, useMemo, useState } from 'react';

import {
  Badge,
  Box,
  Flex,
  Link,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { isWithinInterval, subHours } from 'date-fns';
import { DownloadIcon } from 'lucide-react';

import {
  DataType,
  ExportJob,
  ExportStatus,
  getExportJobDownloadUrl,
  QueryParams,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  DateRange,
  EntityWithRelationships,
} from '@m3ter-com/console-core/types';
import {
  CopyToClipboard,
  IconButton,
  Select,
  SelectOption,
} from '@m3ter-com/ui-components';

import { EntityRouteListIds } from '@/types/lists';

import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { ExclusiveDateRangePicker } from '@/components/forms/ExclusiveDateRangePicker/ExclusiveDateRangePicker';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useExportJobsList from '@/hooks/features/dataExports/useExportJobsList';
import useNotification from '@/hooks/util/useNotification';
import useOrg from '@/hooks/data/crud/useOrg';
import { extractError } from '@/util/error';

const relationships = ['exportSchedule'];
const dateRangePickerRanges: Array<DateRange> = [
  DateRange.ThisMonth,
  DateRange.LastMonth,
];

export const getExportStatusColorScheme = (
  status?: ExportStatus
): string | undefined => {
  if (status === ExportStatus.FAILED) {
    return 'red';
  }
  if (status === ExportStatus.SUCCEEDED) {
    return 'green';
  }
  return undefined;
};

interface ExportDownloadButtonProps {
  exportJob: ExportJob;
}

export const ExportDownloadButton: React.FC<ExportDownloadButtonProps> = ({
  exportJob,
}) => {
  const { t } = useTranslation();
  const { toast } = useNotification();
  const { currentOrgId: organizationId } = useOrg();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onDownload = useCallback(async () => {
    setIsLoading(true);
    try {
      const downloadUrl = await getExportJobDownloadUrl(
        organizationId,
        exportJob.id
      );
      const downloadWindow = window.open(downloadUrl);
      if (!downloadWindow) {
        throw new Error(t('errors:downloads.downloadFailed'));
      }
    } catch (error) {
      const appError = extractError(error);
      toast({
        status: 'error',
        description: t('notifications:exportDownloadFailure').replace(
          '{error}',
          appError.message
        ),
      });
    } finally {
      setIsLoading(false);
    }
  }, [exportJob, organizationId, t, toast]);

  const isSucceededJob = exportJob.status === ExportStatus.SUCCEEDED;
  const isWithinLast24Hours = useMemo(
    () =>
      !!exportJob.startedAt &&
      isWithinInterval(new Date(exportJob.startedAt), {
        start: subHours(new Date(Date.now()), 24),
        end: new Date(Date.now()),
      }),
    [exportJob]
  );

  const downloadDisabledLabel = useMemo(() => {
    if (!isSucceededJob) {
      return t('features:dataExports.downloadJobFailedWarning');
    }
    if (!isWithinLast24Hours) {
      return t('features:dataExports.downloadAvailabilityWarning');
    }

    return undefined;
  }, [isSucceededJob, isWithinLast24Hours, t]);

  return (
    <Tooltip
      hasArrow
      label={downloadDisabledLabel}
      isDisabled={isSucceededJob && isWithinLast24Hours}
    >
      <IconButton
        size="sm"
        icon={<DownloadIcon size={16} />}
        isLoading={isLoading}
        isDisabled={!isSucceededJob || !isWithinLast24Hours}
        onClick={onDownload}
        aria-label={t('features:dataExports.downloadExport')}
      />
    </Tooltip>
  );
};

export const ExportJobsListRoute: React.FC = () => {
  const { t } = useTranslation();
  const { toLongDateTime, timeZone } = useDateFormatter();
  const {
    onSelectedDateRangeChange,
    onSelectedStatusChange,
    selectedDateRange,
    selectedStatus,
  } = useExportJobsList();

  const statusOptions = useMemo<Array<SelectOption<ExportStatus>>>(
    () =>
      Object.values(ExportStatus).map((value) => ({
        value,
        label: t(`features:dataExports.exportStatus.${value}`),
      })),
    [t]
  );

  const queryParams = useMemo<QueryParams>(
    () => ({
      ...(selectedStatus ? { status: selectedStatus } : {}),
      ...(selectedDateRange
        ? {
            dateCreatedStart: selectedDateRange.start,
            dateCreatedEnd: selectedDateRange.end,
          }
        : {}),
    }),
    [selectedStatus, selectedDateRange]
  );

  const columnDefinitions = useMemo<
    Array<ColumnDefinition<EntityWithRelationships<ExportJob>>>
  >(
    () => [
      {
        id: 'started-at',
        header: t('forms:labels.startDate'),
        accessor: (item) => item.startedAt && toLongDateTime(item.startedAt),
      },
      {
        id: 'id',
        header: t('common:id'),
        accessor: (item) => (
          <CopyToClipboard value={item.id}>{item.id}</CopyToClipboard>
        ),
      },
      {
        id: 'source-type',
        header: t('forms:labels.sourceType'),
        accessor: (item) =>
          t(`features:dataExports.sourceType.${item.sourceType}`),
      },
      {
        id: 'export-schedule',
        header: t('features:dataExports.schedule'),
        accessor: (item) => {
          if (!item.scheduleId) {
            return `(${t('features:dataExports.scheduleType.AD_HOC')})`;
          }

          return item.scheduleId && item.exportSchedule ? (
            <Link
              as={CrudDetailsLink}
              dataType={DataType.ExportSchedule}
              id={item.scheduleId}
            >
              {item.exportSchedule.name}
            </Link>
          ) : (
            `(${t('features:dataExports.scheduleNotFound')})`
          );
        },
      },
      {
        id: 'export-status',
        header: t('forms:labels.status'),
        accessor: (item) => (
          <Badge colorScheme={getExportStatusColorScheme(item.status)}>
            {t(`features:dataExports.exportStatus.${item.status}`)}
          </Badge>
        ),
      },
      {
        id: 'download-job',
        header: '',
        accessor: (item) => <ExportDownloadButton exportJob={item} />,
        align: 'center',
      },
    ],
    [t, toLongDateTime]
  );

  return (
    <CrudList<ExportJob>
      listId={EntityRouteListIds.ExportJob}
      relationships={relationships}
      params={queryParams}
    >
      <CrudListHeader hideCreateLink>
        <SimpleGrid
          gap={4}
          alignItems="center"
          gridTemplateColumns="repeat(2, 1fr)"
        >
          <Flex gap={4} alignItems="center">
            <VStack spacing={0} alignItems="stretch">
              <Text whiteSpace="nowrap">{t('forms:labels.startDate')}:</Text>
              <Text whiteSpace="nowrap">{t('features:billing.inclusive')}</Text>
            </VStack>
            <ExclusiveDateRangePicker
              isClearable
              value={selectedDateRange}
              onChange={onSelectedDateRangeChange}
              timeZone={timeZone}
              dateRanges={dateRangePickerRanges}
            />
          </Flex>
          <Flex gap={4} alignItems="center" alignContent="stretch">
            <Text whiteSpace="nowrap">{t('forms:labels.status')}:</Text>
            <Box flex={1}>
              <Select
                isClearable
                value={selectedStatus}
                onChange={(newStatus: string | null) => {
                  onSelectedStatusChange(newStatus);
                }}
                options={statusOptions}
              />
            </Box>
          </Flex>
        </SimpleGrid>
      </CrudListHeader>
      <CrudListTable columns={columnDefinitions} />
      <CrudListFooter />
    </CrudList>
  );
};
