import {
  AccountPlan,
  Charge,
  Commitment,
  Contract,
} from '@m3ter-com/m3ter-api';
import { getCleanDateInstance } from '@m3ter-com/console-core/utils';

const isActive = (
  orgTimeZone: string,
  startDate: string,
  endDate?: string
): boolean => {
  const now = new Date();
  const start = getCleanDateInstance(startDate, orgTimeZone);

  // The start cannot be in the future.
  if (start > now) {
    return false;
  }

  // If there is an end, it has to be in the past.
  if (endDate) {
    const end = getCleanDateInstance(endDate, orgTimeZone);
    if (end <= now) {
      return false;
    }
  }

  return true;
};

export const isAccountPlanActive = (
  accountPlan: AccountPlan,
  orgTimeZone: string
): boolean => isActive(orgTimeZone, accountPlan.startDate, accountPlan.endDate);

export const isCommitmentActive = (
  commitment: Commitment,
  orgTimeZone: string
): boolean => isActive(orgTimeZone, commitment.startDate, commitment.endDate);

export const isContractActive = (
  contract: Contract,
  orgTimeZone: string
): boolean => isActive(orgTimeZone, contract.startDate, contract.endDate);

export const isScheduledCharge = (
  charge: Charge,
  orgTimeZone: string
): boolean => !!charge.billDate && isActive(orgTimeZone, charge.billDate);
