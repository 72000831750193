import i18next from 'i18next';

import {
  ExportDestination,
  ExportDestinationType,
  Id,
  ScheduleType,
} from '@m3ter-com/m3ter-api';

export const getScheduleFrequencyDescription = (
  count: number,
  frequency: ScheduleType
): string => {
  return count === 1
    ? i18next.t('common:everyPeriod', {
        period: i18next.t(
          `features:dataExports.scheduleTypePeriods.singular.${frequency}`
        ),
      })
    : i18next.t('common:everyCountPeriod', {
        count,
        period: i18next.t(
          `features:dataExports.scheduleTypePeriods.plural.${frequency}`
        ),
      });
};

export const getDestinationTrustPolicy = (externalId: Id) => ({
  Version: '2012-10-17',
  Statement: [
    {
      Effect: 'Allow',
      Action: 'sts:AssumeRole',
      Principal: {
        AWS: 'arn:aws:iam::816069165829:role/m3terDataExports',
      },
      Condition: {
        StringEquals: {
          'sts:ExternalId': externalId,
        },
      },
    },
  ],
});

export const getDestinationPermissionPolicy = (
  bucketName: string,
  prefix?: string
) => ({
  Version: '2012-10-17',
  Statement: [
    {
      Effect: 'Allow',
      Action: [
        's3:PutObject',
        's3:ListMultipartUploadParts',
        's3:AbortMultipartUpload',
      ],
      Resource: `arn:aws:s3:::${bucketName}/${prefix ? `${prefix}/*` : '*'}`,
    },
  ],
});

export const getWorkloadIdentityPool = (poolId: string, providerId: string) =>
  `gcloud iam workload-identity-pools create "${poolId}" \\
  --location="global"

gcloud iam workload-identity-pools providers create-aws "${providerId}" \\
  --location="global" \\
  --workload-identity-pool="${poolId}" \\
  --account-id=816069165829 \\
  --attribute-mapping='google.subject=assertion.arn,attribute.aws_role=assertion.arn.contains("assumed-role") ? assertion.arn.extract("{account_arn}assumed-role/") + "assumed-role/" + assertion.arn.extract("assumed-role/{role_name}/") : assertion.arn' \\
  --attribute-condition="attribute.aws_role=='arn:aws:sts::816069165829:assumed-role/m3terDataExports'"`;

export const getBucketIAMPolicyBinding = (
  poolId: string,
  projectNumber: string,
  bucketName: string
) => `gcloud storage buckets add-iam-policy-binding "gs://${bucketName}" \\
  --role=roles/storage.objectCreator \\
  --member="principalSet://iam.googleapis.com/projects/${projectNumber}/locations/global/workloadIdentityPools/${poolId}/attribute.aws_role/arn:aws:sts::816069165829:assumed-role/m3terDataExports"`;

export const getServiceAccountIAMPolicyBinding = (
  poolId: string,
  projectNumber: string,
  bucketName: string,
  serviceAccountEmail: string
) => `gcloud iam service-accounts add-iam-policy-binding "${serviceAccountEmail}" \\
  --role=roles/iam.workloadIdentityUser \\
  --member="principalSet://iam.googleapis.com/projects/${projectNumber}/locations/global/workloadIdentityPools/${poolId}/attribute.aws_role/arn:aws:sts::816069165829:assumed-role/m3terDataExports"

gcloud storage buckets add-iam-policy-binding "gs://${bucketName}" \\
  --member="serviceAccount:${serviceAccountEmail}" \\
  --role="roles/storage.objectCreator"`;

export const getDestinationType = (
  destination: ExportDestination
): ExportDestinationType =>
  Object.hasOwn(destination, 'iamRoleArn')
    ? ExportDestinationType.AwsS3
    : ExportDestinationType.GoogleCloud;
