import React, { useMemo } from 'react';

import {
  Charge,
  ChargeEntityType,
  ChargeLineItemType,
} from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import { useCrudRetrieveContext } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';
import { ChargeForm } from '@/components/features/accounts/ChargeForm/ChargeForm';

export const ChargesCreateRoute: React.FC = () => {
  const { account } = useCrudRetrieveContext();

  const initialValues: Partial<Charge> = useMemo(
    () => ({
      accountId: account?.id,
      lineItemType: ChargeLineItemType.AdHoc,
      entityType: ChargeEntityType.AdHoc,
    }),
    [account]
  );

  return <CrudCreate<Charge> form={ChargeForm} initialValues={initialValues} />;
};
