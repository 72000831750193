import React, { useMemo } from 'react';

import orderBy from 'lodash/orderBy';
import {
  Avatar,
  Heading,
  Link,
  List,
  ListItem,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';

import { DataType, User } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import useEntityPermissionPolicyList from '@/hooks/features/access/useEntityPermissionPolicyList';
import useUserGroupList from '@/hooks/features/access/useUserGroupList';
import { ActiveStatusBadge } from '@/components/common/data/ActiveStatusBadge/ActiveStatusBadge';
import { AuditData } from '@/components/common/data/AuditData/AuditData';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';

export interface UserOverviewProps {
  user: User;
}

export const UserOverview: React.FC<UserOverviewProps> = ({ user }) => {
  const { t } = useTranslation();

  const { userGroups, userGroupsError, userGroupsLoading } = useUserGroupList(
    user.id
  );

  const {
    permissionPolicies,
    permissionPoliciesError,
    permissionPoliciesLoading,
  } = useEntityPermissionPolicyList({ dataType: DataType.User, id: user.id });

  const isUserActive = useMemo(
    () =>
      !user.dtEndAccess || new Date(user.dtEndAccess) > new Date(Date.now()),
    [user]
  );

  const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <LoadingErrorContentSwitch
      showContentOnError
      isLoading={permissionPoliciesLoading || userGroupsLoading}
      error={permissionPoliciesError ?? userGroupsError}
    >
      <VStack
        spacing={4}
        width="100%"
        alignItems="start"
        divider={<StackDivider />}
      >
        <VStack alignItems="center" spacing={2} width="100%">
          <Avatar name={fullName} verticalAlign="middle" size="lg" />
          <Link as={CrudDetailsLink} dataType={DataType.User} id={user.id}>
            <Heading as="h2" size="lg">
              {fullName}
            </Heading>
          </Link>
          <Text>{user.email}</Text>
          <ActiveStatusBadge ml={0} active={isUserActive} />
        </VStack>
        <KeyValue
          label={t('common:permissionPolicys')}
          value={
            permissionPolicies.length > 0 ? (
              <List>
                {orderBy(permissionPolicies, 'name').map((permission) => (
                  <ListItem key={permission.id}>
                    <ReferenceLink
                      dataType={DataType.PermissionPolicy}
                      id={permission.id}
                      accessor="name"
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              '-'
            )
          }
        />
        <KeyValue
          label={t('common:userGroups')}
          value={
            userGroups.length > 0 ? (
              <List>
                {orderBy(userGroups, 'name').map((userGroup) => (
                  <ListItem key={userGroup.id}>
                    <ReferenceLink
                      dataType={DataType.UserGroup}
                      id={userGroup.id}
                      accessor="name"
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              '-'
            )
          }
        />
        <AuditData data={user} />
      </VStack>
    </LoadingErrorContentSwitch>
  );
};
