import React from 'react';

import { NamedRoute } from '@/types/routes';

import { ProductsRoute } from '@/components/routes/products/ProductsRoute';
import { ProductsListRoute } from '@/components/routes/products/ProductsListRoute';
import { ProductsCreateRoute } from '@/components/routes/products/ProductsCreateRoute';
import { ProductsEditRoute } from '@/components/routes/products/ProductsEditRoute';
import { ProductsDetailsRoute } from '@/components/routes/products/ProductsDetailsRoute';
import { ProductsRetrieveRoute } from '@/components/routes/products/ProductsRetrieveRoute';

export default (): NamedRoute => ({
  path: 'products',
  name: 'products',
  element: <ProductsRoute />,
  children: [
    { index: true, name: 'product.list', element: <ProductsListRoute /> },
    {
      path: 'create',
      name: 'product.create',
      element: <ProductsCreateRoute />,
    },
    {
      path: ':productId',
      element: <ProductsRetrieveRoute />,
      children: [
        {
          index: true,
          name: 'product.details',
          element: <ProductsDetailsRoute />,
        },
        {
          path: 'edit',
          name: 'product.edit',
          element: <ProductsEditRoute />,
        },
      ],
    },
  ],
});
