import React from 'react';

import { DataType } from '@m3ter-com/m3ter-api';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';
import { IntegrationRunDetailsRedirectRoute } from '@/components/routes/integrations/runs/IntegrationRunDetailsRedirectRoute';

const LazyIntegrationRunsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationRunsRoute" */ '@/components/routes/integrations/runs/IntegrationRunsRoute'
  ).then((module) => ({ default: module.IntegrationRunsRoute }))
);

const LazyIntegrationRunsListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationRunsListRoute" */ '@/components/routes/integrations/runs/IntegrationRunsListRoute'
  ).then((module) => ({ default: module.IntegrationRunsListRoute }))
);

const LazyIntegrationRunDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationRunDetailsRoute" */ '@/components/routes/integrations/runs/IntegrationRunDetailsRoute'
  ).then((module) => ({ default: module.IntegrationRunDetailsRoute }))
);

export default () =>
  createCrudRoute({
    path: 'runs',
    dataType: DataType.IntegrationRun,
    element: <LazyIntegrationRunsRoute />,
    list: <LazyIntegrationRunsListRoute />,
    details: <LazyIntegrationRunDetailsRoute />,
    directChildRoutes: [
      { index: true, element: <IntegrationRunDetailsRedirectRoute /> },
    ],
  });
