import React, { useMemo } from 'react';

import { SimpleGrid } from '@chakra-ui/react';

import {
  chargeEntityTypeToDataType,
  DataType,
  DataTypeToEntity,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink/KeyValueReferenceLink';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { getReference } from '@/util/billing';
import { isNamedEntity } from '@/util/data';

interface ChargeDetailsProps<
  DT extends DataType.Charge | DataType.BalanceCharge
> {
  data: DataTypeToEntity[DT];
  dataType: DT;
}

export function ChargeDetails<
  DT extends DataType.Charge | DataType.BalanceCharge
>({ data: charge, dataType }: ChargeDetailsProps<DT>) {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDate, toLongDateTime } = useDateFormatter();

  const amount = useMemo(
    () => formatCurrency(charge.amount, charge.currency),
    [charge, formatCurrency]
  );

  const chargeEntityDataType =
    charge.entityType && chargeEntityTypeToDataType[charge.entityType];

  return (
    <DetailsCard
      showEditButton
      data={charge}
      dataType={dataType}
      showMetadata={false}
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={charge.name} />
          <KeyValue label={t('forms:labels.code')} value={charge.code} />
          <KeyValue
            label={t('forms:labels.servicePeriodStart')}
            value={toLongDateTime(charge.servicePeriodStartDate)}
          />
          <KeyValue
            label={t('forms:labels.servicePeriodEnd')}
            value={toLongDateTime(charge.servicePeriodEndDate)}
          />
          <KeyValue
            label={t('forms:labels.currency')}
            value={charge.currency}
          />
          <KeyValue label={t('forms:labels.amount')} value={amount} />
          <KeyValue
            label={t('features:billing.billDate')}
            value={charge.billDate ? toLongDate(charge.billDate) : '-'}
          />
          <KeyValueReferenceLink
            label={t('common:bill')}
            id={charge.billId}
            dataType={DataType.Bill}
            accessor={(bill) => getReference(bill)}
            defaultValue="-"
          />
          <KeyValue
            label={t('features:billing.lineItemTypeLabel')}
            value={t(`features:charges.lineItemTypes.${charge.lineItemType}`)}
          />
          <KeyValueReferenceLink
            label={t('forms:labels.accountingProduct')}
            id={charge.accountingProductId}
            dataType={DataType.Product}
            accessor="name"
            defaultValue="-"
          />
          <KeyValueReferenceLink
            label={t('common:contract')}
            id={charge.contractId}
            dataType={DataType.Contract}
            accessor="name"
            defaultValue="-"
          />
          {!chargeEntityDataType && (
            <KeyValue
              label={t('forms:labels.entityType')}
              value={t('features:charges.entityTypes.AD_HOC')}
            />
          )}
          {chargeEntityDataType && charge.entityId && (
            <KeyValue
              label={t(`common:${chargeEntityDataType}`)}
              value={
                <ReferenceLink
                  dataType={chargeEntityDataType}
                  id={charge.entityId}
                  accessor={(entity) => isNamedEntity(entity) && entity.name}
                />
              }
            />
          )}
          <KeyValue
            gridColumn="span 2"
            label={t('forms:labels.description')}
            value={charge.description || '-'}
          />
          <KeyValue
            gridColumn="span 2"
            label={t('forms:labels.notes')}
            value={charge.notes || '-'}
          />
        </SimpleGrid>
      }
    />
  );
}
