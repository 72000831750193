import { ElementType, ReactElement, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Spinner, Stack } from '@chakra-ui/react';

import {
  DataType,
  DataTypeToEntity,
  Id,
  PathParams,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { dataTypeRetrieveQuery } from '@/queries/crud';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useEntityRedirect, {
  RedirectTo,
} from '@/hooks/navigation/useEntityRedirect';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useEntityUpdateMutation from '@/hooks/data/useEntityUpdateMutation';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';

export interface EntityEditProps<DT extends DataType> {
  dataType: DT;
  id: Id;
  form: ElementType;
  defaultRedirectTo: RedirectTo<DataTypeToEntity[DT]>;
  extraData?: Record<string, any>;
  pathParams?: PathParams;
}

export function EntityEdit<DT extends DataType>({
  dataType,
  id,
  form: Form,
  defaultRedirectTo,
  extraData,
  pathParams,
}: EntityEditProps<DT>): ReactElement<any, any> {
  const { t } = useTranslation();
  const { singularLower: entityName } = useEntityNamings(dataType);

  const {
    updateEntity,
    isSaving,
    error: savingError,
  } = useEntityUpdateMutation(dataType);
  const { getReturnPath } = useEntityRedirect(defaultRedirectTo);

  const navigate = useNavigate();

  const orgPathParams = useOrgPathParams(pathParams);
  const {
    isLoading,
    error: loadingError,
    data: initialData,
  } = useQuery(
    dataTypeRetrieveQuery({
      dataType,
      id,
      pathParams: orgPathParams,
    })
  );

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(
    (data: DataTypeToEntity[DT]) => {
      updateEntity(
        { newEntity: data, pathParams },
        {
          onSuccess: (updatedData) => {
            // Redirect based on query string or the default redirect.
            navigate(getReturnPath(updatedData));
          },
        }
      );
    },
    [updateEntity, pathParams, navigate, getReturnPath]
  );

  return (
    <Stack>
      {loadingError && (
        <ErrorAlert
          title={t('errors:generic.problemLoadingData', { entityName })}
          error={loadingError}
        />
      )}
      {savingError && (
        <ErrorAlert
          title={t('errors:generic.problemSavingData', { entityName })}
          error={savingError}
        />
      )}
      {isLoading && <Spinner />}
      {initialData && (
        <Form
          isEdit
          isSaving={isSaving}
          onSave={onSave}
          onCancel={onCancel}
          initialValues={initialData}
          extraData={extraData}
          entityName={entityName}
          dataType={dataType}
        />
      )}
    </Stack>
  );
}
