import React, { ReactElement, ReactNode } from 'react';

import { Text } from '@chakra-ui/react';

import {
  OrganizationConfig,
  PlanGroup,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useOrg from '@/hooks/data/crud/useOrg';

type PlanGroupAndOrgConfigCommonKey = keyof UnsavedEntity<OrganizationConfig> &
  keyof UnsavedEntity<PlanGroup>;
export interface PlanGroupOrOrgConfigValueProps<
  K extends PlanGroupAndOrgConfigCommonKey
> {
  fieldName: K;
  format: (value: OrganizationConfig[K] | PlanGroup[K]) => ReactNode;
  planGroup: PlanGroup;
}

export const PlanGroupOrOrgConfigValue = <
  K extends PlanGroupAndOrgConfigCommonKey
>({
  fieldName,
  format,
  planGroup,
}: PlanGroupOrOrgConfigValueProps<K>): ReactElement<any, any> | null => {
  const { t } = useTranslation();
  const { orgConfig } = useOrg();

  const planGroupValue = planGroup[fieldName];
  const orgConfigValue = orgConfig[fieldName];

  if (planGroupValue !== undefined) {
    return (
      <React.Fragment>
        <Text>{format(planGroupValue)}</Text>
        <Text variant="annotation">
          {t('features:plans.orgConfigDefault')}:{' '}
          <s>{format(orgConfigValue)}</s>
        </Text>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Text>{format(orgConfigValue)}</Text>
      <Text variant="annotation">{t('features:plans.fromOrgConfig')}</Text>
    </React.Fragment>
  );
};
