import { ComponentMultiStyleConfig } from '@chakra-ui/react';

// Export the styles for the (single) `RadioTile` sub-component.
// This isn't exported as default in case we want to style `RadioTiles` later.
export const radioTile: ComponentMultiStyleConfig = {
  parts: ['wrapper', 'content'],
  baseStyle: {
    wrapper: {
      padding: 4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: 'lg',
    },
    content: {
      mt: 2,
      ml: 6,
      cursor: 'pointer',
    },
  },
  variants: {
    selected: {
      wrapper: {
        borderColor: 'misc-highlight',
        background: 'misc-hover',
      },
    },
  },
};
