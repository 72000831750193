import React, { PropsWithChildren, ReactNode } from 'react';

import { Badge, SimpleGrid } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import { DataType, PlanGroup } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import type { TFunction } from 'i18next';

import useCurrencies from '@/hooks/util/useCurrencies';
import { DetailsCard } from '@/components/common/data/DetailsCard/DetailsCard';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink/KeyValueReferenceLink';
import { getBillInAdvanceDescription } from '@/util/billing';

import { PlanGroupOrOrgConfigValue } from './PlanGroupOrOrgConfigValue';

export interface PlanGroupDetailsProps {
  planGroup: PlanGroup;
  showEditButton?: boolean;
  showIsCustomDetails?: boolean;
  showMetadata?: boolean;
  header?: ReactNode;
}

export const PlanGroupDetails: React.FC<
  PropsWithChildren<PlanGroupDetailsProps>
> = ({
  children,
  planGroup,
  showEditButton,
  showIsCustomDetails = false,
  showMetadata,
  header,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const isPlanGroupCustom = !!planGroup.accountId;

  return (
    <DetailsCard
      data={planGroup}
      dataType={DataType.PlanGroup}
      showEditButton={showEditButton}
      showMetadata={showMetadata}
      header={header}
      details={
        <SimpleGrid columns={4} gap={6} w="100%">
          <KeyValue label={t('forms:labels.name')} value={planGroup.name} />
          <KeyValue label={t('forms:labels.code')} value={planGroup.code} />
          {showIsCustomDetails && isPlanGroupCustom && (
            <KeyValue
              label={t('features:plans.planGroupType')}
              value={
                <span>
                  <Badge maxH="fit-content" colorScheme="green">
                    {t('common:custom')}
                  </Badge>{' '}
                  <Trans
                    t={t as TFunction}
                    i18nKey="features:account.customAccountLink"
                  >
                    for
                    <ReferenceLink
                      dataType={DataType.Account}
                      id={planGroup.accountId!}
                      accessor="name"
                    />
                  </Trans>
                </span>
              }
            />
          )}
          <KeyValue
            label={t('forms:labels.currency')}
            value={planGroup.currency}
          />
          <KeyValue
            label={t('forms:labels.standingCharge')}
            value={
              planGroup.standingCharge
                ? formatCurrency(planGroup.standingCharge, planGroup.currency)
                : '-'
            }
          />
          <KeyValue
            label={t('forms:labels.standingChargeBilling')}
            value={
              <PlanGroupOrOrgConfigValue
                fieldName="standingChargeBillInAdvance"
                format={getBillInAdvanceDescription}
                planGroup={planGroup}
              />
            }
          />
          <KeyValueReferenceLink
            label={t('forms:labels.standingChargeAccountingProduct')}
            dataType={DataType.Product}
            accessor="name"
            id={planGroup.standingChargeAccountingProductId}
            defaultValue="-"
          />
          <KeyValue
            label={t('forms:labels.standingChargeDescription')}
            value={planGroup.standingChargeDescription || '-'}
          />
          <KeyValue
            label={t('forms:labels.minimumSpend')}
            value={
              planGroup.minimumSpend
                ? formatCurrency(planGroup.minimumSpend, planGroup.currency)
                : '-'
            }
          />
          <KeyValue
            label={t('forms:labels.minimumSpendBilling')}
            value={
              <PlanGroupOrOrgConfigValue
                fieldName="minimumSpendBillInAdvance"
                format={getBillInAdvanceDescription}
                planGroup={planGroup}
              />
            }
          />
          <KeyValueReferenceLink
            label={t('forms:labels.minimumSpendAccountingProduct')}
            dataType={DataType.Product}
            accessor="name"
            id={planGroup.minimumSpendAccountingProductId}
            defaultValue="-"
          />
          <KeyValue
            label={t('forms:labels.minimumSpendDescription')}
            value={planGroup.minimumSpendDescription || '-'}
          />
        </SimpleGrid>
      }
    >
      {children}
    </DetailsCard>
  );
};
