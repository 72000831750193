import { useParams } from 'react-router-dom';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { PageWithBreadcrumbs } from '@/components/common/breadcrumbs/PageWithBreadcrumbs/PageWithBreadcrumbs';
import { EntityEdit } from '@/components/common/data/EntityEdit/EntityEdit';
import { ProductForm } from '@/components/features/products/ProductForm/ProductForm';

export const ProductsEditRoute: React.FC = () => {
  const { t } = useTranslation();
  const { productId } = useParams();

  return (
    <PageWithBreadcrumbs title={t('common:edit')}>
      <BreadcrumbItem title={t('common:edit')} />
      <EntityEdit
        dataType={DataType.Product}
        id={productId!}
        form={ProductForm}
        defaultRedirectTo={(product) => `../${product.id}`}
      />
    </PageWithBreadcrumbs>
  );
};
