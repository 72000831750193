import React from 'react';

import { Charge, DataType } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { ChargesRoute } from '@/components/routes/accounts/charges/ChargesRoute';
import { ChargesListRoute } from '@/components/routes/accounts/charges/ChargesListRoute';
import { ChargesDetailsRoute } from '@/components/routes/accounts/charges/ChargesDetailsRoute';
import { ChargesCreateRoute } from '@/components/routes/accounts/charges/ChargesCreateRoute';
import { ChargesEditRoute } from '@/components/routes/accounts/charges/ChargesEditRoute';

export default (): NamedRoute =>
  createCrudRoute<Charge>({
    path: 'charges',
    dataType: DataType.Charge,
    element: <ChargesRoute />,
    list: <ChargesListRoute />,
    details: <ChargesDetailsRoute />,
    create: <ChargesCreateRoute />,
    edit: <ChargesEditRoute />,
  });
