import React from 'react';

import { DataType, Account } from '@m3ter-com/m3ter-api';

import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';
import { ChargesList } from '@/components/features/accounts/ChargesList/ChargesList';

export const ChargesListRoute: React.FC = () => {
  const account = useCrudRetrieveData<Account>(DataType.Account);
  return <ChargesList account={account} />;
};
