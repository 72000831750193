import { DataTypeToEntity } from './datatype-entity-map';
import { ChargeEntityType, DataType } from './enums';

type ChargeEntityTypeToDataTypeEnum = {
  [ChargeEntityType.Balance]: DataType.Balance;
};
export type ChargeEntityTypeToDataType<E extends ChargeEntityType> =
  E extends keyof ChargeEntityTypeToDataTypeEnum
    ? ChargeEntityTypeToDataTypeEnum[E]
    : never;

export const chargeEntityTypeToDataType: Partial<
  Record<ChargeEntityType, DataType>
> &
  ChargeEntityTypeToDataTypeEnum = {
  [ChargeEntityType.Balance]: DataType.Balance,
};

export type ChargeEntityTypeToEntity<E extends ChargeEntityType> =
  E extends keyof ChargeEntityTypeToDataTypeEnum
    ? DataTypeToEntity[ChargeEntityTypeToDataTypeEnum[E]]
    : never;
