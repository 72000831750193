import { create, each, enforce, test } from 'vest';
import i18next from 'i18next';

import type { ExternalMapping } from '@m3ter-com/m3ter-api';

import type {
  ExternalMappingsCreateFormSettingsStepValues,
  ExternalMappingsCreateFormIdsStepValues,
} from '@/components/features/integrations/ExternalMappingForms/ExternalMappingsCreateForm';

export const createStepOne = create(
  'external-mapping-create-step-one',
  (data: Partial<ExternalMappingsCreateFormSettingsStepValues>) => {
    test(
      'm3terEntity',
      i18next.t('forms:validations.externalMappings.m3terEntityRequired'),
      () => {
        enforce(data.m3terEntity).isNotEmpty();
      }
    );

    test(
      'externalSystem',
      i18next.t('forms:validations.externalMappings.externalSystemRequired'),
      () => {
        enforce(data.externalSystem).isNotEmpty();
      }
    );

    test(
      'externalTable',
      i18next.t('forms:validations.externalMappings.externalTableRequired'),
      () => {
        enforce(data.externalTable).isNotEmpty();
      }
    );
  }
);

export const createStepTwo = create(
  'external-mapping-create-step-two',
  (data: Partial<ExternalMappingsCreateFormIdsStepValues>) => {
    test(
      'mappings',
      i18next.t('forms:validations.externalMappings.atLeastOneMappingRequired'),
      () => !!data.mappings?.length
    );

    each(data.mappings || [], (mapping, mappingIndex) => {
      test(
        `mappings.${mappingIndex}.m3terId`,
        i18next.t('forms:validations.externalMappings.m3terIdRequired'),
        () => {
          enforce(mapping.m3terId).isNotEmpty();
        }
      );

      test(
        `mappings.${mappingIndex}.externalId`,
        i18next.t('forms:validations.externalMappings.externalIdRequired'),
        () => {
          enforce(mapping.externalId).isNotEmpty();
        }
      );
    });
  }
);

export const edit = create(
  'external-mapping-edit',
  (data: Partial<ExternalMapping>) => {
    test(
      'm3terId',
      i18next.t('forms:validations.externalMappings.m3terIdRequired'),
      () => {
        enforce(data.m3terId).isNotEmpty();
      }
    );

    test(
      'externalId',
      i18next.t('forms:validations.externalMappings.externalIdRequired'),
      () => {
        enforce(data.externalId).isNotEmpty();
      }
    );
  }
);
