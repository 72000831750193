export enum SearchComparator {
  Equal = ':',
  NotEqual = '!:',
  Contains = '~',
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  LessThan = '<',
  LessThanOrEqual = '<=',
}

export interface SearchCriterion {
  field: string;
  comparator: SearchComparator;
  value: string | number | boolean;
}

export enum SearchOperator {
  And = 'AND',
  Or = 'OR',
}

export enum SearchSortOrder {
  Ascending = 'ASC',
  Descending = 'DESC',
}
