import {
  createAction,
  createSelector,
  createSlice,
  PayloadAction,
  PrepareAction,
} from '@reduxjs/toolkit';

import { Id } from '@m3ter-com/m3ter-api';
import { WithRequired } from '@m3ter-com/console-core/types';

import { AppError } from '@/types/errors';

import { NotificationDefinition, RequestMeta } from '@/store/store';

export interface AccountContractsState {
  isAddingAccountPlans: boolean;
  isAddingCommitments: boolean;
  isLoading: boolean;
  error?: AppError;
}

interface AddAccountPlansToContractPayload {
  accountPlanIds: Array<Id>;
  contractId: Id;
}
export type AddAccountPlansToContractAction = PayloadAction<
  AddAccountPlansToContractPayload,
  string,
  WithRequired<RequestMeta, 'onSuccess'>
>;

interface AddCommitmentsToContractPayload {
  commitmentIds: Array<Id>;
  contractId: Id;
}
export type AddCommitmentsToContractAction = PayloadAction<
  AddCommitmentsToContractPayload,
  string,
  WithRequired<RequestMeta, 'onSuccess'>
>;

interface RemoveAccountPlanFromContractPayload {
  accountPlanId: Id;
}
export type RemoveAccountPlanFromContractAction = PayloadAction<
  RemoveAccountPlanFromContractPayload,
  string,
  Required<RequestMeta>
>;

interface RemoveCommitmentFromContractPayload {
  commitmentId: Id;
}
export type RemoveCommitmentFromContractAction = PayloadAction<
  RemoveCommitmentFromContractPayload,
  string,
  Required<RequestMeta>
>;

export const initialState: AccountContractsState = {
  isAddingAccountPlans: false,
  isAddingCommitments: false,
  isLoading: false,
};
const name = 'features/accounts/accountContracts';

const accountContractsState = createSlice({
  name,
  initialState,
  reducers: {
    addAccountPlansToContract: {
      reducer: (
        state: AccountContractsState,
        _action: AddAccountPlansToContractAction
      ) => {
        state.isAddingAccountPlans = true;
      },
      prepare: (
        contractId: Id,
        accountPlanIds: Array<Id>,
        successNotification: NotificationDefinition
      ) => ({
        payload: { accountPlanIds, contractId },
        meta: {
          onSuccess: {
            notification: successNotification,
          },
        },
      }),
    },
    addAccountPlansToContractComplete: (state: AccountContractsState) => {
      state.isAddingAccountPlans = false;
    },
    addCommitmentsToContract: {
      reducer: (
        state: AccountContractsState,
        _action: AddCommitmentsToContractAction
      ) => {
        state.isAddingCommitments = true;
      },
      prepare: (
        contractId: Id,
        commitmentIds: Array<Id>,
        successNotification: NotificationDefinition
      ) => ({
        payload: { commitmentIds, contractId },
        meta: {
          onSuccess: {
            notification: successNotification,
          },
        },
      }),
    },
    addCommitmentsToContractComplete: (state: AccountContractsState) => {
      state.isAddingCommitments = false;
    },
    reset: () => initialState,
  },
});

export default accountContractsState.reducer;

export const removeAccountPlanFromContract = createAction<
  PrepareAction<RemoveAccountPlanFromContractPayload>
>(
  `${name}/removeAccountPlanFromContract`,
  (
    accountPlanId: Id,
    successNotification: NotificationDefinition,
    failureNotification: NotificationDefinition
  ) => ({
    payload: { accountPlanId },
    meta: {
      onFailure: {
        notification: failureNotification,
      },
      onSuccess: {
        notification: successNotification,
      },
    },
  })
);
export const removeCommitmentFromContract = createAction<
  PrepareAction<RemoveCommitmentFromContractPayload>
>(
  `${name}/removeCommitmentFromContract`,
  (
    commitmentId: Id,
    successNotification: NotificationDefinition,
    failureNotification: NotificationDefinition
  ) => ({
    payload: { commitmentId },
    meta: {
      onFailure: {
        notification: failureNotification,
      },
      onSuccess: {
        notification: successNotification,
      },
    },
  })
);

export const {
  addAccountPlansToContract,
  addAccountPlansToContractComplete,
  addCommitmentsToContract,
  addCommitmentsToContractComplete,
  reset,
} = accountContractsState.actions;

const selectAccountContractsState = (state: {
  features: { accounts: { accountContracts: AccountContractsState } };
}): AccountContractsState => state.features.accounts.accountContracts;

export const selectIsAddingAccountPlans = createSelector(
  selectAccountContractsState,
  (state) => state.isAddingAccountPlans
);

export const selectIsAddingCommitments = createSelector(
  selectAccountContractsState,
  (state) => state.isAddingCommitments
);

export const selectIsAccountContractsLoading = createSelector(
  selectAccountContractsState,
  (state) => state.isLoading
);

export const selectAccountContractsError = createSelector(
  selectAccountContractsState,
  (state) => state.error
);
