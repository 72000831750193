import React from 'react';

import { DataType, IntegrationConfig } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';
import { integrationDetailsAccessor } from '@/util/integrations';
import { IntegrationConfigsRoute } from '@/components/routes/integrations/configs/IntegrationConfigsRoute';
import { IntegrationConfigsListRedirectRoute } from '@/components/routes/integrations/configs/IntegrationConfigsListRedirectRoute';
import { IntegrationConfigEditRoute } from '@/components/routes/integrations/configs/IntegrationConfigEditRoute';
import { IntegrationConfigCreateRoute } from '@/components/routes/integrations/configs/IntegrationConfigCreateRoute';

const LazyIntegrationConfigDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "IntegrationConfigDetailsRoute" */ '@/components/routes/integrations/configs/IntegrationConfigDetailsRoute'
  ).then((module) => ({ default: module.IntegrationConfigDetailsRoute }))
);

export default (): NamedRoute =>
  createCrudRoute<IntegrationConfig>({
    path: 'configurations',
    titleAccessor: integrationDetailsAccessor,
    dataType: DataType.Integration,
    element: <IntegrationConfigsRoute />,
    list: <IntegrationConfigsListRedirectRoute />,
    details: <LazyIntegrationConfigDetailsRoute />,
    edit: <IntegrationConfigEditRoute />,
    create: <IntegrationConfigCreateRoute />,
  });
