import { PropsWithChildren, useMemo } from 'react';

import {
  Box,
  BoxProps,
  StyleProps,
  useMultiStyleConfig,
} from '@chakra-ui/react';

export interface DataGridCellProps extends BoxProps {
  isPinnedFirst?: boolean;
  isPinnedLast?: boolean;
}

const sharedPinnedProps: StyleProps = {
  position: 'sticky',
  zIndex: 1,
};

export const DataGridCell: React.FC<PropsWithChildren<DataGridCellProps>> = ({
  isPinnedFirst,
  isPinnedLast,
  children,
  ...boxProps
}) => {
  const styles = useMultiStyleConfig('DataGrid', {});

  const pinnedProps = useMemo<StyleProps>(() => {
    if (isPinnedFirst) {
      return { ...sharedPinnedProps, left: 0 };
    }
    if (isPinnedLast) {
      return { ...sharedPinnedProps, right: 0, borderLeftWidth: 1 };
    }
    return {};
  }, [isPinnedFirst, isPinnedLast]);

  return (
    <Box __css={styles.cell} role="cell" {...pinnedProps} {...boxProps}>
      {children}
    </Box>
  );
};
