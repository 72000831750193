import { ReactElement } from 'react';

import { HStack, Stack } from '@chakra-ui/react';
import { RefreshCwIcon } from 'lucide-react';

import {
  DataType,
  Entity,
  PathParams,
  QueryParams,
} from '@m3ter-com/m3ter-api';
import {
  DataGrid,
  DataGridColumnDefinition,
  IconButton,
  SearchBar,
} from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BaseEntityListProps } from '@/types/lists';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { EntityListEmptyContent } from '@/components/common/data/EntityListEmptyContent/EntityListEmptyContent';
import { EntityListFooter } from '@/components/common/data/EntityListFooter/EntityListFooter';

import useEntityList from './useEntityList';

// Re-export to avoid needing to import from UI components.
export type ColumnDefinition<E extends Entity = Entity> =
  DataGridColumnDefinition<E>;

export interface EntityListProps<DT extends DataType>
  extends BaseEntityListProps<DT> {
  actionName?: string;
  pathParams?: PathParams;
  queryParams?: QueryParams;
  relationships?: Array<string>;
  searchFields?: Array<string>;
}

export function EntityList<DT extends DataType>({
  dataType,
  columnDefinitions,
  actionName,
  pathParams,
  queryParams,
  relationships,
  searchFields,
  headerActions,
  footerActions,
  styleProps,
  ...dataGridProps
}: EntityListProps<DT>): ReactElement<any, any> {
  const { t } = useTranslation();

  const entityNamings = useEntityNamings(dataType);

  const {
    currentPage,
    currentPageData,
    error,
    goToNextPage,
    goToPage,
    hasMore,
    isFetching,
    knownPageCount,
    onPageSizeChange,
    pageSize,
    refetch,
    search,
    query,
  } = useEntityList({
    dataType,
    actionName,
    relationships,
    pathParams,
    queryParams,
    searchFields,
  });

  return (
    <Stack spacing={4} {...styleProps}>
      {error && <ErrorAlert error={error} />}
      <HStack spacing={4} alignItems="start">
        <SearchBar
          initialValue={query}
          placeholder={t('common:searchEntity', {
            entity: entityNamings.pluralLower,
          })}
          onSearch={search}
          onClear={() => {
            search(undefined);
          }}
        />
        <IconButton
          aria-label={t('common:refresh')}
          icon={<RefreshCwIcon size={16} />}
          isDisabled={isFetching}
          onClick={() => {
            refetch();
          }}
        />
        {headerActions}
      </HStack>
      <DataGrid
        {...dataGridProps}
        isLoading={isFetching}
        columnDefinitions={columnDefinitions}
        idAccessor="id"
        items={currentPageData}
        emptyContent={
          <EntityListEmptyContent
            dataType={dataType}
            isSearching={!!query}
            onClearSearch={() => {
              search(undefined);
            }}
          />
        }
        // Styling
        flex={1}
        borderRadius="md"
      />
      <EntityListFooter
        actions={footerActions}
        currentPage={currentPage}
        goToNextPage={goToNextPage}
        goToPage={goToPage}
        hasMore={hasMore}
        knownPageCount={knownPageCount}
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
      />
    </Stack>
  );
}
