import omit from 'lodash/omit';

import {
  AuditedEntity,
  CustomFields,
  Entity,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';

export type Accessor<D, ReturnType = any> = keyof D | ((item: D) => ReturnType);

// Takes an array of entities and creates a object with the id as the key and the entity as the value.
// E.g. [{ id: 'abc123', name: 'Product1' }] => { abc123: { id: 'abc123', name: 'Product1' } }
export const entitiesById = <T extends Entity>(
  entities: T[]
): Record<string, T> => Object.fromEntries(entities.map((e) => [e.id, e]));

// Takes an array of entities and returns an array of their IDs.
export const ids = (entities: Entity[]) => entities.map(({ id }) => id);

// Takes an array of entities and returns a new array with only unique entities.
// E.g. [{ id: 'abc123', name: 'Product1' }, { id: 'abc123', name: 'Product1' }] => [{ id: 'abc123', name: 'Product1' }]
export const uniqueEntities = <T extends Entity>(entities: T[]): T[] =>
  Object.values(entitiesById(entities));

// Shallow copies an entity and removes id, version and audit data properties.
export const cloneEntity = <T extends Entity>(
  entity: T,
  overrides: Partial<T> = {}
) =>
  ({
    ...omit(entity, [
      'id',
      'version',
      'createdBy',
      'dtCreated',
      'dtLastModified',
      'lastModifiedBy',
    ]),
    ...overrides,
  } as UnsavedEntity<T>);

export const getAccessorValue = <E extends Entity>(
  data: E,
  accessor: Accessor<E>
): any => {
  return typeof accessor === 'function' ? accessor(data) : data[accessor];
};

export const sortByStringAccessor = <E extends Entity>(
  entities: Array<E>,
  accessor: Accessor<E, string>
): Array<E> =>
  entities.sort((entityA, entityB) =>
    String.prototype.localeCompare.call(
      getAccessorValue(entityA, accessor),
      getAccessorValue(entityB, accessor)
    )
  );

const quantityTextRegexp = /[{}]/g;

export const formatEntityUnit = (quantityText: string) => {
  return quantityText.replace(quantityTextRegexp, '');
};

export const isEntityWithCustomFields = (
  entity: Entity
): entity is Entity & { customFields?: CustomFields } =>
  Object.prototype.hasOwnProperty.call(entity, 'customFields');

export const isAuditedEntity = (entity: Entity): entity is AuditedEntity =>
  Object.prototype.hasOwnProperty.call(entity, 'createdBy') &&
  Object.prototype.hasOwnProperty.call(entity, 'lastModifiedBy');

export const isNamedEntity = <E extends Entity>(
  entity: E
): entity is E & { name: string } => Object.hasOwn(entity, 'name');

export const buildSelectOptions = <ET extends Entity>(
  data: Array<ET>,
  valueAccessor: Accessor<ET>,
  labelAccessor: Accessor<ET>,
  secondayLabelAccessor?: Accessor<ET>
): Array<SelectOption> =>
  data.map((item: ET) => ({
    value: getAccessorValue(item, valueAccessor),
    label: getAccessorValue(item, labelAccessor),
    secondaryLabel: secondayLabelAccessor
      ? getAccessorValue(item, secondayLabelAccessor)
      : undefined,
  }));
