import { PlusCircleIcon, SearchIcon } from 'lucide-react';

import { DataType } from '@m3ter-com/m3ter-api';
import { Button, EmptyState } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';

export interface EntityListEmptyContentProps {
  dataType: DataType;
  isSearching: boolean;
  onClearSearch: () => void;
}

export const EntityListEmptyContent: React.FC<EntityListEmptyContentProps> = ({
  dataType,
  isSearching,
  onClearSearch,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(dataType);

  return (
    <EmptyState
      icon={isSearching ? SearchIcon : PlusCircleIcon}
      title={
        isSearching
          ? t('features:search.noResultsFound')
          : t('features:search.noEntities', {
              entityName: entityNamings.pluralLower,
            })
      }
      content={
        isSearching
          ? t('features:search.noEntitiesMatchMessage', {
              entityName: entityNamings.pluralLower,
            })
          : t('features:search.noEntitiesMessage', {
              entityName: entityNamings.pluralLower,
            })
      }
      actions={
        isSearching ? (
          <Button onClick={onClearSearch}>
            {t('features:search.clearSearch')}
          </Button>
        ) : (
          <Button as={CrudCreateLink} dataType={dataType}>
            {t('forms:buttons.createEntity', {
              entityName: entityNamings.singularLower,
            })}
          </Button>
        )
      }
    />
  );
};
