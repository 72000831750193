import { useMemo } from 'react';

import { skipToken, useQuery } from '@tanstack/react-query';

import {
  ExternalMappingEntityType,
  getIntegrationsExternalEntities,
} from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  FormCreatableSelect,
  FormField,
} from '@m3ter-com/console-core/components';

import useOrg from '@/hooks/data/crud/useOrg';

export interface ExternalMappingExternalIdFieldProps {
  externalSystem?: string;
  hideLabel?: boolean;
  integrationCredentialId?: string;
  m3terEntityType?: ExternalMappingEntityType;
  name: string;
}

export const ExternalMappingExternalIdField: React.FC<
  ExternalMappingExternalIdFieldProps
> = ({
  externalSystem,
  hideLabel = false,
  integrationCredentialId,
  m3terEntityType,
  name,
}) => {
  const { t } = useTranslation();
  const { currentOrgId: organizationId } = useOrg();

  const { isLoading: isLoadingExternalEntities, data: externalEntities } =
    useQuery({
      queryFn:
        !!externalSystem && !!m3terEntityType
          ? () =>
              getIntegrationsExternalEntities({
                organizationId,
                externalSystem,
                m3terEntityType,
                integrationCredentialId,
              })
          : skipToken,
      queryKey: [
        'externalEntities',
        organizationId,
        externalSystem,
        m3terEntityType,
        integrationCredentialId,
      ],
    });

  const externalIdOptions = useMemo<Array<SelectOption>>(
    () =>
      (externalEntities || []).map((entity) => ({
        value: entity.externalId,
        label: entity.externalName ? entity.externalName : entity.externalId,
        secondaryLabel: entity.externalName ? entity.externalId : undefined,
      })),
    [externalEntities]
  );

  return (
    <FormField
      isRequired
      name={name}
      label={t('forms:labels.externalEntityId')}
      isDisabled={!externalSystem || !m3terEntityType}
      control={FormCreatableSelect}
      options={externalIdOptions}
      isLoading={isLoadingExternalEntities}
      hideLabel={hideLabel}
    />
  );
};
