import { Balance, DataType } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { BalancesRoute } from '@/components/routes/accounts/balances/BalancesRoute';
import { BalancesListRoute } from '@/components/routes/accounts/balances/BalancesListRoute';
import { BalancesCreateRoute } from '@/components/routes/accounts/balances/BalancesCreateRoute';
import { BalancesEditRoute } from '@/components/routes/accounts/balances/BalancesEditRoute';
import { BalancesDetailsRoute } from '@/components/routes/accounts/balances/BalancesDetailsRoute';
import { BalanceRetrieveRoute } from '@/components/routes/accounts/balances/BalanceRetrieveRoute';

import setupBalanceChargeSchedulesRoute from './balance-charge-schedules';
import setupBalanceChargesRoute from './balance-charges';
import setupBalanceTransactionsRoute from './balance-transactions';
import setupBalanceTransactionSchedulesRoute from './balance-transaction-schedules';

export default (): NamedRoute =>
  createCrudRoute<Balance>({
    path: 'balances',
    dataType: DataType.Balance,
    element: <BalancesRoute />,
    list: <BalancesListRoute />,
    create: <BalancesCreateRoute />,
    edit: <BalancesEditRoute />,
    details: <BalancesDetailsRoute />,
    retrieve: <BalanceRetrieveRoute />,
    entityChildRoutes: [
      setupBalanceChargeSchedulesRoute(),
      setupBalanceChargesRoute(),
      setupBalanceTransactionSchedulesRoute(),
      setupBalanceTransactionsRoute(),
    ],
  });
