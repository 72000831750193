import { useMemo } from 'react';

import sortBy from 'lodash/sortBy';

import { ExternalMappingConfig } from '@m3ter-com/m3ter-api';
import { FormField, FormSelect } from '@m3ter-com/console-core/components';
import { SelectOption } from '@m3ter-com/ui-components';

export interface IntegrationsSystemSelectProps {
  externalMappingConfig: ExternalMappingConfig;
  includeM3ter?: boolean;
  label: string;
  name: string;
}

export const IntegrationsSystemSelect: React.FC<
  IntegrationsSystemSelectProps
> = ({ externalMappingConfig, includeM3ter = false, label, name }) => {
  const options = useMemo<Array<SelectOption>>(() => {
    const result = externalMappingConfig.externalSystems.map((system) => ({
      value: system.name,
      label: system.name,
    }));
    if (includeM3ter) {
      result.push({ value: 'm3ter', label: 'm3ter' });
    }
    return sortBy(result, (option) => option.label.toLowerCase());
  }, [externalMappingConfig, includeM3ter]);

  return (
    <FormField
      isRequired
      name={name}
      label={label}
      control={FormSelect}
      options={options}
    />
  );
};
