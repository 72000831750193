import i18next from 'i18next';
import Big from 'big.js';
import orderBy from 'lodash/orderBy';

import {
  Bill,
  BillBandUsage,
  BillConfig,
  BillLineItem,
  CurrencyCode,
  Frequency,
  Id,
} from '@m3ter-com/m3ter-api';
import { addOrdinalSuffix } from '@m3ter-com/console-core/utils';

import { uniq } from './array';

export const OTHER_LINE_ITEMS = 'other-line-items';
const UNICODE_NON_CHARACTER = '\uffff';

/**
 * Totals the line item converted sub totals to get a bill total.
 * It rounds each line item to avoid cases like this, where if there
 * are 2 line items at $0.004 the bill would show as
 *
 *   Line Item 1    $0.00
 *   Line Item 2    $0.00
 *   Total          $0.01  (rounded from $0.008)
 */
export const getBillTotal = (bill: Bill, decimalPlaces: number = 2): number =>
  (bill.lineItems || [])
    .reduce(
      (total, lineItem) =>
        total.plus(new Big(lineItem.convertedSubtotal).round(decimalPlaces)),
      new Big(0)
    )
    .round(decimalPlaces)
    .toNumber();

/**
 * Extracts unique line item IDs from an array of bills.
 *
 * This function processes each bill's line items to determine a unique set of IDs
 * based on the following priority:
 * 1. If `planGroupId` exists, it is added to the set.
 * 2. If both `commitmentId` and `productId` exist, `productId` is added to the set.
 * 3. If only `commitmentId` exists, it is added to the set.
 * 4. If only `productId` exists, it is added to the set.
 * 5. If none of the above IDs exist, a default `OTHER_LINE_ITEMS` ID is added to the set.
 *
 * @param {Array<Bill>} bills - The array of bills to process.
 * @returns {Array<Id>} An array of unique line item IDs.
 *
 * Note:
 * The `productId` is the primary identifier for grouping (group by product). However, it will also serve as the
 * `accountingProductId` in certain scenarios, such as a standing charge or minimum spend accounting product
 * on a plan group. In these cases, the line item for the associated plan group will have both a `productId`
 * (accounting product) and a `planGroupId`. Here, the plan group identifier should be included in
 * the set, rather than the `productId`, to ensure proper grouping within the plan group (group by
 * `planGroupId`). The accounting product ID will also be present on other line items, and in these cases,
 * they should be grouped by product, such as a commitment with an accounting product, in this case we want
 * to group by product.
 */
export const getUniqueBillLineItemGroupingIds = (
  bills: Array<Bill>
): Array<Id> =>
  uniq(
    bills.flatMap((bill) =>
      (bill.lineItems || []).flatMap((lineItem) => {
        const uniqueIds = new Set<Id>();
        // Plan Group ID takes priority when it exists.
        if (lineItem.planGroupId) {
          uniqueIds.add(lineItem.planGroupId);
        }
        // Commitment ID with Product ID - Product ID is chosen.
        else if (lineItem.commitmentId && lineItem.productId) {
          uniqueIds.add(lineItem.productId);
        }
        // Only Commitment ID - Commitment ID is chosen.
        else if (lineItem.commitmentId) {
          uniqueIds.add(lineItem.commitmentId);
        }
        // Only Product ID - Product ID is chosen.
        else if (lineItem.productId) {
          uniqueIds.add(lineItem.productId);
        }
        // No relevant IDs - OTHER_LINE_ITEMS is added
        else {
          uniqueIds.add(OTHER_LINE_ITEMS);
        }
        return Array.from(uniqueIds);
      })
    )
  );

/**
 * Eliminates duplicate line items from bills based on their IDs.
 *
 * @param billsByIds - An array of tuples where each tuple contains a grouping ID and an array of bills.
 * @returns An array of tuples where each tuple contains a grouping ID and an array of bills with unique line items.
 *
 * Note:
 * The number of unique line items displayed in the console should match the count received from the API.
 * This function ensures that duplicate line items are not displayed in the console, serving as a safeguard.
 */
export const dedupBillLineItems = (
  billsByIds: Array<[Id, Array<Bill>]>
): Array<[Id, Array<Bill>]> => {
  const billsByIdWithUniqueLineItems = billsByIds.map<[Id, Array<Bill>]>(
    ([id, bills]) => {
      const uniqueLineItemIds = new Set<Id>();
      const deduplicatedBills = bills.map<Bill>((bill) => {
        const deduplicatedLineItems = (bill.lineItems || []).filter(
          (lineItem) => {
            if (uniqueLineItemIds.has(lineItem.id)) {
              return false;
            }
            uniqueLineItemIds.add(lineItem.id);
            return true;
          }
        );

        return { ...bill, lineItems: deduplicatedLineItems };
      });

      return [id, deduplicatedBills];
    }
  );

  return billsByIdWithUniqueLineItems;
};

/**
 * Determines the priority of a bill line item group based on its properties.
 *
 * @param billsById - An array of tuples where the first element is an ID and the second element is an array of bills.
 * @param id - The ID of the bill line item group to find the priority for.
 * @returns The priority of the bill line item group:
 * - 1: Products (sorted by name later)
 * - 2: Plan Groups
 * - 3: Commitments
 * - 4: Other Line Items
 */
export const getBillLineItemGroupPriority = (
  billsById: Array<[Id, Array<Bill>]>,
  id: string
) => {
  const billGroup = billsById.find(([key]) => key === id);
  const firstItem = billGroup && billGroup[1][0]?.lineItems?.[0];
  // Other Line Items
  if (!firstItem) {
    return 4;
  }

  if (id !== OTHER_LINE_ITEMS) {
    // Products (sorted by name later)
    if (firstItem.productName) {
      return 1;
    }
    // Plan Groups
    if (firstItem.planGroupId) {
      return 2;
    }
    // Commitments
    if (firstItem.commitmentId) {
      return 3;
    }
  }
  // Other Line Items
  return 4;
};

/**
 * Splits bills into groups based on unique identifiers found in their line items (product, commitment, or plan group IDs).
 * Returns an array of tuples where each tuple contains a unique identifier and an array of bills,
 * with each bill containing only the line items that match the identifier.
 * The resulting groups are deduplicated and ordered by priority and product name.
 *
 * @param {Array<Bill>} bills - The array of bills to process.
 * @returns {Array<[string, Array<Bill>]>} An array of tuples where each tuple contains a unique identifier and an array of bills.
 */
export const splitBillsByIds = (
  bills: Array<Bill>
): Array<[string, Array<Bill>]> => {
  /**
   * Extract unique identifiers from bill line items, which can be product, commitment, or plan group IDs.
   * If none of these IDs are present, group the remaining items under the key 'other-line-items'.
   */
  const billsById = getUniqueBillLineItemGroupingIds(bills).map<
    [Id, Array<Bill>]
  >((id) => {
    const billDuplicates = bills.map((bill) => {
      const relevantLineItems = (bill.lineItems || []).filter((lineItem) => {
        // If planGroupId exists, group by it.
        if (lineItem.planGroupId) {
          return lineItem.planGroupId === id;
        }
        // If both commitmentId and productId exist, group by productId.
        if (lineItem.commitmentId && lineItem.productId) {
          return lineItem.productId === id;
        }
        // If only commitmentId exists, group by commitmentId.
        if (lineItem.commitmentId) {
          return lineItem.commitmentId === id;
        }
        // If only productId exists, group by productId.
        if (lineItem.productId) {
          return lineItem.productId === id;
        }
        // If no relevant IDs, group under OTHER_LINE_ITEMS.
        return (
          id === OTHER_LINE_ITEMS &&
          !lineItem.productId &&
          !lineItem.commitmentId &&
          !lineItem.planGroupId
        );
      });

      // Order the line items by aggregationId, compoundAggregationId, counterId so they are grouped in the array.
      const orderedRelevantLineItems = orderBy(relevantLineItems, [
        (lineItem) =>
          lineItem.aggregationId ||
          lineItem.compoundAggregationId ||
          lineItem.counterId,
      ]);

      return { ...bill, lineItems: orderedRelevantLineItems };
    });

    return [id, billDuplicates];
  });

  // Safe guard to ensure that the number of unique line items displayed in the console matches the count received from the API.
  const uniqueBillLineItems = dedupBillLineItems(billsById);

  // Order the unique bill line items by priority.
  const orderUniqueBillLineItems = orderBy(uniqueBillLineItems, [
    ([id]) => getBillLineItemGroupPriority(uniqueBillLineItems, id),
    /**
     * Sort bills by product name, bills with an empty string as the product name are moved
     * to the end of the array. This is so the grouped product bill line items are sorted
     * alphabetically and placed before other line items groups without a product, e.g. plan
     * group line items, or prepayment line items, these will be placed at the end.
     */
    ([_, bill]) => {
      /**
       * If we have line items on the bill and the first one has a product name
       * (we do not want an  empty string) we use the product name as the sorting value.
       */
      const firstLineItem = bill[0]?.lineItems?.[0];
      if (firstLineItem?.productName) {
        return firstLineItem.productName;
      }
      /**
       * Otherwise we return the default value as the unicode "non-character" so that
       * these grouped bill line items will be sorted last.
       */
      return UNICODE_NON_CHARACTER;
    },
  ]);
  return orderUniqueBillLineItems;
};

/**
 * Checks a bill's locked property and, optionally, the
 * global lock date set on the org's bill config, if one exists.
 */
export const isBillLocked = (bill: Bill, billConfig?: BillConfig) => {
  if (!billConfig?.billLockDate) return bill.locked;
  const globalLockDate = new Date(billConfig.billLockDate);
  const billEndDate = new Date(bill.endDate);

  return globalLockDate >= billEndDate || bill.locked;
};

/**
 * Gets the total number of units for a line item. Defaults to `units` if present, then
 * if there is usage breakdown this is the total of all bands, otherwise it's the quantity.
 */
export const getLineItemUnits = (lineItem: BillLineItem): number =>
  lineItem.units ??
  (lineItem.usagePerPricingBand
    ? lineItem.usagePerPricingBand.reduce((total, band) => {
        return total + band.bandUnits;
      }, 0)
    : lineItem.quantity);

/**
 * Returns a summary of the unit prices within an array of usage bands.
 * If there is one unit price band it returns a single formatted value.
 * If there are multiple unit price bands it returns a min-max formatted range.
 * If there are no unit price bands it returns an empty string.
 */
export const getUnitPriceSummary = (
  usagePerPriceBand: Array<BillBandUsage>,
  currency: CurrencyCode,
  formatter: (
    value: number,
    currency: CurrencyCode,
    precise?: boolean
  ) => string
): string => {
  const unitPrices = usagePerPriceBand
    .filter((band) => !band.fixedPrice)
    .map((band) => band.unitPrice)
    .sort((a, b) => a - b);

  if (unitPrices.length === 0) {
    return '';
  }
  if (unitPrices.length === 1) {
    return formatter(unitPrices[0], currency, true);
  }
  // Return a min–max range.
  return `${formatter(unitPrices[0], currency, true)}–${formatter(
    unitPrices[unitPrices.length - 1],
    currency,
    true
  )}`;
};

export const getFrequencyDescription = (
  count: number,
  frequency: Frequency
): string => {
  return count === 1
    ? i18next.t('common:everyPeriod', {
        period: i18next.t(`common:frequencyPeriods.singular.${frequency}`),
      })
    : i18next.t('common:everyCountPeriod', {
        count,
        period: i18next.t(`common:frequencyPeriods.plural.${frequency}`),
      });
};

export const getIntervalAndOffsetDescription = (
  interval: number,
  offset: number
) => {
  if (offset === 0) {
    return interval === 1
      ? i18next.t('features:billing.everyBill')
      : i18next.t('features:billing.everyIntervalBills', { interval });
  }

  const start = addOrdinalSuffix(offset + 1); // Offset starts at 0
  return interval === 1
    ? i18next.t('features:billing.everyBillStarting', { start })
    : i18next.t('features:billing.everyIntervalBillsStarting', {
        interval,
        start,
      });
};

export const getBillInAdvanceDescription = (inAdvance?: boolean) =>
  inAdvance ? i18next.t('common:inAdvance') : i18next.t('common:inArrears');

export const getReference = (bill: Bill) =>
  bill.sequentialInvoiceNumber ?? `INV-${bill.id.slice(-4)}`;
