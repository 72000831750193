import { useMemo } from 'react';

import { BalanceTransactionSchedule } from '@m3ter-com/m3ter-api';

import { CrudRetrieve } from '@/components/common/crud/CrudRetrieve/CrudRetrieve';
import { useCrudRetrieveContext } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';

const relationships = ['transactionType'];

export const BalanceTransactionScheduleRetrieveRoute: React.FC = () => {
  const { balance } = useCrudRetrieveContext();
  const pathParams = useMemo(() => ({ balanceId: balance?.id }), [balance?.id]);

  return (
    <CrudRetrieve<BalanceTransactionSchedule>
      pathParams={pathParams}
      titleAccessor="name"
      relationships={relationships}
    />
  );
};
