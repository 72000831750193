import React, { useMemo } from 'react';

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Heading,
  Link,
} from '@chakra-ui/react';

import {
  BalanceCharge,
  ChargeEntityType,
  DataType,
  Id,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { CardActionsHeader } from '@m3ter-com/ui-components';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';

import { OtherListIds } from '@/types/lists';

import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import useCrudRouteNames from '@/hooks/navigation/useCrudRouteNames';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { getReference } from '@/util/billing';

const relationships = ['bill'];

interface BalanceChargesTableProps {
  accountId?: Id;
  balanceId?: Id;
  scheduleId?: Id;
  header?: string;
  showCreateButton?: boolean;
  listId: OtherListIds.BalanceCharges | OtherListIds.BalanceScheduleCharges;
}

export const BalanceChargesTable: React.FC<BalanceChargesTableProps> = ({
  header,
  accountId,
  balanceId,
  scheduleId,
  listId,
  showCreateButton = false,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDate, toLongDateTime } = useDateFormatter();
  const { editRouteName } = useCrudRouteNames(DataType.BalanceCharge);
  const { deleteItem } = useEntityDelete(DataType.BalanceCharge);
  const { plural, singularLower } = useEntityNamings(DataType.BalanceCharge);

  // If scheduleId is provided, we are showing charges for a schedule,
  // otherwise, we are showing charges for a balance.
  const queryParams = useMemo(
    () => ({
      ...(scheduleId
        ? { scheduleId }
        : {
            accountId,
            entityId: balanceId,
            entityType: ChargeEntityType.Balance,
          }),
    }),
    [accountId, balanceId, scheduleId]
  );

  const columnDefinitions = useMemo<
    Array<ColumnDefinition<EntityWithRelationships<BalanceCharge>>>
  >(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (item) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.BalanceCharge}
            id={item.id}
          >
            {item.name}
          </Link>
        ),
      },
      {
        id: 'servicePeriodStart',
        header: t('forms:labels.servicePeriodStart'),
        accessor: (item) => toLongDateTime(item.servicePeriodStartDate),
      },
      {
        id: 'servicePeriodEnd',
        header: t('forms:labels.servicePeriodEnd'),
        accessor: (item) => toLongDateTime(item.servicePeriodEndDate),
      },
      {
        id: 'billDate',
        header: t('features:billing.billDate'),
        accessor: (item) => item.billDate && toLongDate(item.billDate),
      },
      {
        id: 'bill',
        header: t('common:bill'),
        accessor: (item) =>
          item.billId &&
          item.bill && (
            <Link
              as={CrudDetailsLink}
              dataType={DataType.Bill}
              id={item.billId}
            >
              {getReference(item.bill)}
            </Link>
          ),
      },
      {
        id: 'amount',
        header: t('common:amount'),
        accessor: (item) => formatCurrency(item.amount, item.currency),
        align: 'right',
      },
      {
        id: 'actions',
        header: '',
        accessor: (item) => (
          <EntityCrudActions<BalanceCharge>
            addReturnPath
            dataType={DataType.BalanceCharge}
            item={item}
            editRouteName={editRouteName}
            onDelete={deleteItem}
          />
        ),
      },
    ],
    [deleteItem, editRouteName, formatCurrency, t, toLongDate, toLongDateTime]
  );

  return (
    <Card>
      <CardActionsHeader
        width="100%"
        py={!showCreateButton ? 4 : 3}
        actions={
          showCreateButton && (
            <ButtonGroup>
              <Button
                size="sm"
                as={CrudCreateLink}
                addReturnPath
                dataType={DataType.BalanceCharge}
              >
                {t('forms:buttons.createEntity', {
                  entityName: singularLower,
                })}
              </Button>
              <Button
                size="sm"
                as={CrudCreateLink}
                addReturnPath
                dataType={DataType.BalanceChargeSchedule}
              >
                {t('forms:buttons.createEntity', {
                  entityName: t(
                    'features:balanceSchedules.chargeSchedule'
                  ).toLowerCase(),
                })}
              </Button>
            </ButtonGroup>
          )
        }
      >
        <Heading size="md">{header ?? plural}</Heading>
      </CardActionsHeader>
      <CardBody>
        <CrudList<BalanceCharge>
          dataType={DataType.BalanceCharge}
          listId={listId}
          params={queryParams}
          relationships={relationships}
        >
          <CrudListHeader hideCreateLink />
          <CrudListTable columns={columnDefinitions} />
          <CrudListFooter />
        </CrudList>
      </CardBody>
    </Card>
  );
};
