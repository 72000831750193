import { BillingEntity, DataType, DateTimeISOString, Id } from '../types';

import { getOrganizationPath } from '../util/path';
import { get, put } from '../client';

export interface EndDateBillingEntitiesRequestBody {
  endDate: DateTimeISOString;
  billingEntities: Array<BillingEntity>;
  applyToChildren?: boolean;
}

export interface EndDateBillingEntitiesResponse {
  updatedEntities?: Record<BillingEntity, Array<Id>>;
  failedEntities?: Record<BillingEntity, Array<Id>>;
}

export interface BalanceTransactionsSummaryResponse {
  totalCreditAmount: number;
  totalDebitAmount: number;
  initialCreditAmount?: number;
}

export type EndBillingEntitiesDataType = DataType.Account | DataType.Contract;

export const endDateAccountBillingEntities = (
  organizationId: Id,
  accountId: Id,
  body: EndDateBillingEntitiesRequestBody
) =>
  put({
    path: getOrganizationPath('/accounts/:accountId/enddatebillingentities'),
    pathParams: { organizationId, accountId },
    body,
  }) as Promise<EndDateBillingEntitiesResponse>;

export const endDateContractBillingEntities = (
  organizationId: Id,
  contractId: Id,
  body: EndDateBillingEntitiesRequestBody
) =>
  put({
    path: getOrganizationPath('/contracts/:contractId/enddatebillingentities'),
    pathParams: { organizationId, contractId },
    body,
  }) as Promise<EndDateBillingEntitiesResponse>;

export const endDataTypeBillingEntities = (
  dataType: EndBillingEntitiesDataType,
  organizationId: Id,
  entityId: Id,
  body: EndDateBillingEntitiesRequestBody
) => {
  switch (dataType) {
    case DataType.Account:
      return endDateAccountBillingEntities(organizationId, entityId, body);
    case DataType.Contract:
      return endDateContractBillingEntities(organizationId, entityId, body);
    default:
      throw new Error(
        `Unsupported data type (${dataType}) for ending billing entities`
      );
  }
};

export const getBalanceTransactionsSummary = (
  organizationId: Id,
  balanceId: Id
) =>
  get({
    path: getOrganizationPath('/balances/:balanceId/transactions/summary'),
    pathParams: { organizationId, balanceId },
  }) as Promise<BalanceTransactionsSummaryResponse>;
