import React from 'react';

import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogCloseButton,
  AlertDialogBody,
  ButtonGroup,
} from '@chakra-ui/react';

import { Button, ButtonProps } from '../Button/Button';

export interface ConfirmationDialogProps {
  isOpen: boolean;
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  confirmButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
  onConfirm: () => void;
  onClose: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  isOpen,
  title = 'Are you sure?',
  message = 'Are you sure you want to do that?',
  confirmText = 'Yes',
  cancelText = 'No',
  confirmButtonProps,
  cancelButtonProps,
  onClose,
  onConfirm,
}) => {
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>{title}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>{message}</AlertDialogBody>
        <AlertDialogFooter>
          <ButtonGroup>
            <Button
              ref={cancelRef}
              onClick={onClose}
              data-testid="cancel"
              {...cancelButtonProps}
            >
              {cancelText}
            </Button>
            <Button
              colorScheme="red"
              onClick={onConfirm}
              data-testid="confirm"
              {...confirmButtonProps}
            >
              {confirmText}
            </Button>
          </ButtonGroup>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
