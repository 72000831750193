import React from 'react';

import { Charge, DataType } from '@m3ter-com/m3ter-api';

import { CrudDetails } from '@/components/common/crud/CrudDetails/CrudDetails';
import { ChargeDetails } from '@/components/features/accounts/ChargeDetails/ChargeDetails';

const ChargeDetailsRouteComponent: React.FC<{
  data: Charge;
}> = ({ data }) => <ChargeDetails data={data} dataType={DataType.Charge} />;

export const ChargesDetailsRoute: React.FC = () => (
  <CrudDetails<Charge> component={ChargeDetailsRouteComponent} />
);
