import { useMemo } from 'react';

import { Link } from '@chakra-ui/react';

import { DataType, Product } from '@m3ter-com/m3ter-api';
import { Button } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { PageWithBreadcrumbs } from '@/components/common/breadcrumbs/PageWithBreadcrumbs/PageWithBreadcrumbs';
import {
  EntityList,
  ColumnDefinition,
} from '@/components/common/data/EntityList/EntityList';
import { EntityActions } from '@/components/common/data/EntityActions/EntityActions';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';

const searchFields = ['name', 'code'];

export const ProductsListRoute: React.FC = () => {
  const { t } = useTranslation();

  const columnDefinitions = useMemo<Array<ColumnDefinition<Product>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (product) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.Product}
            id={product.id}
          >
            {product.name}
          </Link>
        ),
      },
      { id: 'code', header: t('forms:labels.code'), accessor: 'code' },
      {
        id: 'actions',
        header: t('common:actions'),
        accessor: (product) => (
          <EntityActions
            dataType={DataType.Product}
            entity={product}
            nameAccessor="name"
          />
        ),
        shrink: true,
        isPinnedLast: true,
      },
    ],
    [t]
  );

  return (
    <PageWithBreadcrumbs
      title={t('common:products')}
      actions={
        <Button
          intent="primary"
          as={CrudCreateLink}
          dataType={DataType.Product}
        >
          {t('forms:buttons.createEntity', {
            entityName: t('common:product'),
          })}
        </Button>
      }
      display="flex"
      flexDirection="column"
    >
      <EntityList
        dataType={DataType.Product}
        columnDefinitions={columnDefinitions}
        searchFields={searchFields}
        styleProps={{ flex: '1 1 0', minHeight: 0 }}
      />
    </PageWithBreadcrumbs>
  );
};
