import { ReactNode, useCallback, useMemo, useState } from 'react';

import { Flex, Link } from '@chakra-ui/react';

import {
  DataType,
  ExternalMapping,
  Id,
  QueryParams,
} from '@m3ter-com/m3ter-api';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useEntityDeleteMutation from '@/hooks/data/useEntityDeleteMutation';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListRefreshButton,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { EntitiesBulkDeleteAction } from '@/components/common/data/EntitiesBulkDeleteAction/EntitiesBulkDeleteAction';
import { ExternalMappingM3terIdLink } from '@/components/features/integrations/ExternalMappingM3terIdLink/ExternalMappingM3terIdLink';

export interface ExternalMappingsListProps {
  actions?: ReactNode;
  listId: string;
  includeIntegrationColumn?: boolean;
  queryParams?: QueryParams;
}

const searchFields = ['m3terEntity', 'm3terId', 'externalTable', 'externalId'];

export const ExternalMappingsList: React.FC<ExternalMappingsListProps> = ({
  actions,
  listId,
  includeIntegrationColumn = false,
  queryParams,
}) => {
  const { t } = useTranslation();

  const [selectedExternalMappingIds, setSelectedExternalMappingIds] = useState<
    Array<Id>
  >([]);
  const clearSelectedExternalMappingIds = useCallback(() => {
    setSelectedExternalMappingIds([]);
  }, []);

  const columns = useMemo<
    Array<ColumnDefinition<EntityWithRelationships<ExternalMapping>>>
  >(
    () => [
      {
        id: 'entity-type',
        header: t('forms:labels.entityType'),
        accessor: (externalMapping) =>
          t(
            `features:externalMappings.entityTypes.${externalMapping.m3terEntity}`
          ),
      },
      {
        id: 'external-table',
        header: t('forms:labels.externalTable'),
        accessor: 'externalTable',
      },
      ...(includeIntegrationColumn
        ? [
            {
              id: 'linked-integration',
              header: t('features:externalMappings.linkedIntegration'),
              accessor: (
                externalMapping: EntityWithRelationships<ExternalMapping>
              ) =>
                externalMapping.integrationConfig ? (
                  <Link
                    as={CrudDetailsLink}
                    dataType={DataType.Integration}
                    id={externalMapping.integrationConfig.id}
                  >
                    {externalMapping.integrationConfig.name}
                  </Link>
                ) : (
                  '-'
                ),
            },
          ]
        : []),
      {
        id: 'm3ter-id',
        header: 'm3ter ID',
        accessor: (externalMapping) => (
          <ExternalMappingM3terIdLink externalMapping={externalMapping} />
        ),
      },
      {
        id: 'external-id',
        header: t('forms:labels.externalEntityId'),
        accessor: 'externalId',
      },
    ],
    [includeIntegrationColumn, t]
  );

  const relationships = useMemo(
    () => (includeIntegrationColumn ? ['integrationConfig'] : []),
    [includeIntegrationColumn]
  );

  const { deleteEntity: deleteExternalMapping } = useEntityDeleteMutation(
    DataType.ExternalMapping
  );
  const onDeleteExternalMapping = useCallback(
    (externalMapping: ExternalMapping) => {
      deleteExternalMapping({
        entity: externalMapping,
      });
    },
    [deleteExternalMapping]
  );

  return (
    <CrudList<ExternalMapping>
      dataType={DataType.ExternalMapping}
      editRouteName={getCrudRouteName(
        DataType.ExternalMapping,
        CrudRouteType.Edit
      )}
      listId={listId}
      onDelete={onDeleteExternalMapping}
      params={queryParams}
      relationships={relationships}
      searchFields={searchFields}
    >
      <CrudListHeader hideCreateLink hideRefresh>
        <Flex
          alignItems="center"
          flexFlow="row nowrap"
          gap={4}
          justifyContent="flex-start"
          marginLeft="auto"
        >
          <CrudListRefreshButton />
          {actions}
        </Flex>
      </CrudListHeader>
      <CrudListTable
        columns={columns}
        onSelectedItemsChange={setSelectedExternalMappingIds}
        selectedItems={selectedExternalMappingIds}
        selectionType="multi"
      />
      <CrudListFooter>
        <EntitiesBulkDeleteAction
          dataType={DataType.ExternalMapping}
          selectedItems={selectedExternalMappingIds}
          clearSelectedItems={clearSelectedExternalMappingIds}
        />
      </CrudListFooter>
    </CrudList>
  );
};
