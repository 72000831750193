import React, { useMemo } from 'react';

import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Account, Balance, DataType } from '@m3ter-com/m3ter-api';

import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import { BalanceForm } from '@/components/features/accounts/BalanceForm/BalanceForm';
import { RequiresCurrenciesWrapper } from '@/components/features/organization/RequiresCurrenciesWrapper/RequiresCurrenciesWrapper';
import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';

export const BalancesCreateRoute: React.FC = () => {
  const { t } = useTranslation();
  const account = useCrudRetrieveData<Account>(DataType.Account);

  const initialValues = useMemo<Partial<Balance>>(
    () => ({ accountId: account.id }),
    [account]
  );

  return (
    <RequiresCurrenciesWrapper
      message={t('features:balances.noCurrenciesMessage')}
    >
      <CrudCreate<Balance> form={BalanceForm} initialValues={initialValues} />
    </RequiresCurrenciesWrapper>
  );
};
