import React, { useCallback } from 'react';

import { Badge, Link, SimpleGrid } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import { DataType, Plan, PlanTemplate } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { KeyValue } from '@m3ter-com/ui-components';

import type { TFunction } from 'i18next';

import {
  getBillInAdvanceDescription,
  getFrequencyDescription,
  getIntervalAndOffsetDescription,
} from '@/util/billing';
import useCurrencies from '@/hooks/util/useCurrencies';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { KeyValueReferenceLink } from '@/components/common/data/KeyValueReferenceLink/KeyValueReferenceLink';

import { PlanOrTemplateValue } from './PlanOrTemplateValue';
import { PlanOrTemplateOrOrgConfigValue } from './PlanOrTemplateOrOrgConfigValue';

interface PlanDetailsContentProps {
  plan: Plan;
  planTemplate: PlanTemplate;
  showIsCustomDetails?: boolean;
}

export const PlanDetailsContent: React.FC<PlanDetailsContentProps> = ({
  plan,
  planTemplate,
  showIsCustomDetails = false,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();

  const isCustomPlan = !!plan.accountId;

  const formatCurrencyValue = useCallback(
    (value?: number) => formatCurrency(value ?? 0, planTemplate.currency),
    [planTemplate, formatCurrency]
  );

  return (
    <SimpleGrid columns={4} gap={6} w="100%">
      <KeyValue label={t('forms:labels.name')} value={plan.name} />
      <KeyValue label={t('forms:labels.code')} value={plan.code} />
      {showIsCustomDetails && isCustomPlan && (
        <KeyValue
          label={t('features:plans.planType')}
          value={
            <span>
              <Badge maxH="fit-content" colorScheme="green">
                {t('common:custom')}
              </Badge>{' '}
              <Trans
                t={t as TFunction}
                i18nKey="features:account.customAccountLink"
              >
                for
                <ReferenceLink
                  dataType={DataType.Account}
                  id={plan.accountId!}
                  accessor="name"
                />
              </Trans>
            </span>
          }
        />
      )}
      <KeyValue
        label={t('common:planTemplate')}
        value={
          <Link
            as={CrudDetailsLink}
            dataType={DataType.PlanTemplate}
            id={planTemplate.id}
          >
            {planTemplate.name}
          </Link>
        }
      />
      {plan.productId && (
        <KeyValue
          label={t('common:product')}
          value={
            <ReferenceLink
              dataType={DataType.Product}
              id={plan.productId}
              accessor="name"
            />
          }
        />
      )}
      <KeyValue
        label={t('forms:labels.currency')}
        value={planTemplate.currency}
      />
      <KeyValue
        label={t('forms:labels.billFrequency')}
        value={getFrequencyDescription(
          planTemplate.billFrequencyInterval,
          planTemplate.billFrequency
        )}
      />
      <KeyValue
        label={t('forms:labels.standingCharge')}
        value={
          <PlanOrTemplateValue
            plan={plan}
            planTemplate={planTemplate}
            fieldName="standingCharge"
            format={formatCurrencyValue}
            defaultValue={0}
          />
        }
      />
      <KeyValue
        label={t('forms:labels.standingChargeBilling')}
        value={getIntervalAndOffsetDescription(
          planTemplate.standingChargeInterval,
          planTemplate.standingChargeOffset
        )}
      />
      <KeyValue
        label={t('forms:labels.standingChargeBilling')}
        value={
          <PlanOrTemplateOrOrgConfigValue
            fieldName="standingChargeBillInAdvance"
            format={getBillInAdvanceDescription}
            plan={plan}
            planTemplate={planTemplate}
          />
        }
      />
      <KeyValueReferenceLink
        label={t('forms:labels.standingChargeAccountingProduct')}
        dataType={DataType.Product}
        accessor="name"
        id={plan.standingChargeAccountingProductId}
        defaultValue="-"
      />
      <KeyValue
        label={t('forms:labels.standingChargeDescription')}
        value={plan.standingChargeDescription || '-'}
      />
      <KeyValue
        label={t('forms:labels.minimumSpend')}
        value={
          <PlanOrTemplateValue
            plan={plan}
            planTemplate={planTemplate}
            fieldName="minimumSpend"
            format={formatCurrencyValue}
            defaultValue={0}
          />
        }
      />
      <KeyValue
        label={t('forms:labels.minimumSpendBilling')}
        value={
          <PlanOrTemplateOrOrgConfigValue
            fieldName="minimumSpendBillInAdvance"
            format={getBillInAdvanceDescription}
            plan={plan}
            planTemplate={planTemplate}
          />
        }
      />
      <KeyValueReferenceLink
        label={t('forms:labels.minimumSpendAccountingProduct')}
        dataType={DataType.Product}
        accessor="name"
        id={plan.minimumSpendAccountingProductId}
        defaultValue="-"
      />
      <KeyValue
        label={t('forms:labels.minimumSpendDescription')}
        value={plan.minimumSpendDescription || '-'}
      />
    </SimpleGrid>
  );
};
