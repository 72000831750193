import React, { useCallback, useEffect, useMemo } from 'react';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  InputLeftAddon,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import upperFirst from 'lodash/upperFirst';

import {
  SourceType,
  ExportSchedule,
  DataType,
  ScheduleType,
  DataExportTimePeriodType,
  AggregationFrequency,
  ExportFileFormat,
  ExportAdhoc,
  AnalyticsJobTimePeriodType,
} from '@m3ter-com/m3ter-api';
import { useFormError, useTranslation } from '@m3ter-com/console-core/hooks';
import {
  FormStack,
  RadioTileOption,
  RadioTiles,
  SelectOption,
  Input,
} from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormRadioGroup,
  FormSection,
  FormSelect,
  NameCodeFields,
  RadioGroupOption,
} from '@m3ter-com/console-core/components';
import { getDateValuesForTimePeriod } from '@m3ter-com/console-core/utils';

import { BaseFormProps } from '@/types/forms';

import exportScheduleSchema from '@/validation/exportSchedule';
import adHocExportScheduleSchema from '@/validation/adHocExportSchedule';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { UsageQueryBuilder } from '@/components/features/usage/query-builder/UsageQueryBuilder/UsageQueryBuilder';
import {
  DataTypeFormEntityMultiSelect,
  FormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect/FormEntityMultiSelect';
import { FormUsageQueryBuilderAccounts } from '@/components/forms/FormUsageQueryBuilderAccounts/FormUsageQueryBuilderAccounts';
import { FormUsageQueryBuilderAggregation } from '@/components/forms/FormUsageQueryBuilderAggregation/FormUsageQueryBuilderAggregation';
import { FormUsageQueryBuilderAggregationFrequency } from '@/components/forms/FormUsageQueryBuilderAggregationFrequency/FormUsageQueryBuilderAggregationFrequency';
import { FormUsageQueryBuilderMeters } from '@/components/forms/FormUsageQueryBuilderMeters/FormUsageQueryBuilderMeters';
import { FormUsageQueryBuilderRelativeTimePeriod } from '@/components/forms/FormUsageQueryBuilderRelativeTimePeriod/FormUsageQueryBuilderRelativeTimePeriod';
import { FormUsageQueryBuilderDataTypes } from '@/components/forms/FormUsageQueryBuilderDataTypes/FormUsageQueryBuilderDataTypes';
import { FormUsageQueryBuilderAbsoluteTimePeriod } from '@/components/forms/FormUsageQueryBuilderRelativeTimePeriod/FormUsageQueryBuilderAbsoluteTimePeriod';
import {
  DateRange,
  TimePeriodType,
} from '@/components/features/usage/query-builder/UsageQueryBuilderAbsoluteTimePeriod/UsageQueryBuilderAbsoluteTimePeriod';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import { getDestinationType } from '@/util/dataExport';

const isExportSchedule = (
  exportData: ExportSchedule | ExportAdhocFormValues
): exportData is ExportSchedule => Object.hasOwn(exportData, 'scheduleType');

export interface ExportAdhocFormValues extends ExportAdhoc {
  dateRange: DateRange;
}

export interface ExportScheduleCreateFormProps
  extends BaseFormProps<ExportSchedule | ExportAdhoc> {
  isAdhoc?: boolean;
}

export interface ExportScheduleTypeFieldsProps {
  isAdhoc: boolean;
}

const defaultInitialValues: Partial<ExportSchedule> = {};

const ExportScheduleTypeFields: React.FC<ExportScheduleTypeFieldsProps> = ({
  isAdhoc,
}) => {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { timeZone } = useDateFormatter();

  const { isInvalid: isInvalidDataTypes, message: invalidDataTypesMessage } =
    useFormError('operationalDataTypes');
  const { isInvalid: isInvalidDateRange, message: invalidDateRangeMessage } =
    useFormError('dateRange');
  const {
    isInvalid: isInvalidAggregation,
    message: invalidAggregationMessage,
  } = useFormError('aggregation');

  const sourceType: SourceType | undefined = useWatch({
    name: 'sourceType',
  });
  const aggregationFrequency: AggregationFrequency | undefined = useWatch({
    name: 'aggregationFrequency',
  });
  const dateRange: DateRange | undefined = useWatch({ name: 'dateRange' });

  const isOriginalFrequency =
    aggregationFrequency === AggregationFrequency.Original;

  useEffect(() => {
    if (isOriginalFrequency) {
      setValue('aggregation', null, { shouldValidate: true });
    }
  }, [isOriginalFrequency, setValue]);

  const onTypeChange = useCallback(
    (newType: SourceType) => {
      if (newType === SourceType.Usage) {
        setValue('operationalDataTypes', []);
        setValue('timePeriod', DataExportTimePeriodType.Today);
        setValue('aggregationFrequency', AggregationFrequency.Original);
        const { startDate, endDate } = getDateValuesForTimePeriod(
          DataExportTimePeriodType.Today,
          timeZone
        );
        setValue('dateRange', {
          startDate,
          endDate,
          type: DataExportTimePeriodType.Today,
        });
      } else {
        setValue('aggregationFrequency', null);
        setValue('timePeriod', null);
        setValue('aggregation', null);
        setValue('meterIds', []);
        setValue('accountIds', []);

        const { startDate, endDate } = getDateValuesForTimePeriod(
          DataExportTimePeriodType.AllTime,
          timeZone
        );
        setValue('dateRange', {
          startDate,
          endDate,
          type: DataExportTimePeriodType.AllTime,
        });
      }

      const allErrorKeys = Object.keys(errors);
      const errorsToClear = allErrorKeys.filter(
        (key) => key !== 'destinationIds' && key !== 'name' && key !== 'code'
      );
      clearErrors(errorsToClear);
    },
    [clearErrors, errors, setValue, timeZone]
  );

  const exportTypeOptions = useMemo<Array<RadioTileOption<SourceType>>>(
    () => [
      {
        value: SourceType.Usage,
        label: t(`features:dataExports.sourceType.${SourceType.Usage}`),
        content: (
          <Text>
            {t(
              `features:dataExports.sourceTypeDescription.${SourceType.Usage}`
            )}
          </Text>
        ),
      },
      {
        value: SourceType.Operational,
        label: t(`features:dataExports.sourceType.${SourceType.Operational}`),
        content: (
          <Text>
            {t(
              `features:dataExports.sourceTypeDescription.${SourceType.Operational}`
            )}
          </Text>
        ),
      },
    ],
    [t]
  );

  const schduledUsageTimePeriods = useMemo(
    () =>
      Object.values(DataExportTimePeriodType).filter(
        (type) => type !== DataExportTimePeriodType.AllTime
      ),
    []
  );
  const adhocUsageTimePeriods = useMemo(
    () => [
      AnalyticsJobTimePeriodType.Custom,
      ...Object.values(DataExportTimePeriodType).filter(
        (type) => type !== DataExportTimePeriodType.AllTime
      ),
    ],
    []
  );
  const adhocOperationalTimePeriods = useMemo(
    () => [
      AnalyticsJobTimePeriodType.Custom,
      ...Object.values(AnalyticsJobTimePeriodType).filter(
        (type) => type !== AnalyticsJobTimePeriodType.Custom
      ),
      DataExportTimePeriodType.AllTime,
    ],
    []
  );

  return (
    <Box maxW="60em">
      <FormControl isRequired mb={4}>
        <FormLabel>{t('forms:labels.sourceType')}</FormLabel>
        <Controller
          name="sourceType"
          control={control}
          render={({ field: { value, onChange } }) => (
            <RadioTiles
              columns={2}
              value={value}
              options={exportTypeOptions}
              onChange={(type) => {
                onTypeChange(type);
                onChange(type);
              }}
            />
          )}
        />
      </FormControl>
      <Box>
        {sourceType === SourceType.Usage && (
          <UsageQueryBuilder>
            <SimpleGrid columns={6} gap={2} width="100%">
              <FormControl
                isRequired
                gridColumn={!isAdhoc ? 'span 2' : 'span 6'}
              >
                {isAdhoc ? (
                  <FormControl isInvalid={!!isInvalidDateRange}>
                    <FormUsageQueryBuilderAbsoluteTimePeriod
                      name="dateRange"
                      periods={adhocUsageTimePeriods}
                      showHelpText={(period: TimePeriodType) =>
                        period === AnalyticsJobTimePeriodType.Custom
                      }
                      helpText={t('forms:helpText.usageAdhocExportTimePeriod')}
                    />
                    {isInvalidDateRange && (
                      <FormErrorMessage>
                        {invalidDateRangeMessage}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                ) : (
                  <FormUsageQueryBuilderRelativeTimePeriod
                    name="timePeriod"
                    periods={schduledUsageTimePeriods}
                  />
                )}
              </FormControl>
              <FormControl
                isRequired
                gridColumn={!isAdhoc ? 'span 2' : 'span 3'}
              >
                <FormUsageQueryBuilderAggregationFrequency name="aggregationFrequency" />
              </FormControl>
              <FormControl
                gridColumn={!isAdhoc ? 'span 2' : 'span 3'}
                isRequired={!isOriginalFrequency}
                isInvalid={!!isInvalidAggregation}
              >
                <FormUsageQueryBuilderAggregation
                  name="aggregation"
                  isDisabled={isOriginalFrequency}
                />
                {isInvalidAggregation && (
                  <FormErrorMessage>
                    {invalidAggregationMessage}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl gridColumn="span 3">
                <FormUsageQueryBuilderMeters name="meterIds" />
              </FormControl>
              <FormControl gridColumn="span 3">
                <FormUsageQueryBuilderAccounts name="accountIds" />
              </FormControl>
            </SimpleGrid>
          </UsageQueryBuilder>
        )}
        {sourceType === SourceType.Operational && (
          <UsageQueryBuilder
            startDate={dateRange?.startDate ?? undefined}
            endDate={dateRange?.endDate ?? undefined}
          >
            <Stack gap={2}>
              <FormControl isInvalid={!!isInvalidDateRange}>
                {isAdhoc && (
                  <FormUsageQueryBuilderAbsoluteTimePeriod
                    name="dateRange"
                    showHelpText
                    isDatesRequired={false}
                    isTodayFullyIncluded={false}
                    periods={adhocOperationalTimePeriods}
                    helpText={t('forms:helpText.usageAdhocExportOpsTimePeriod')}
                  />
                )}
                {isInvalidDateRange && (
                  <FormErrorMessage>{invalidDateRangeMessage}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={!!isInvalidDataTypes}>
                <FormUsageQueryBuilderDataTypes name="operationalDataTypes" />
                {isInvalidDataTypes && (
                  <FormErrorMessage>{invalidDataTypesMessage}</FormErrorMessage>
                )}
              </FormControl>
            </Stack>
          </UsageQueryBuilder>
        )}
      </Box>
    </Box>
  );
};

const ExportScheduleFrequencyFields: React.FC = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { isInvalid, message } = useFormError('period');
  const period: number = useWatch({ name: 'period' }) ?? 0;

  const scheduleTypeOptions = useMemo<Array<SelectOption<ScheduleType>>>(
    () =>
      Object.values(ScheduleType)
        .filter((type) => type !== ScheduleType.AdHoc)
        .map((type) => ({
          value: type,
          label: upperFirst(
            t(
              `features:dataExports.scheduleTypePeriods.${
                period > 1 ? 'plural' : 'singular'
              }.${type}`
            )
          ),
        })),
    [period, t]
  );

  return (
    <VStack width="100%" spacing={2} alignItems="flex-start">
      <SimpleGrid columns={2} columnGap={4} alignItems="flex-end" width="100%">
        <FormControl isRequired isInvalid={!!isInvalid}>
          <FormLabel>{t('forms:labels.frequency')}</FormLabel>
          <Controller
            name="period"
            control={control}
            render={({ field: { value, onChange } }) => (
              <InputGroup>
                <InputLeftAddon>{t('common:every')}</InputLeftAddon>
                <Input
                  width="100%"
                  type="number"
                  value={value}
                  onChange={onChange}
                />
              </InputGroup>
            )}
          />
        </FormControl>
        <FormControl isRequired>
          <FormSelect name="scheduleType" options={scheduleTypeOptions} />
        </FormControl>
      </SimpleGrid>
      <FormControl isInvalid={!!isInvalid}>
        {!!message && <FormErrorMessage mt={0}>{message}</FormErrorMessage>}
      </FormControl>
    </VStack>
  );
};

export const ExportScheduleForm: React.FC<ExportScheduleCreateFormProps> = ({
  onSave,
  onCancel,
  isAdhoc = false,
  isEdit = false,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExportSchedule);

  const onSubmit = useCallback(
    (data: ExportSchedule | ExportAdhocFormValues) => {
      if (isExportSchedule(data)) {
        onSave(data);
      } else {
        const { dateRange, timePeriod: _, ...rest } = data;
        onSave({
          ...rest,
          startDate: dateRange?.startDate ?? undefined,
          endDate: dateRange?.endDate ?? undefined,
        });
      }
    },
    [onSave]
  );

  const exportFileFormatOptions = useMemo<Array<RadioGroupOption>>(
    () =>
      Object.values(ExportFileFormat).map((value) => ({
        value,
        label: t(`features:dataExports.exportFileFormat.${value}`),
      })),
    [t]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={
        !isAdhoc ? exportScheduleSchema : adHocExportScheduleSchema
      }
    >
      <FormSection
        mb={4}
        width="fit-content"
        heading={
          !isAdhoc
            ? t('common:entityDetails', { entityName: entityNamings.singular })
            : t('common:entityDetails', {
                entityName: t('features:dataExports.adhocExport'),
              })
        }
      >
        <Stack spacing={4} width="100%">
          <FormStack>
            {!isAdhoc && <NameCodeFields />}
            <FormField
              name="destinationIds"
              label={t('common:destinations')}
              control={
                FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.ExportDestination>
              }
              dataType={DataType.ExportDestination}
              accessor="name"
              detailAccessor={(destination) =>
                t(
                  `features:dataExports.destinationTypes.${getDestinationType(
                    destination
                  )}`
                )
              }
            />
            <FormField
              stacked
              name="exportFileFormat"
              label={t('forms:labels.exportFileFormat')}
              control={FormRadioGroup}
              options={exportFileFormatOptions}
              helpText={t('forms:helpText.exportFileFormat')}
            />
            {!isAdhoc && <ExportScheduleFrequencyFields />}
          </FormStack>
          <ExportScheduleTypeFields isAdhoc={isAdhoc} />
        </Stack>
      </FormSection>
      <FormActions
        cancelText={t('common:cancel')}
        submitText={
          // eslint-disable-next-line no-nested-ternary
          isAdhoc
            ? t('features:dataExports.runAdhocExport')
            : isEdit
            ? t('forms:buttons.updateEntity', {
                entityName: entityNamings.singularLower,
              })
            : t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })
        }
        isSaving={isSaving}
        onCancel={onCancel}
      />
    </Form>
  );
};
