import { DataTypeToEntity } from './datatype-entity-map';
import { ExternalMappingEntityType, DataType } from './enums';

// This is a little bit mental yes.
// The issue is we need to be able to map between ExternalMappingEntityType and DataType and we need
// to be able to do that in value-land (e.g. to call API methods) and in type-land (e.g. to get
// correct typings for those API calls).
// The usual answer here would be an Enum, but TypeScript doesn't allow you to use computed Enum
// values as a key in another Enum.
// So we have to have a type map for type-land and a value map for value-land.
// There's some additional complexity because:
// - We can't actually map every single ExternalMappingEntityType to a DataType and vice-versa
// - Despite this, we still want to be able to try to use any DataType or ExternalMappingEntityType
// // as a key when accessing the value maps otherwise we would have to do something like:
// // const dataType = externalMappingEntityTypeToDataType[entityType as keyof typeof externalMappingEntityTypeToDataType] as DataType | undefined;
// And also because:
// We want the type-land and version-land maps to be coupled. With this setup, if we add to, remove
// from or change from the type-land maps, the value-land maps will error.
// Thank you for reading :)

type DataTypeToExternalMappingEntityTypeEnum = {
  [DataType.Account]: ExternalMappingEntityType.Account;
  [DataType.AccountPlan]: ExternalMappingEntityType.AccountPlan;
  [DataType.Aggregation]: ExternalMappingEntityType.Aggregation;
  [DataType.Balance]: ExternalMappingEntityType.Balance;
  [DataType.BalanceTransaction]: ExternalMappingEntityType.BalanceTransaction;
  [DataType.Bill]: ExternalMappingEntityType.Bill;
  [DataType.BillLineItem]: ExternalMappingEntityType.BillLineItem;
  [DataType.Commitment]: ExternalMappingEntityType.Commitment;
  [DataType.CompoundAggregation]: ExternalMappingEntityType.CompoundAggregation;
  [DataType.Contract]: ExternalMappingEntityType.Contract;
  [DataType.Meter]: ExternalMappingEntityType.Meter;
  [DataType.Organization]: ExternalMappingEntityType.Organization;
  [DataType.OutboundWebhook]: ExternalMappingEntityType.Webhook;
  [DataType.Plan]: ExternalMappingEntityType.Plan;
  [DataType.Pricing]: ExternalMappingEntityType.Pricing;
  [DataType.Product]: ExternalMappingEntityType.Product;
};
export type DataTypeToExternalMappingEntityType<D extends DataType> =
  D extends keyof DataTypeToExternalMappingEntityTypeEnum
    ? DataTypeToExternalMappingEntityTypeEnum[D]
    : never;
export const dataTypeToExternalMappingEntityType: Partial<
  Record<DataType, ExternalMappingEntityType>
> &
  DataTypeToExternalMappingEntityTypeEnum = {
  [DataType.Account]: ExternalMappingEntityType.Account,
  [DataType.AccountPlan]: ExternalMappingEntityType.AccountPlan,
  [DataType.Aggregation]: ExternalMappingEntityType.Aggregation,
  [DataType.Balance]: ExternalMappingEntityType.Balance,
  [DataType.BalanceTransaction]: ExternalMappingEntityType.BalanceTransaction,
  [DataType.Bill]: ExternalMappingEntityType.Bill,
  [DataType.BillLineItem]: ExternalMappingEntityType.BillLineItem,
  [DataType.Commitment]: ExternalMappingEntityType.Commitment,
  [DataType.CompoundAggregation]: ExternalMappingEntityType.CompoundAggregation,
  [DataType.Contract]: ExternalMappingEntityType.Contract,
  [DataType.Meter]: ExternalMappingEntityType.Meter,
  [DataType.Organization]: ExternalMappingEntityType.Organization,
  [DataType.OutboundWebhook]: ExternalMappingEntityType.Webhook,
  [DataType.Plan]: ExternalMappingEntityType.Plan,
  [DataType.Pricing]: ExternalMappingEntityType.Pricing,
  [DataType.Product]: ExternalMappingEntityType.Product,
};

type ExternalMappingEntityTypeToDataTypeEnum = {
  [ExternalMappingEntityType.Account]: DataType.Account;
  [ExternalMappingEntityType.AccountPlan]: DataType.AccountPlan;
  [ExternalMappingEntityType.Aggregation]: DataType.Aggregation;
  [ExternalMappingEntityType.Balance]: DataType.Balance;
  [ExternalMappingEntityType.BalanceTransaction]: DataType.BalanceTransaction;
  [ExternalMappingEntityType.Bill]: DataType.Bill;
  [ExternalMappingEntityType.BillLineItem]: DataType.BillLineItem;
  [ExternalMappingEntityType.Commitment]: DataType.Commitment;
  [ExternalMappingEntityType.CompoundAggregation]: DataType.CompoundAggregation;
  [ExternalMappingEntityType.Contract]: DataType.Contract;
  [ExternalMappingEntityType.Meter]: DataType.Meter;
  [ExternalMappingEntityType.Organization]: DataType.Organization;
  [ExternalMappingEntityType.Plan]: DataType.Plan;
  [ExternalMappingEntityType.Pricing]: DataType.Pricing;
  [ExternalMappingEntityType.Product]: DataType.Product;
  [ExternalMappingEntityType.Webhook]: DataType.OutboundWebhook;
};
export type ExternalMappingEntityTypeToDataType<
  E extends ExternalMappingEntityType
> = E extends keyof ExternalMappingEntityTypeToDataTypeEnum
  ? ExternalMappingEntityTypeToDataTypeEnum[E]
  : never;
export const externalMappingEntityTypeToDataType: Partial<
  Record<ExternalMappingEntityType, DataType>
> &
  ExternalMappingEntityTypeToDataTypeEnum = {
  [ExternalMappingEntityType.Account]: DataType.Account,
  [ExternalMappingEntityType.AccountPlan]: DataType.AccountPlan,
  [ExternalMappingEntityType.Aggregation]: DataType.Aggregation,
  [ExternalMappingEntityType.Balance]: DataType.Balance,
  [ExternalMappingEntityType.BalanceTransaction]: DataType.BalanceTransaction,
  [ExternalMappingEntityType.Bill]: DataType.Bill,
  [ExternalMappingEntityType.BillLineItem]: DataType.BillLineItem,
  [ExternalMappingEntityType.Commitment]: DataType.Commitment,
  [ExternalMappingEntityType.CompoundAggregation]: DataType.CompoundAggregation,
  [ExternalMappingEntityType.Contract]: DataType.Contract,
  [ExternalMappingEntityType.Meter]: DataType.Meter,
  [ExternalMappingEntityType.Organization]: DataType.Organization,
  [ExternalMappingEntityType.Plan]: DataType.Plan,
  [ExternalMappingEntityType.Pricing]: DataType.Pricing,
  [ExternalMappingEntityType.Product]: DataType.Product,
  [ExternalMappingEntityType.Webhook]: DataType.OutboundWebhook,
};

export type ExternalMappingEntityTypeToEntity<
  E extends ExternalMappingEntityType
> = E extends keyof ExternalMappingEntityTypeToDataTypeEnum
  ? DataTypeToEntity[ExternalMappingEntityTypeToDataTypeEnum[E]]
  : never;
