import { useMemo } from 'react';

import { Spinner } from '@chakra-ui/react';

import { IntegrationCredential } from '@m3ter-com/m3ter-api';

import useQueryString from '@/hooks/navigation/useQueryString';
import { useIntegrationConfigSchemaContext } from '@/components/features/integrations/IntegrationConfigSchemaContext/IntegrationConfigSchemaContext';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { CrudCreate } from '@/components/common/crud/CrudCreate/CrudCreate';
import { IntegrationCredentialCreateForm } from '@/components/features/integrations/IntegrationCredentialCreateForm/IntegrationCredentialCreateForm';

interface IntegrationCredentialCreateRouteParams {
  destination?: string;
}

export const IntegrationCredentialCreateRoute: React.FC = () => {
  const { destination } =
    useQueryString<IntegrationCredentialCreateRouteParams>();
  const initialValues = useMemo(
    () => ({
      destination,
    }),
    [destination]
  );

  const { configSchemaError, isLoadingConfigSchema } =
    useIntegrationConfigSchemaContext();

  if (isLoadingConfigSchema) {
    return <Spinner />;
  }

  if (configSchemaError) {
    return <ErrorAlert error={configSchemaError} />;
  }

  return (
    <CrudCreate<IntegrationCredential>
      form={IntegrationCredentialCreateForm}
      initialValues={initialValues}
    />
  );
};
