import { BalanceTransactionSchedule, DataType } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { BalanceTransactionScheduleRoute } from '@/components/routes/accounts/balances/balance-schedules/BalanceTransactionScheduleRoute';
import { BalanceTransactionScheduleRetrieveRoute } from '@/components/routes/accounts/balances/balance-schedules/BalanceTransactionScheduleRetrieveRoute';

const LazyBalanceTransactionScheduleEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceTransactionScheduleEditRoute" */ '@/components/routes/accounts/balances/balance-schedules/BalanceTransactionScheduleEditRoute'
  ).then((module) => ({ default: module.BalanceTransactionScheduleEditRoute }))
);

const LazyBalanceTransactionScheduleDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceTransactionScheduleDetailsRoute" */ '@/components/routes/accounts/balances/balance-schedules/BalanceTransactionScheduleDetailsRoute'
  ).then((module) => ({
    default: module.BalanceTransactionScheduleDetailsRoute,
  }))
);

const LazyBalanceTransactionScheduleListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceTransactionScheduleListRoute" */ '@/components/routes/accounts/balances/balance-schedules/BalanceTransactionScheduleListRoute'
  ).then((module) => ({ default: module.BalanceTransactionScheduleListRoute }))
);

const LazyBalanceTransactionScheduleCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceTransactionScheduleCreateRoute" */ '@/components/routes/accounts/balances/balance-schedules/BalanceTransactionScheduleCreateRoute'
  ).then((module) => ({
    default: module.BalanceTransactionScheduleCreateRoute,
  }))
);

export default (): NamedRoute =>
  createCrudRoute<BalanceTransactionSchedule>({
    path: 'transaction-schedules',
    dataType: DataType.BalanceTransactionSchedule,
    element: <BalanceTransactionScheduleRoute />,
    retrieve: <BalanceTransactionScheduleRetrieveRoute />,
    details: <LazyBalanceTransactionScheduleDetailsRoute />,
    edit: <LazyBalanceTransactionScheduleEditRoute />,
    create: <LazyBalanceTransactionScheduleCreateRoute />,
    list: <LazyBalanceTransactionScheduleListRoute />,
  });
