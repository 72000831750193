import { BalanceChargeSchedule, DataType } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { BalanceChargeScheduleRoute } from '@/components/routes/accounts/balances/balance-schedules/BalanceChargeScheduleRoute';
import { BalanceChargeScheduleRetrieveRoute } from '@/components/routes/accounts/balances/balance-schedules/BalanceChargeScheduleRetrieveRoute';

const LazyBalanceChargeScheduleEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceChargeScheduleEditRoute" */ '@/components/routes/accounts/balances/balance-schedules/BalanceChargeScheduleEditRoute'
  ).then((module) => ({ default: module.BalanceChargeScheduleEditRoute }))
);

const LazyBalanceChargeScheduleDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceChargeScheduleDetailsRoute" */ '@/components/routes/accounts/balances/balance-schedules/BalanceChargeScheduleDetailsRoute'
  ).then((module) => ({ default: module.BalanceChargeScheduleDetailsRoute }))
);

const LazyBalanceChargeScheduleListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceChargeScheduleListRoute" */ '@/components/routes/accounts/balances/balance-schedules/BalanceChargeScheduleListRoute'
  ).then((module) => ({ default: module.BalanceChargeScheduleListRoute }))
);

const LazyBalanceChargeScheduleCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceChargeScheduleCreateRoute" */ '@/components/routes/accounts/balances/balance-schedules/BalanceChargeScheduleCreateRoute'
  ).then((module) => ({ default: module.BalanceChargeScheduleCreateRoute }))
);

export default (): NamedRoute =>
  createCrudRoute<BalanceChargeSchedule>({
    path: 'charge-schedules',
    dataType: DataType.BalanceChargeSchedule,
    element: <BalanceChargeScheduleRoute />,
    retrieve: <BalanceChargeScheduleRetrieveRoute />,
    details: <LazyBalanceChargeScheduleDetailsRoute />,
    create: <LazyBalanceChargeScheduleCreateRoute />,
    edit: <LazyBalanceChargeScheduleEditRoute />,
    list: <LazyBalanceChargeScheduleListRoute />,
  });
