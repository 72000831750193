import React from 'react';

import { BalanceTransaction, DataType } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { BalanceTransactionsRoute } from '@/components/routes/accounts/balances/balance-transactions/BalanceTransactionsRoute';

const LazyBalanceTransactionsCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceTransactionsCreateRoute" */ '@/components/routes/accounts/balances/balance-transactions/BalanceTransactionsCreateRoute'
  ).then((module) => ({ default: module.BalanceTransactionsCreateRoute }))
);

export default (): NamedRoute =>
  createCrudRoute<BalanceTransaction>({
    path: 'transactions',
    dataType: DataType.BalanceTransaction,
    element: <BalanceTransactionsRoute />,
    create: <LazyBalanceTransactionsCreateRoute />,
  });
