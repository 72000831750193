import React from 'react';

import { BalanceCharge, DataType } from '@m3ter-com/m3ter-api';

import { NamedRoute } from '@/types/routes';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { BalanceChargesRoute } from '@/components/routes/accounts/balances/balance-charges/BalanceChargesRoute';

const LazyBalanceChargesCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceChargesCreateRoute" */ '@/components/routes/accounts/balances/balance-charges/BalanceChargesCreateRoute'
  ).then((module) => ({ default: module.BalanceChargesCreateRoute }))
);

const LazyBalanceChargesEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceChargesEditRoute" */ '@/components/routes/accounts/balances/balance-charges/BalanceChargesEditRoute'
  ).then((module) => ({ default: module.BalanceChargesEditRoute }))
);

const LazyBalanceChargesDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "BalanceChargeDetailsRoute" */ '@/components/routes/accounts/balances/balance-charges/BalanceChargesDetailsRoute'
  ).then((module) => ({ default: module.BalanceChargesDetailsRoute }))
);

export default (): NamedRoute =>
  createCrudRoute<BalanceCharge>({
    path: 'charges',
    dataType: DataType.BalanceCharge,
    element: <BalanceChargesRoute />,
    create: <LazyBalanceChargesCreateRoute />,
    edit: <LazyBalanceChargesEditRoute />,
    details: <LazyBalanceChargesDetailsRoute />,
  });
