import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { EntityCreate } from '@/components/common/data/EntityCreate/EntityCreate';
import { ProductForm } from '@/components/features/products/ProductForm/ProductForm';
import { PageWithBreadcrumbs } from '@/components/common/breadcrumbs/PageWithBreadcrumbs/PageWithBreadcrumbs';

export const ProductsCreateRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageWithBreadcrumbs title={t('common:create')}>
      <BreadcrumbItem title={t('common:create')} />
      <EntityCreate
        dataType={DataType.Product}
        defaultRedirectTo={(product) => `../${product.id}`}
        form={ProductForm}
      />
    </PageWithBreadcrumbs>
  );
};
