import { useCallback, useEffect, useState } from 'react';

import {
  DefaultError,
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';

import { ListResponse } from '@m3ter-com/m3ter-api';

type UseCursorPaginatedQueryOptions<T> = Pick<
  UseInfiniteQueryOptions<
    ListResponse<T>,
    DefaultError,
    InfiniteData<ListResponse<T>>,
    ListResponse<T>,
    QueryKey,
    string | undefined
  >,
  | 'enabled'
  | 'refetchInterval'
  | 'queryFn'
  | 'queryKey'
  | 'select'
  | 'placeholderData'
>;

const useCursorPaginatedQuery = <T>(
  options: UseCursorPaginatedQueryOptions<T>
) => {
  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    isPending,
    refetch,
  } = useInfiniteQuery<
    ListResponse<T>,
    DefaultError,
    InfiniteData<ListResponse<T>>,
    QueryKey,
    string | undefined
  >({
    ...options,
    initialPageParam: undefined,
    getNextPageParam: (lastPage: ListResponse<T>) =>
      // The search API returns a next token of `0` when there are no more pages.
      lastPage.nextToken !== '0' ? lastPage.nextToken : undefined,
  });

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  // When the query key changes, we know that RQ will treat this as a new query, which
  // includes resetting the page state and this makes sense because the dataset you're
  // looking at will have changed.
  // isPending is only true when RQ is fetching the first page of a new query, so we can listen
  // for that and reset our page index then.
  useEffect(() => {
    if (isPending) {
      setCurrentPageIndex(0);
    }
  }, [isPending]);

  const goToNextPage = useCallback(async () => {
    await fetchNextPage();
    setCurrentPageIndex((pageIndex) => pageIndex + 1);
  }, [fetchNextPage]);

  const goToPage = useCallback((page: number) => {
    setCurrentPageIndex(page - 1);
  }, []);

  return {
    currentPage: currentPageIndex + 1,
    currentPageData: data?.pages[currentPageIndex]?.data,
    error,
    goToNextPage,
    goToPage,
    hasMore: hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    knownPageCount: data?.pages.length ?? 1,
    refetch,
  };
};

export default useCursorPaginatedQuery;
