import { useQuery } from '@tanstack/react-query';
import { HStack, Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from 'lucide-react';

import { Bill, DataType } from '@m3ter-com/m3ter-api';

import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { ExternalSystemLogo } from '@/components/common/brand/ExternalSystemLogo/ExternalSystemLogo';

export interface ExternalInvoiceLinkProps {
  bill: Bill;
}

export const ExternalInvoiceLink: React.FC<ExternalInvoiceLinkProps> = ({
  bill,
}) => {
  const latestRunPathParams = useOrgPathParams({
    entityId: bill.id,
    entityType: 'Bill',
  });
  const { data: integrationRun } = useQuery(
    dataTypeRetrieveQuery({
      dataType: DataType.IntegrationRun,
      actionName: 'getLatestEntityRun',
      pathParams: latestRunPathParams,
    })
  );

  if (!integrationRun?.url) {
    return '-';
  }

  return (
    <HStack
      alignItems="center"
      flexFlow="row nowrap"
      gap={1}
      justifyContent="flex-start"
    >
      <Link isExternal href={integrationRun.url}>
        {bill.externalInvoiceReference ?? integrationRun?.externalId}
      </Link>
      <ExternalSystemLogo height="20px" system={integrationRun.destination} />
      <ExternalLinkIcon size={16} />
    </HStack>
  );
};
