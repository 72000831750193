import React, { useMemo } from 'react';

import { SimpleGrid } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import {
  Form,
  FormActions,
  FormAdvancedNumberInput,
  FormCurrencyInput,
  FormField,
  FormInput,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Charge, CurrencyCode, DataType, Id } from '@m3ter-com/m3ter-api';
import { FormStack } from '@m3ter-com/ui-components';

import { BaseFormProps } from '@/types/forms';

import { FormDatePicker } from '@/components/forms/FormDatePicker/FormDatePicker';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect/FormEntitySelect';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect/FormPicklistSelect';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import chargeSchema from '@/validation/charge';

export interface ChargeFormProps extends BaseFormProps<Charge> {}

interface ChargeFormSettingFormFieldsProps {
  accountId?: Id;
}

interface ChargeFormFieldsProps {
  currency?: CurrencyCode;
}

export interface ChargeFormExtraData {
  isForBalanceCharge: boolean;
}

const ChargeAmountFormField: React.FC<ChargeFormFieldsProps> = ({
  currency,
}) => {
  const { t } = useTranslation();
  const currencyCode: undefined | CurrencyCode =
    useWatch({ name: 'currency' }) ?? currency;

  const amountProps = useMemo(
    () => ({
      isDisabled: !currencyCode,
      control: currencyCode ? FormCurrencyInput : FormAdvancedNumberInput,
      ...(currencyCode ? { code: currencyCode } : {}),
    }),
    [currencyCode]
  );

  return (
    <FormField
      isRequired
      name="amount"
      label={t('forms:labels.amount')}
      {...amountProps}
    />
  );
};

const defaultInitialValues: Partial<Charge> = {};

export const ChargeFormSettingFormFields: React.FC<
  ChargeFormSettingFormFieldsProps
> = ({ accountId }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Charge);
  const contractQueryParams = useMemo(() => ({ accountId }), [accountId]);

  return (
    <FormSection
      isOptional
      heading={t('forms:labels.entitySettings', {
        entityName: entityNamings.singular,
      })}
    >
      <FormField
        name="contractId"
        label={t('common:contract')}
        control={
          FormEntitySelect as DataTypeFormEntitySelect<DataType.Contract>
        }
        dataType={DataType.Contract}
        params={contractQueryParams}
        accessor="name"
        detailAccessor="code"
      />
      <FormField
        name="notes"
        label={t('forms:labels.notes')}
        control={FormInput}
        helpText={t('forms:helpText.chargeNotes')}
      />
    </FormSection>
  );
};

export const ChargeForm: React.FC<ChargeFormProps> = ({
  onSave,
  onCancel,
  extraData,
  isEdit = false,
  isSaving = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const chargeEntityNamings = useEntityNamings(DataType.Charge);
  const balanceChargeEntityNamings = useEntityNamings(DataType.BalanceCharge);

  const isForBalanceCharge = !!extraData?.isForBalanceCharge;
  const entityNamings = isForBalanceCharge
    ? balanceChargeEntityNamings
    : chargeEntityNamings;

  return (
    <Form
      onSubmit={onSave}
      initialValues={initialValues}
      validationSchema={chargeSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <NameCodeFields />
          <SimpleGrid w="100%" columnGap={4} columns={2}>
            <FormField
              isRequired
              useCodeAsValue
              name="currency"
              label={t('forms:labels.currency')}
              control={FormPicklistSelect}
              dataType={DataType.Currency}
            />
            <ChargeAmountFormField currency={extraData?.currency} />
          </SimpleGrid>
          <SimpleGrid w="100%" columnGap={4} columns={2}>
            <FormField
              isRequired
              name="servicePeriodStartDate"
              label={t('forms:labels.servicePeriodStart')}
              control={FormDatePicker}
            />
            <FormField
              isRequired
              name="servicePeriodEndDate"
              label={t('forms:labels.servicePeriodEnd')}
              control={FormDatePicker}
            />
          </SimpleGrid>
          <FormField
            isClearable
            name="accountingProductId"
            label={t('forms:labels.accountingProduct')}
            control={
              FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
            }
            dataType={DataType.Product}
            accessor="name"
            detailAccessor="code"
          />
          <FormField
            stripTime
            name="billDate"
            label={t('features:billing.billDate')}
            control={FormDatePicker}
            helpText={t('forms:helpText.chargeBillDate')}
          />
          <FormField
            name="description"
            label={t('forms:labels.description')}
            helpText={t('forms:helpText.displayedBillLineItem')}
            control={FormInput}
          />
        </FormSection>
        <ChargeFormSettingFormFields accountId={initialValues.accountId} />
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t('forms:buttons.createEntity', {
                  entityName: entityNamings.singularLower,
                })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
