import { useCallback, useMemo, useState } from 'react';

import { TFunction } from 'i18next';
import { Spinner, useSteps, VStack } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

import { DataType } from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Stepper, Step, Alert, AlertLink } from '@m3ter-com/ui-components';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { NamedLink } from '@/components/common/navigation/NamedLink/NamedLink';

import useExternalMappingsCreate, {
  ExternalMappingsCreateFormValues,
} from './useExternalMappingsCreate';
import {
  ExternalMappingsCreateFormSettingsStep,
  ExternalMappingsCreateFormSettingsStepValues,
} from './ExternalMappingsCreateFormSettingsStep';
import {
  ExternalMappingsCreateMode,
  ExternalMappingsCreateFormIdsStep,
  ExternalMappingsCreateFormIdsStepValues,
} from './ExternalMappingsCreateFormIdsStep';

export interface ExternalMappingsCreateFormProps {
  initialValues?: DeepPartial<ExternalMappingsCreateFormValues>;
  linkedIntegrationId?: string;
  mode: ExternalMappingsCreateMode;
  returnPath?: string;
  onCancel?: () => void;
}

export const ExternalMappingsCreateForm: React.FC<
  ExternalMappingsCreateFormProps
> = ({ initialValues, linkedIntegrationId, mode, returnPath, onCancel }) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.ExternalMapping);

  const {
    externalMappingConfig,
    externalMappingConfigError,
    isLoadingExternalMappingConfig,
    isLoadingLinkedIntegration,
    isSaving,
    linkedIntegration,
    linkedIntegrationError,
    onSave,
    savingError,
  } = useExternalMappingsCreate(linkedIntegrationId);

  const { activeStep, goToNext, goToPrevious } = useSteps({ count: 2 });

  const [settingsStepData, setSettingsStepData] = useState<
    Partial<ExternalMappingsCreateFormSettingsStepValues>
  >({
    externalSystem: initialValues?.externalSystem,
    externalTable: initialValues?.externalTable,
    m3terEntity: initialValues?.m3terEntity,
  });
  const isSettingsStepComplete =
    !!settingsStepData.externalSystem &&
    !!settingsStepData.externalTable &&
    !!settingsStepData.m3terEntity;

  const onSettingsStepSubmit = useCallback(
    (newSettingsStepData: ExternalMappingsCreateFormSettingsStepValues) => {
      setSettingsStepData(newSettingsStepData);
      goToNext();
    },
    [goToNext]
  );

  const idsStepInitialValues = useMemo<
    DeepPartial<ExternalMappingsCreateFormIdsStepValues>
  >(
    () => ({
      mappings: initialValues?.mappings,
    }),
    [initialValues]
  );
  const onIdsStepSubmit = useCallback(
    (idsStepData: ExternalMappingsCreateFormIdsStepValues) => {
      if (isSettingsStepComplete) {
        onSave({
          ...(settingsStepData as ExternalMappingsCreateFormSettingsStepValues),
          ...idsStepData,
          returnPath,
        });
      }
    },
    [isSettingsStepComplete, onSave, returnPath, settingsStepData]
  );

  if (externalMappingConfigError) {
    return <ErrorAlert error={externalMappingConfigError} />;
  }

  if (isLoadingExternalMappingConfig || isLoadingLinkedIntegration) {
    return <Spinner />;
  }

  if (externalMappingConfig) {
    return (
      <VStack alignItems="stretch" spacing={6}>
        {!!savingError && (
          <ErrorAlert
            error={savingError}
            title={t('errors:generic.problemSavingData', {
              entityName: entityNamings.pluralLower,
            })}
          />
        )}
        {!!linkedIntegrationError && (
          <ErrorAlert
            error={linkedIntegrationError}
            title={t('errors:externalMappings.problemLoadingLinkedIntegration')}
          />
        )}
        {!linkedIntegrationId && (
          <Alert status="info">
            <Trans
              t={t as TFunction}
              i18nKey="features:externalMappings.linkedIntegrationFormWarning"
              components={{
                integrationsListLink: (
                  <AlertLink as={NamedLink} name="integrations.systems-list" />
                ),
              }}
            />
          </Alert>
        )}
        <Stepper index={activeStep} mb={6}>
          <Step title={t('features:externalMappings.selectMappingType')} />
          <Step title={t('features:externalMappings.addMappingIds')} />
        </Stepper>
        {activeStep === 0 && (
          <ExternalMappingsCreateFormSettingsStep
            externalMappingConfig={externalMappingConfig}
            initialValues={settingsStepData}
            isSaving={isSaving}
            onCancel={onCancel}
            onSave={onSettingsStepSubmit}
          />
        )}
        {activeStep === 1 && (
          <ExternalMappingsCreateFormIdsStep
            initialValues={idsStepInitialValues}
            isSaving={isSaving}
            linkedIntegration={linkedIntegration}
            mode={mode}
            onBack={goToPrevious}
            onCancel={onCancel}
            onSave={onIdsStepSubmit}
            settings={
              isSettingsStepComplete
                ? (settingsStepData as ExternalMappingsCreateFormSettingsStepValues)
                : undefined
            }
          />
        )}
      </VStack>
    );
  }

  return null;
};

// Re-export types
export { ExternalMappingsCreateMode };
export type {
  ExternalMappingsCreateFormValues,
  ExternalMappingsCreateFormIdsStepValues,
  ExternalMappingsCreateFormSettingsStepValues,
};
