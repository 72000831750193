import { ThemeOverride } from '@chakra-ui/react';

// https://github.com/chakra-ui/chakra-ui/blob/170198fc3936ad34f8136a2da173c12d9dc3dc36/packages/components/theme/src/semantic-tokens.ts
type SemanticTokens = Required<ThemeOverride>['semanticTokens'];
const semanticTokens: SemanticTokens = {
  colors: {
    // Custom colors
    'bg-dark': 'blue.800',
    'bg-subtle-dark': 'gray.700',
    'bg-light': 'gray.50',
    'bg-subtle-light': 'white',
    'text-dark': 'white',
    'text-subtle-dark': 'gray.400',
    'text-light': 'blue.900',
    'text-subtle-light': 'gray.500',
    'border-light': 'gray.200',
    'border-dark': 'whiteAlpha.300',
    'misc-hover-dark': 'blue.900',
    'misc-hover-light': 'blue.50',
    'misc-hover': {
      _light: 'misc-hover-light',
      _dark: 'misc-hover-dark',
    },
    'misc-highlight': { _light: 'blue.500', _dark: 'blue.300' },
    'nav-highlight': { _light: 'gray.200', _dark: 'whiteAlpha.300' },
    'blue-bright': 'blue.500',
    'blue-pale': 'blue.200',
    'blue-adaptive': { _light: 'blue-bright', _dark: 'blue-pale' },
    'green-bright': 'green.500',
    'green-pale': 'green.200',
    'green-adaptive': { _light: 'green-bright', _dark: 'green-pale' },
    'orange-bright': 'orange.500',
    'orange-pale': 'orange.200',
    'orange-adaptive': { _light: 'orange-bright', _dark: 'orange-pale' },
    'red-bright': 'red.500',
    'red-pale': 'red.200',
    'red-adaptive': { _light: 'red-bright', _dark: 'red-pale' },
    'yellow-bright': 'yellow.500',
    'yellow-pale': 'yellow.200',
    'yellow-adaptive': { _light: 'yellow-bright', _dark: 'yellow-pale' },
    'nav-bg': { _light: 'gray.100', _dark: 'blue.900' },
    'nav-actions-bg': { _light: 'gray.200', _dark: 'blue.800' },
    // Standard chakra variables
    'chakra-body-text': { _light: 'text-light', _dark: 'text-dark' },
    'chakra-body-bg': { _light: 'bg-light', _dark: 'bg-dark' },
    'chakra-border-color': {
      _light: 'border-light',
      _dark: 'border-dark',
    },
    'chakra-inverse-text': { _light: 'text-dark', _dark: 'text-light' },
    'chakra-subtle-bg': {
      _light: 'bg-subtle-light',
      _dark: 'bg-subtle-dark',
    },
    'chakra-subtle-text': {
      _light: 'text-subtle-light',
      _dark: 'text-subtle-dark',
    },
    'chakra-placeholder-color': {
      _light: 'chakra-subtle-text',
      _dark: 'chakra-subtle-text',
    },
  },
};

export default semanticTokens;
