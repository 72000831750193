import React, { useCallback, useMemo } from 'react';

import { SimpleGrid } from '@chakra-ui/react';
import { useWatch } from 'react-hook-form';

import {
  Balance,
  CurrencyCode,
  DataType,
  DrawdownChargeTypes,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, MultiSelectOption } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormAdvancedNumberInput,
  FormCurrencyInput,
  FormField,
  FormInput,
  FormMultiSelect,
  FormSection,
  NameCodeFields,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import { FormDatePicker } from '@/components/forms/FormDatePicker/FormDatePicker';
import {
  DataTypeFormEntityMultiSelect,
  FormEntityMultiSelect,
} from '@/components/forms/FormEntityMultiSelect/FormEntityMultiSelect';
import { FormPicklistSelect } from '@/components/features/accounts/FormPicklistSelect/FormPicklistSelect';
import {
  FormEntitySelect,
  DataTypeFormEntitySelect,
} from '@/components/forms/FormEntitySelect/FormEntitySelect';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import balanceSchema from '@/validation/balance';

export interface BalanceFormProps extends BaseFormProps<Balance> {}

export const BalanceRolloverAmountFormField = () => {
  const { t } = useTranslation();
  const currency: CurrencyCode | undefined = useWatch({
    name: 'currency',
  });

  const amountProps = useMemo(
    () => ({
      name: 'rolloverAmount',
      label: t('forms:labels.rolloverAmount'),
      isDisabled: !currency,
      control: currency ? FormCurrencyInput : FormAdvancedNumberInput,
      ...(currency ? { code: currency } : {}),
    }),
    [currency, t]
  );

  return <FormField {...amountProps} />;
};

const defaultInitialValues: Partial<Balance> = {};

export const BalanceForm: React.FC<BalanceFormProps> = ({
  onSave,
  onCancel,
  isSaving,
  isEdit = false,
  initialValues = defaultInitialValues,
}) => {
  const { t } = useTranslation();
  const entityNamings = useEntityNamings(DataType.Balance);

  const lineItemTypeOptions = useMemo<Array<MultiSelectOption>>(
    () =>
      Object.values(DrawdownChargeTypes).map((chargeType) => ({
        value: chargeType,
        label: t(`features:billing.lineItemTypes.${chargeType}`),
      })),
    [t]
  );

  const onSubmit = useCallback(
    (data: Balance) => {
      const { lineItemTypes = [], ...rest } = data;
      onSave({
        ...rest,
        lineItemTypes: lineItemTypes.length > 0 ? lineItemTypes : undefined,
      });
    },
    [onSave]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={balanceSchema}
    >
      <FormStack>
        <FormSection
          heading={t('common:entityDetails', {
            entityName: entityNamings.singular,
          })}
        >
          <NameCodeFields />
          <FormField
            isRequired
            name="currency"
            label={t('forms:labels.currency')}
            control={FormPicklistSelect}
            useCodeAsValue
            dataType={DataType.Currency}
          />
          <SimpleGrid
            columns={2}
            columnGap={4}
            alignItems="stretch"
            width="100%"
          >
            <FormField
              isRequired
              name="startDate"
              label={t('forms:labels.startDateInclusive')}
              control={FormDatePicker}
              showTimeSelect
            />
            <FormField
              isRequired
              name="endDate"
              label={t('forms:labels.endDateExclusive')}
              control={FormDatePicker}
              showTimeSelect
            />
          </SimpleGrid>
          <FormField
            isClearable
            name="consumptionsAccountingProductId"
            label={t('forms:labels.consumptionsAccountingProduct')}
            control={
              FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
            }
            dataType={DataType.Product}
            accessor="name"
            detailAccessor="code"
          />
          <FormField
            name="description"
            label={t('forms:labels.description')}
            control={FormInput}
          />
        </FormSection>
        <FormSection isOptional heading={t('forms:labels.balanceDrawdown')}>
          <FormField
            isClearable
            name="productIds"
            label={t('forms:labels.drawDownProducts')}
            control={
              FormEntityMultiSelect as DataTypeFormEntityMultiSelect<DataType.Product>
            }
            dataType={DataType.Product}
            accessor="name"
            detailAccessor="code"
            helpText={t('forms:helpText.drawDownProducts', {
              entityName: entityNamings.singularLower,
            })}
          />
          <FormField
            name="lineItemTypes"
            label={t('forms:labels.drawDownChargeTypes')}
            control={FormMultiSelect}
            options={lineItemTypeOptions}
            isClearable
          />
          <FormField
            name="balanceDrawDownDescription"
            label={t('forms:labels.drawDownDescription')}
            control={FormInput}
            helpText={t('forms:helpText.displayedBillLineItem')}
          />
        </FormSection>
        <FormSection
          isOptional
          heading={t('forms:labels.balanceOverageAndRollover')}
        >
          <BalanceRolloverAmountFormField />
          <FormField
            name="rolloverEndDate"
            label={t('forms:labels.rolloverEndDate')}
            control={FormDatePicker}
            showTimeSelect
          />
          <FormField
            name="overageSurchargePercent"
            label={t('forms:labels.overageSurchargePercent')}
            control={FormInput}
            type="number"
            addonText="%"
          />
          <FormField
            name="overageDescription"
            label={t('forms:labels.overageDescription')}
            control={FormInput}
            helpText={t('forms:helpText.displayedBillLineItem')}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          submitText={
            isEdit
              ? t('forms:buttons.updateEntity', {
                  entityName: entityNamings.singularLower,
                })
              : t('forms:buttons.createEntity', {
                  entityName: entityNamings.singularLower,
                })
          }
          isSaving={isSaving}
          onCancel={onCancel}
        />
      </FormStack>
    </Form>
  );
};
