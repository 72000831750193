import { DataType } from '@m3ter-com/m3ter-api';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';
import { MetersRoute } from '@/components/routes/meters/MetersRoute';

const LazyMetersListRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MetersListRoute" */ '@/components/routes/meters/MetersListRoute'
  ).then((module) => ({ default: module.MetersListRoute }))
);

const LazyMeterDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MeterDetailsRoute" */ '@/components/routes/meters/MeterDetailsRoute'
  ).then((module) => ({ default: module.MeterDetailsRoute }))
);

const LazyMeterCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MeterCreateRoute" */ '@/components/routes/meters/MeterCreateRoute'
  ).then((module) => ({
    default: module.MeterCreateRoute,
  }))
);

const LazyMeterEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "MeterEditRoute" */ '@/components/routes/meters/MeterEditRoute'
  ).then((module) => ({
    default: module.MeterEditRoute,
  }))
);

export default () =>
  createCrudRoute({
    path: 'meters',
    dataType: DataType.Meter,
    element: <MetersRoute />,
    list: <LazyMetersListRoute />,
    details: <LazyMeterDetailsRoute />,
    create: <LazyMeterCreateRoute />,
    edit: <LazyMeterEditRoute />,
  });
