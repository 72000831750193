import { ElementType, ReactElement, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { Stack } from '@chakra-ui/react';

import {
  DataType,
  DataTypeToEntity,
  PathParams,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';
import { DeepPartial } from '@m3ter-com/console-core/types';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import useEntityRedirect, {
  RedirectTo,
} from '@/hooks/navigation/useEntityRedirect';
import useEntityCreateMutation from '@/hooks/data/useEntityCreateMutation';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';

export interface EntityCreateProps<DT extends DataType> {
  dataType: DT;
  form: ElementType;
  defaultRedirectTo: RedirectTo<DataTypeToEntity[DT]>;
  extraData?: Record<string, any>;
  initialValues?: DeepPartial<DataTypeToEntity[DT]>;
  pathParams?: PathParams;
}

const defaultInitialValues: DeepPartial<any> = {};

export function EntityCreate<DT extends DataType>({
  dataType,
  form: Form,
  defaultRedirectTo,
  extraData,
  initialValues = defaultInitialValues,
  pathParams,
}: EntityCreateProps<DT>): ReactElement<any, any> {
  const { t } = useTranslation();
  const { singularLower: entityName } = useEntityNamings(dataType);

  const { createEntity, isSaving, error } = useEntityCreateMutation(dataType);
  const { getReturnPath } = useEntityRedirect(defaultRedirectTo);

  const navigate = useNavigate();

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSave = useCallback(
    (data: UnsavedEntity<DataTypeToEntity[DT]>) => {
      createEntity(
        { newEntity: data, pathParams },
        {
          onSuccess: (createdData) => {
            // Redirect based on query string or the default redirect.
            navigate(getReturnPath(createdData));
          },
        }
      );
    },
    [createEntity, pathParams, navigate, getReturnPath]
  );

  return (
    <Stack>
      {error && (
        <ErrorAlert
          title={t('errors:generic.problemSavingData', { entityName })}
          error={error}
        />
      )}
      <Form
        isSaving={isSaving}
        onSave={onSave}
        onCancel={onCancel}
        initialValues={initialValues}
        extraData={extraData}
        entityName={entityName}
        dataType={dataType}
      />
    </Stack>
  );
}
