import { useCallback, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Contract, DataType, Id } from '@m3ter-com/m3ter-api';
import {
  getCleanDateInstance,
  getDatesSortOrder,
} from '@m3ter-com/console-core/utils';

import useOrg from '@/hooks/data/crud/useOrg';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { dataTypeListAllQuery } from '@/queries/crud';
import useEntityDeleteMutation from '@/hooks/data/useEntityDeleteMutation';

const useAccountContracts = (accountId: Id) => {
  const pathParams = useOrgPathParams();
  const { orgConfig } = useOrg();

  const {
    error,
    isLoading,
    data: accountContracts = [],
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.Contract,
      pathParams,
      queryParams: { accountId },
    })
  );

  const sortedAccountContracts = useMemo(
    () =>
      accountContracts.sort((currentAccountContract, nextAccountContract) =>
        getDatesSortOrder(
          currentAccountContract.startDate,
          nextAccountContract.startDate
        )
      ),
    [accountContracts]
  );

  const { deleteEntity: deleteContract } = useEntityDeleteMutation(
    DataType.Contract
  );

  const onDeleteAccountContract = useCallback(
    (contract: Contract) => {
      deleteContract({ entity: contract });
    },
    [deleteContract]
  );

  /**
   * useMemo hook to process and categorize account contracts.
   *
   * This hook processes the sortedAccountContracts array and categorizes the contracts
   * into active/pending and previous categories based on their end dates.
   *
   * @param {Object} orgConfig - The organization configuration object containing timezone information.
   * @param {Array} sortedAccountContracts - An array of account contracts sorted by start date.
   *
   * @returns {Object} An object containing:
   * - activeAndPendingContractsData: An array of active or pending contract data.
   * - previousContractsData: An array of previous contract data.
   */
  const {
    activeAndPendingContractsData: activeAndPendingContracts,
    previousContractsData: previousContracts,
  } = useMemo(() => {
    const activeAndPendingContractsData = new Array<Contract>();
    const previousContractsData = new Array<Contract>();

    sortedAccountContracts.forEach((contract) => {
      const contractEndDate = getCleanDateInstance(
        contract.endDate,
        orgConfig.timezone
      );
      if (contractEndDate > new Date(Date.now())) {
        activeAndPendingContractsData.push(contract);
      } else {
        previousContractsData.push(contract);
      }
    });

    return {
      activeAndPendingContractsData,
      previousContractsData,
    };
  }, [orgConfig, sortedAccountContracts]);

  return {
    isLoading,
    error,
    onDeleteAccountContract,
    activeAndPendingContracts,
    previousContracts,
  };
};

export default useAccountContracts;
