import { useCallback } from 'react';

import { DataGridColumnDisplay } from '@m3ter-com/ui-components';

import { PreferenceName } from '@/types/preferences';

import usePreference from './usePreference';

export interface UseColumnDisplayPreferenceReturn {
  columnDisplay: Array<DataGridColumnDisplay> | undefined;
  onColumnDisplayChange: (
    newColumnDisplay: Array<DataGridColumnDisplay>
  ) => void;
}

type ColumnDisplayPreferences = Record<string, Array<DataGridColumnDisplay>>;

const defaultColumnDisplayPreferences: ColumnDisplayPreferences = {};

const useColumnDisplayPreference = (
  listId: string
): UseColumnDisplayPreferenceReturn => {
  const [columnDisplayPreferences, setColumnDisplayPreferences] = usePreference(
    PreferenceName.COLUMN_DISPLAY,
    defaultColumnDisplayPreferences
  );

  const onColumnDisplayChange = useCallback(
    (newColumnDisplay: Array<DataGridColumnDisplay>) => {
      setColumnDisplayPreferences((currentPreferences) => ({
        ...currentPreferences,
        [listId]: newColumnDisplay,
      }));
    },
    [listId, setColumnDisplayPreferences]
  );

  return {
    columnDisplay: columnDisplayPreferences[listId],
    onColumnDisplayChange,
  };
};

export default useColumnDisplayPreference;
