import { useCallback, useMemo, useRef, useState } from 'react';

import {
  ButtonGroup,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';

import { SearchComparator, SearchCriterion } from '@m3ter-com/m3ter-api';
import { Button, Select, TagButton } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { SearchFieldDefinition, SearchFieldType } from '@/types/search';

import { getComparatorSelectOptions } from '@/util/search';

export interface EntitySearchListFilterProps {
  criterion: SearchCriterion;
  searchFieldDefinitions: Array<SearchFieldDefinition>;
  onRemove: () => void;
  onUpdate: (criterion: SearchCriterion) => void;
}

export const EntitySearchListFilter: React.FC<EntitySearchListFilterProps> = ({
  criterion,
  searchFieldDefinitions,
  onRemove,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const { isOpen, onClose, onToggle } = useDisclosure();

  const [comparator, setComparator] = useState(criterion.comparator);
  const [value, setValue] = useState(`${criterion.value}`);

  const searchFieldDefinition = useMemo(
    () =>
      searchFieldDefinitions.find(
        (definition) => definition.field === criterion.field
      ),
    [searchFieldDefinitions, criterion]
  );

  const comparatorOptions = useMemo(
    () =>
      getComparatorSelectOptions(
        searchFieldDefinition?.type ?? SearchFieldType.String
      ),
    [searchFieldDefinition]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    []
  );

  const onUpdateClick = useCallback(() => {
    onUpdate({
      ...criterion,
      comparator,
      value,
    });
    onClose();
  }, [onUpdate, onClose, criterion, comparator, value]);

  const onCancel = useCallback(() => {
    // Reset
    setComparator(criterion.comparator);
    setValue(`${criterion.value}`);
    onClose();
  }, [criterion, onClose]);

  return (
    <Popover
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <TagButton
          size="sm"
          colorScheme="blue"
          onClick={onToggle}
          onClose={onRemove}
        >
          {searchFieldDefinition?.label} {criterion.comparator}{' '}
          {criterion.value}
        </TagButton>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverHeader>{searchFieldDefinition?.label}</PopoverHeader>
        <PopoverBody>
          <Stack>
            <Select
              value={comparator}
              onChange={
                setComparator as (value: SearchComparator | null) => void
              }
              options={comparatorOptions}
              aria-label={t('features:search.comparator')}
            />
            <Input
              ref={initialFocusRef}
              value={value}
              onChange={onInputChange}
              aria-label={t('common:query')}
            />
          </Stack>
        </PopoverBody>
        <PopoverFooter>
          <ButtonGroup size="sm">
            <Button onClick={onUpdateClick}>{t('common:update')}</Button>
            <Button variant="ghost" onClick={onCancel}>
              {t('common:cancel')}
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
