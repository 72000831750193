import { useCallback, useMemo } from 'react';

import { Reorder, useDragControls } from 'framer-motion';
import { Box, Flex, Icon, List, ListItem, Switch } from '@chakra-ui/react';
import { GripVerticalIcon } from 'lucide-react';

import {
  ColumnDisplay,
  ColumnOption,
  ColumnOptionWithVisibility,
} from '../../../types/tables';

interface ColumnItemProps {
  option: ColumnOptionWithVisibility;
  enableOrdering: boolean;
  enableToggling: boolean;
  isToggleDisabled: boolean;
  onToggle: () => void;
}

const ColumnItem: React.FC<ColumnItemProps> = ({
  option,
  enableOrdering,
  enableToggling,
  isToggleDisabled,
  onToggle,
}) => {
  const controls = useDragControls();
  const id = `toggle-${option.id}`;

  return (
    <ListItem
      as={Reorder.Item}
      value={option as any} // `MergeWithAs` is overriding `Reorder.Item`'s `value` prop with one from React's `LiHTMLAttributes` so we get a TS error without the cast.
      dragListener={false}
      dragControls={controls}
      userSelect="none"
      whiteSpace="nowrap"
    >
      <Flex gap={2} alignItems="center">
        {enableOrdering && (
          <Icon
            as={GripVerticalIcon}
            onPointerDown={(e) => controls.start(e)}
            color="chakra-subtle-text"
            cursor="move"
          />
        )}
        {enableToggling ? (
          <Box as="label" flex={1} htmlFor={id}>
            {option.header}
          </Box>
        ) : (
          <Box flex={1}>{option.header}</Box>
        )}
        {enableToggling && (
          <Switch
            id={id}
            size="sm"
            isChecked={option.visible}
            onChange={onToggle}
            isDisabled={isToggleDisabled}
            value={option.id}
          />
        )}
      </Flex>
    </ListItem>
  );
};

export interface DataGridColumnSettingsProps {
  columnOptions: Array<ColumnOption>;
  onChange?: (columnDisplay: Array<ColumnDisplay>) => void;
  value: Array<ColumnDisplay>;
  enableOrdering?: boolean;
  enableToggling?: boolean;
}

export const DataGridColumnSettings: React.FC<DataGridColumnSettingsProps> = ({
  columnOptions,
  value,
  enableOrdering = false,
  enableToggling = false,
  onChange,
}) => {
  const sortedColumnOptions = useMemo(() => {
    return value
      .map(({ id, visible }) => {
        const column = columnOptions.find((option) => option.id === id);
        return column ? { ...column, visible } : undefined;
      })
      .filter(Boolean) as Array<ColumnOptionWithVisibility>;
  }, [columnOptions, value]);

  const onOrderChange = useCallback(
    (data: Array<ColumnOptionWithVisibility>) => {
      if (onChange) {
        onChange(data.map(({ id, visible }) => ({ id, visible })));
      }
    },
    [onChange]
  );

  const onToggleOption = useCallback(
    (option: ColumnOptionWithVisibility) => {
      if (onChange) {
        onChange(
          sortedColumnOptions.map((item) => ({
            id: item.id,
            visible: item === option ? !item.visible : item.visible,
          }))
        );
      }
    },
    [onChange, sortedColumnOptions]
  );

  const visibleColumnCount = useMemo(
    () => sortedColumnOptions.filter((option) => option.visible).length,
    [sortedColumnOptions]
  );

  return (
    <List
      as={Reorder.Group}
      axis="y"
      values={sortedColumnOptions}
      onReorder={onOrderChange}
      spacing={2}
    >
      {sortedColumnOptions.map((columnOption) => (
        <ColumnItem
          key={columnOption.id}
          option={columnOption}
          enableOrdering={enableOrdering}
          enableToggling={enableToggling}
          isToggleDisabled={
            // Disble the toggle if alwaysVisible or this is the last visible option.
            columnOption.alwaysVisible ||
            (visibleColumnCount === 1 && columnOption.visible)
          }
          onToggle={() => onToggleOption(columnOption)}
        />
      ))}
    </List>
  );
};
