import { Link, SimpleGrid } from '@chakra-ui/react';

import {
  DataType,
  ExternalMapping,
  IntegrationConfig,
} from '@m3ter-com/m3ter-api';
import { KeyValue, Well } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { integrationAccessor } from '@/util/integrations';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { ExternalSystemLogo } from '@/components/common/brand/ExternalSystemLogo/ExternalSystemLogo';

interface ExternalMappingsFormSettingsDisplayProps
  extends Pick<
    ExternalMapping,
    'externalSystem' | 'externalTable' | 'm3terEntity'
  > {
  linkedIntegration?: IntegrationConfig;
}

export const ExternalMappingsFormSettingsDisplay: React.FC<
  ExternalMappingsFormSettingsDisplayProps
> = ({ externalSystem, externalTable, linkedIntegration, m3terEntity }) => {
  const { t } = useTranslation();

  return (
    <Well w="100%">
      <SimpleGrid columns={2} gap={2}>
        <KeyValue
          label={t('forms:labels.entityType')}
          value={t(`features:externalMappings.entityTypes.${m3terEntity}`)}
        />
        <KeyValue
          label={t('features:externalMappings.linkedIntegration')}
          value={
            linkedIntegration ? (
              <Link
                as={CrudDetailsLink}
                dataType={DataType.Integration}
                id={linkedIntegration.id}
              >
                {integrationAccessor(linkedIntegration)}
              </Link>
            ) : (
              '-'
            )
          }
        />
        <KeyValue
          gridColumn="1 / 2"
          label={t('forms:labels.externalSystem')}
          value={<ExternalSystemLogo system={externalSystem} height="20px" />}
        />
        <KeyValue
          label={t('forms:labels.externalTable')}
          value={externalTable}
        />
      </SimpleGrid>
    </Well>
  );
};
