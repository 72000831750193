import { useCallback, useMemo } from 'react';

import { keepPreviousData } from '@tanstack/react-query';

import {
  buildSearchQuery,
  DataType,
  list,
  QueryParams,
  SearchSortOrder,
} from '@m3ter-com/m3ter-api';

import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useListSizePreference from '@/hooks/data/useListSizePreference';
import useColumnDisplayPreference from '@/hooks/data/useColumnDisplayPreference';
import useCursorPaginatedQuery from '@/hooks/data/useCursorPaginatedQuery';

import useEntitySearchListQueryParams from './useEntitySearchListQueryParams';

interface UseEntitySearchListOptions<DT extends DataType> {
  dataType: DT;
  defaultSortBy?: string;
  defaultSortOrder?: SearchSortOrder;
  relationships?: Array<string>;
}

const useEntitySearchList = <DT extends DataType>({
  dataType,
  defaultSortBy,
  defaultSortOrder = SearchSortOrder.Ascending,
  relationships,
}: UseEntitySearchListOptions<DT>) => {
  const {
    criteria,
    operator,
    sortBy,
    sortOrder,
    setCriteria,
    setOperator,
    setSort,
  } = useEntitySearchListQueryParams(defaultSortBy, defaultSortOrder);

  const listId = `${dataType}-browse`;
  const { listSize: pageSize, onListSizeChange } =
    useListSizePreference(listId);
  const { columnDisplay, onColumnDisplayChange } =
    useColumnDisplayPreference(listId);

  const pathParams = useOrgPathParams();

  const queryParams: QueryParams = useMemo(
    () => ({
      searchQuery: buildSearchQuery(criteria),
      operator,
      sortBy,
      sortOrder,
      pageSize,
    }),
    [sortBy, sortOrder, pageSize, criteria, operator]
  );

  const {
    currentPageData = [],
    isFetching,
    currentPage,
    goToNextPage,
    goToPage,
    refetch,
    hasMore,
    knownPageCount,
    error,
  } = useCursorPaginatedQuery({
    queryFn: ({ pageParam }) =>
      list({
        dataType,
        actionName: 'search',
        pathParams,
        queryParams: { ...queryParams, fromDocument: pageParam },
        relationships,
      }),
    queryKey: [dataType, 'search', pathParams, queryParams, relationships],
    placeholderData: keepPreviousData,
  });

  const onSortChange = useCallback(
    (column: string, descending: boolean) => {
      setSort(
        column,
        descending ? SearchSortOrder.Descending : SearchSortOrder.Ascending
      );
      goToPage(1);
    },
    [setSort, goToPage]
  );

  const onPageSizeChange = useCallback(
    (size: number) => {
      onListSizeChange(size);
      goToPage(1);
    },
    [onListSizeChange, goToPage]
  );

  return {
    columnDisplay,
    criteria,
    currentPage,
    currentPageData,
    error,
    goToNextPage,
    goToPage,
    hasMore,
    isFetching,
    knownPageCount,
    onColumnDisplayChange,
    onPageSizeChange,
    onSortChange,
    operator,
    pageSize,
    refetch,
    setCriteria,
    setOperator,
    sortBy,
    sortOrder,
  };
};

export default useEntitySearchList;
