import { create, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import {
  AggregationFrequency,
  ExportSchedule,
  SourceType,
} from '@m3ter-com/m3ter-api';

const suite = create('exportSchedule', (data: Partial<ExportSchedule>) => {
  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test('code', i18next.t('forms:validations.common.codeRequired'), () => {
    enforce(data.code).isNotEmpty();
  });

  omitWhen(data.sourceType === SourceType.Usage, () => {
    test(
      'operationalDataTypes',
      i18next.t('forms:validations.dataExport.operationalDataTypesRequired'),
      () => {
        enforce(data.operationalDataTypes).isNotEmpty();
      }
    );
  });

  test(
    'period',
    i18next.t('forms:validations.dataExport.frequencyIntervalRequired'),
    () => {
      enforce(data.period).isNotEmpty();
    }
  );

  omitWhen(
    data.sourceType === SourceType.Operational ||
      data.aggregationFrequency === AggregationFrequency.Original,
    () => {
      test(
        'aggregation',
        i18next.t('forms:validations.dataExport.aggregationRequired'),
        () => {
          enforce(data.aggregation).isNotEmpty();
        }
      );
    }
  );
});

export default suite;
