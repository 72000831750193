import React, { ElementType, ReactNode } from 'react';

import { Box, Flex, Heading, Icon, StyleProps, VStack } from '@chakra-ui/react';

export interface EmptyStateProps extends Omit<StyleProps, 'content'> {
  title: string;
  content?: ReactNode;
  actions?: ReactNode;
  icon?: ElementType;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  icon,
  title,
  content,
  actions,
  ...styleProps
}) => {
  return (
    <Flex
      p={8}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      {...styleProps}
    >
      <VStack spacing={6}>
        {icon && <Icon as={icon} boxSize={12} color="chakra-subtle-text" />}
        <Heading as="h2" size="sm">
          {title}
        </Heading>
        {content && <Box>{content}</Box>}
        {actions}
      </VStack>
    </Flex>
  );
};
