import React, { useCallback, useMemo, useState } from 'react';

import {
  Box,
  ButtonGroup,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { RefreshCwIcon } from 'lucide-react';

import {
  DataType,
  BalanceTransaction,
  BalanceTransactionEntityType,
  Id,
  CurrencyCode,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { formatNumber, getDatesSortOrder } from '@m3ter-com/console-core/utils';
import {
  Button,
  CardActionsHeader,
  DataTable,
  DataTableColumnDefinition,
  IconButton,
  NumericSelect,
  NumericSelectOption,
  Pagination,
} from '@m3ter-com/ui-components';

import { ListSize } from '@/types/lists';

import { ReferenceLabel } from '@/components/common/data/ReferenceLabel/ReferenceLabel';
import { ReferenceLink } from '@/components/common/data/ReferenceLink/ReferenceLink';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { dataTypeListAllQuery } from '@/queries/crud';
import { getReference } from '@/util/billing';
import useListSizePreference from '@/hooks/data/useListSizePreference';

const listSizeOptions: Array<NumericSelectOption> = [20, 50, 100, 200].map(
  (value) => ({
    label: `${value}`,
    value,
  })
);

interface BalanceTransactionWithBalance extends BalanceTransaction {
  balance: number;
}

interface BalanceTransactionsTableProps {
  currency: CurrencyCode;
  balanceId: Id;
}

interface SourceLinkProps {
  transaction: BalanceTransaction;
}

const SourceLink: React.FC<SourceLinkProps> = ({ transaction }) => {
  const { t } = useTranslation();
  const label = t(`features:balances.entityTypes.${transaction.entityType}`);

  // We can only link to a bill.
  return transaction.entityType === BalanceTransactionEntityType.Bill ? (
    <Flex gap={2} alignItems="center" display="inline-flex">
      <ReferenceLink
        dataType={DataType.Bill}
        id={transaction.entityId}
        accessor={(item) => getReference(item)}
      />
      <span>{`(${label})`}</span>
    </Flex>
  ) : (
    <span>{label}</span>
  );
};

interface BalanceTransactionsTableFooterProps {
  page: number;
  pageCount: number;
  onPageChange: (page: number) => void;
  hasMore: boolean;
  listSize: number;
  onListSizeChange: (newListSize: ListSize) => void;
}

export const BalanceTransactionsTableFooter: React.FC<
  BalanceTransactionsTableFooterProps
> = ({
  page,
  onPageChange,
  listSize,
  onListSizeChange,
  hasMore,
  pageCount,
}) => {
  const { t } = useTranslation();

  const onChangePage = useCallback(
    (newPage: number) => {
      onPageChange(newPage);
    },
    [onPageChange]
  );

  const onNextPage = useCallback(() => {
    onPageChange(page + 1);
  }, [onPageChange, page]);

  const onChangeListSize = useCallback(
    (newListSize: ListSize) => {
      onListSizeChange(newListSize);
      onPageChange(1);
    },
    [onListSizeChange, onPageChange]
  );

  return (
    <Flex
      gap={4}
      mt={4}
      width="100%"
      alignItems="center"
      flexFlow="row nowrap"
      justifyContent="flex-end"
    >
      <FormControl
        alignItems="center"
        display="flex"
        flexFlow="row nowrap"
        width="max-content"
      >
        <FormLabel fontWeight="normal" marginBottom="none" whiteSpace="nowrap">
          {t('common:itemsPerPage')}
        </FormLabel>
        <Box width="150px">
          <NumericSelect
            onChange={
              onChangeListSize as (newListSize: ListSize | null) => void
            }
            options={listSizeOptions}
            value={listSize}
            menuPlacement="top"
          />
        </Box>
      </FormControl>
      <Box alignSelf="flex-end">
        <Pagination
          currentPage={page}
          hasMore={hasMore}
          onChange={onChangePage}
          onNext={onNextPage}
          pageCount={pageCount}
        />
      </Box>
    </Flex>
  );
};

export const BalanceTransactionsTable: React.FC<
  BalanceTransactionsTableProps
> = ({ currency, balanceId }) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDateTime } = useDateFormatter();
  const { isFeatureEnabled } = useFeatureFlags();
  const pathParams = useOrgPathParams({ balanceId });

  const { plural, pluralLower, singularLower } = useEntityNamings(
    DataType.BalanceTransaction
  );
  const {
    isLoading,
    error,
    data: transactions = [],
    refetch,
    isRefetching,
  } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.BalanceTransaction,
      pathParams,
    })
  );

  const onRefresh = useCallback(() => {
    refetch();
  }, [refetch]);

  const [page, setPage] = useState<number>(1);
  const { listSize, onListSizeChange } = useListSizePreference(
    'balance-transactions-table'
  );

  const transactionsWithBalance = useMemo(
    () =>
      transactions
        .sort((currentTransaction, nextTransaction) =>
          getDatesSortOrder(
            currentTransaction.appliedDate,
            nextTransaction.appliedDate
          )
        )
        .reduce((acc, transaction) => {
          const previousBalance = acc[acc.length - 1]?.balance ?? 0;
          acc.push({
            ...transaction,
            balance: previousBalance + transaction.amount,
          });
          return acc;
        }, new Array<BalanceTransactionWithBalance>()),
    [transactions]
  );

  const hasMore = useMemo(
    () => transactionsWithBalance.length > page * listSize,
    [listSize, page, transactionsWithBalance]
  );

  const pageCount = useMemo(
    () => Math.ceil(transactionsWithBalance.length / listSize),
    [listSize, transactionsWithBalance]
  );

  const visibleTransactions = useMemo(
    () => transactionsWithBalance.slice((page - 1) * listSize, page * listSize),
    [transactionsWithBalance, page, listSize]
  );

  const columnDefinitions = useMemo<
    Array<DataTableColumnDefinition<BalanceTransactionWithBalance>>
  >(
    () => [
      {
        id: 'transactionDate',
        header: t('forms:labels.transactionDate'),
        accessor: (item) => toLongDateTime(item.transactionDate),
      },
      {
        id: 'appliedDate',
        header: t('forms:labels.appliedDate'),
        accessor: (item) => toLongDateTime(item.appliedDate),
      },
      {
        id: 'description',
        header: t('common:description'),
        accessor: 'description',
      },
      {
        id: 'transactionType',
        header: t('common:transactionType'),
        accessor: (item) =>
          item.transactionTypeId && (
            <ReferenceLabel
              dataType={DataType.TransactionType}
              id={item.transactionTypeId}
              accessor="name"
            />
          ),
      },
      {
        id: 'source',
        header: t('common:source'),
        accessor: (item) => <SourceLink transaction={item} />,
      },
      {
        id: 'amount',
        header: t('common:amount'),
        accessor: (item) => (
          <Box>
            {formatCurrency(item.amount, currency)}
            {/* Show the paid amount if the transaction is paid and not from a bill */}
            {item.paid &&
              item.entityType !== BalanceTransactionEntityType.Bill && (
                <Text fontSize="sm">
                  {t('common:paid')}{' '}
                  {item.currencyPaid
                    ? formatCurrency(item.paid, item.currencyPaid)
                    : formatNumber(item.paid)}
                </Text>
              )}
          </Box>
        ),
        align: 'right',
      },
      {
        id: 'balance',
        header: t('common:balance'),
        accessor: (item) => formatCurrency(item.balance, currency),
        align: 'right',
      },
    ],
    [currency, formatCurrency, t, toLongDateTime]
  );

  return (
    <Card>
      <CardActionsHeader
        width="100%"
        actions={
          <ButtonGroup>
            <Button
              size="sm"
              as={CrudCreateLink}
              addReturnPath
              dataType={DataType.BalanceTransaction}
            >
              {t('forms:buttons.createEntity', {
                entityName: singularLower,
              })}
            </Button>
            {isFeatureEnabled(Feature.BalanceSchedules) && (
              <Button
                size="sm"
                as={CrudCreateLink}
                addReturnPath
                dataType={DataType.BalanceTransactionSchedule}
              >
                {t('forms:buttons.createEntity', {
                  entityName: t(
                    'features:balanceSchedules.transactionSchedule'
                  ).toLowerCase(),
                })}
              </Button>
            )}
          </ButtonGroup>
        }
      >
        <Heading size="md">{plural}</Heading>
      </CardActionsHeader>
      <CardBody>
        <VStack spacing={4} width="100%" alignItems="stretch">
          {error && <ErrorAlert error={error} />}
          <Flex width="100%" justifyContent="flex-end">
            <IconButton
              aria-label={t('common:refresh')}
              icon={<RefreshCwIcon size={16} />}
              isDisabled={isLoading || isRefetching}
              onClick={onRefresh}
            />
          </Flex>
          <DataTable<BalanceTransactionWithBalance>
            items={visibleTransactions}
            columnDefinitions={columnDefinitions}
            idAccessor="id"
            isLoading={isLoading || isRefetching}
            emptyContent={
              <HStack justifyContent="center" alignItems="center">
                <Text>
                  {t('errors:generic.noDataToDisplay', {
                    entityName: pluralLower,
                  })}
                </Text>
              </HStack>
            }
          />
          <BalanceTransactionsTableFooter
            page={page}
            onPageChange={setPage}
            hasMore={hasMore}
            pageCount={pageCount}
            listSize={listSize}
            onListSizeChange={onListSizeChange}
          />
        </VStack>
      </CardBody>
    </Card>
  );
};
