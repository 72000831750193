import { useCallback, useMemo } from 'react';

import { useToken } from '@chakra-ui/react';

import type { Theme, StylesConfig } from 'react-select';

const useReactSelectStyles = <O>() => {
  const [borderColor, errorColor, highlightColor, hoverColor] = useToken(
    'colors',
    ['border-light', 'red-primary', 'misc-highlight', 'misc-hover-light']
  );
  const popoverZIndex = useToken('zIndices', 'popover');

  const styles = useMemo<StylesConfig<O>>(
    () => ({
      clearIndicator: (provided) => ({
        ...provided,
        cursor: 'pointer',
        color: 'black',
        '&:hover': {
          color: 'black',
        },
      }),
      control: (provided) => ({
        ...provided,
        borderColor,
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        color: 'black',
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: popoverZIndex,
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'white' : 'black',
      }),
      singleValue: (provided) => provided,
    }),
    [borderColor, popoverZIndex]
  );

  const theme = useCallback(
    (provided: Theme) => ({
      ...provided,
      color: 'black',
      borderRadius: 6,
      spacing: {
        ...provided.spacing,
        controlHeight: 40,
      },
      colors: {
        ...provided.colors,
        primary: highlightColor,
        danger: errorColor,
        neutral20: borderColor,
        neutral30: hoverColor,
      },
    }),
    [borderColor, errorColor, highlightColor, hoverColor]
  );

  return {
    styles,
    theme,
  };
};

export default useReactSelectStyles;
