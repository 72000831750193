import { ReactElement, ReactNode } from 'react';

import { chakra, useDisclosure, useMultiStyleConfig } from '@chakra-ui/react';

import type { ColumnDefinition, DataGridTranslations } from './types';

import { SelectionControlProps, SelectionType } from '../../../types/tables';
import { Accessor, getAccessorValue } from '../../../utils/data';
import { SelectionControl } from '../../controls/SelectionControl/SelectionControl';
import { IconButton } from '../../controls/Button/IconButton';
import { CaretDownIcon } from '../../icons/CaretDownIcon';
import { CaretRightIcon } from '../../icons/CaretRightIcon';

import { getColumnJustify } from './utils';
import { DataGridCell } from './DataGridCell';

export interface DataGridRowProps<D> {
  item: D;
  columnDefinitions: Array<ColumnDefinition<D>>;
  selectionType?: SelectionType;
  selectionControlProps?: SelectionControlProps;
  detailsContent?: Accessor<D, ReactNode>;
  translations: DataGridTranslations;
}

export function DataGridRow<D>({
  columnDefinitions,
  item,
  selectionType,
  selectionControlProps,
  detailsContent,
  translations,
}: DataGridRowProps<D>): ReactElement<any, any> {
  const { isOpen, onToggle } = useDisclosure();

  const styles = useMultiStyleConfig('DataGrid', {});

  return (
    <chakra.div __css={styles.row} role="row">
      {selectionType !== undefined && selectionControlProps !== undefined && (
        <DataGridCell isPinnedFirst>
          <SelectionControl
            selectionType={selectionType}
            {...selectionControlProps}
          />
        </DataGridCell>
      )}
      {detailsContent !== undefined && (
        <DataGridCell gridRow={`span ${isOpen ? 2 : 1}`} alignItems="start">
          <IconButton
            aria-label={isOpen ? translations.collapse : translations.expand}
            icon={isOpen ? <CaretDownIcon /> : <CaretRightIcon />}
            size="xs"
            onClick={onToggle}
          />
        </DataGridCell>
      )}
      {columnDefinitions.map((columnDefinition) => (
        <DataGridCell
          key={columnDefinition.id}
          isPinnedFirst={columnDefinition.isPinnedFirst}
          isPinnedLast={columnDefinition.isPinnedLast}
          justifyContent={getColumnJustify(columnDefinition)}
        >
          {getAccessorValue(item, columnDefinition.accessor)}
        </DataGridCell>
      ))}
      {/* Extra cell to take up any remaining space. */}
      <DataGridCell p={0} border={0} />
      {detailsContent !== undefined && isOpen && (
        <DataGridCell __css={styles.details}>
          {getAccessorValue(item, detailsContent)}
        </DataGridCell>
      )}
    </chakra.div>
  );
}
