import type {
  ColumnOption,
  ColumnOptionWithVisibility,
  ColumnDisplay,
} from '../../../types/tables';

export const getSortedColumnOptionsWithVisibility = (
  columnOptions: Array<ColumnOption>,
  columnDisplay: Array<ColumnDisplay>
): Array<ColumnOptionWithVisibility> => {
  return columnDisplay
    .map(({ id, visible }) => {
      const column = columnOptions.find((option) => option.id === id);
      return column ? { ...column, visible } : undefined;
    })
    .filter(Boolean) as Array<ColumnOptionWithVisibility>;
};
