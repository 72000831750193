import { useMemo } from 'react';

import type {
  ColumnDefinition,
  ColumnDisplay,
  ColumnOption,
} from '../types/tables';

const useColumnDisplay = <CD extends ColumnDefinition<any>>(
  columnDefinitions: Array<CD>,
  columnDisplay: Array<ColumnDisplay> = []
) => {
  // Add any missing column definitions to the column display
  // setting, based on column order and `defaultVisibility`.
  // This allows the setting to be a partial of the columns
  // or not be passed at all.
  const updatedColumnDisplay = useMemo(() => {
    const updated = [...columnDisplay];

    columnDefinitions.forEach((columnDefinition) => {
      if (!updated.some((display) => display.id === columnDefinition.id)) {
        updated.push({
          id: columnDefinition.id,
          visible: columnDefinition.defaultVisible ?? true,
        });
      }
    });

    return updated;
  }, [columnDisplay, columnDefinitions]);

  const columnOptions = useMemo(
    () =>
      columnDefinitions.map(
        ({ id, header, alwaysVisible }): ColumnOption => ({
          id,
          header,
          alwaysVisible,
        })
      ),
    [columnDefinitions]
  );

  const visibleColumns = useMemo(() => {
    return updatedColumnDisplay
      .filter((display) => display.visible)
      .map((display) =>
        columnDefinitions.find((column) => column.id === display.id)
      )
      .filter(Boolean) as Array<CD>;
  }, [updatedColumnDisplay, columnDefinitions]);

  return { updatedColumnDisplay, columnOptions, visibleColumns };
};

export default useColumnDisplay;
