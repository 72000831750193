import { SearchComparator, SearchCriterion } from '../types';

const SEARCH_QUERY_DELIMITER = '$';
const DEFAULT_SEARCH = 'id~*';

// Matches the field, valid comparators (order matters) and value.
const QUERY_PART_REGEX = /^(\w+)(:|!:|<=|<|>=|>|~)(.+)$/;

export const buildSearchQuery = (criteria: Array<SearchCriterion>) =>
  criteria.length === 0
    ? DEFAULT_SEARCH
    : criteria
        .map(({ field, comparator, value }) => `${field}${comparator}${value}`)
        .join(SEARCH_QUERY_DELIMITER);

export const parseSearchQuery = (query: string): Array<SearchCriterion> => {
  const criteria: Array<SearchCriterion> = [];

  const parts = query.split(SEARCH_QUERY_DELIMITER);

  parts.forEach((part) => {
    const match = part.match(QUERY_PART_REGEX);
    if (match) {
      const [_, field, comparator, value] = match;
      if (
        Object.values(SearchComparator).includes(comparator as SearchComparator)
      ) {
        criteria.push({
          field,
          comparator: comparator as SearchComparator,
          value,
        });
      }
    }
  });

  return criteria;
};
