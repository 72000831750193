import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  create,
  PathParams,
  DataType,
  DataTypeToEntity,
  UnsavedEntity,
} from '@m3ter-com/m3ter-api';

import { extractErrorMessage } from '@/util/error';
import { dataCreated } from '@/store/data/data';
import useNotification, {
  NotificationOptions,
} from '@/hooks/util/useNotification';
import useOrg from '@/hooks/data/crud/useOrg';

interface CreateMutationData<DT extends DataType> {
  actionName?: string;
  failureNotification?: NotificationOptions;
  newEntity: UnsavedEntity<DataTypeToEntity[DT]>;
  pathParams?: PathParams;
  returnPath?: string;
  successNotification?: NotificationOptions;
}

const useEntityCreateMutation = <DT extends DataType>(dataType: DT) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { toast } = useNotification();
  const { currentOrgId: organizationId } = useOrg();

  const queryClient = useQueryClient();

  const {
    error,
    isPending: isSaving,
    mutate: createEntity,
  } = useMutation({
    mutationFn: async (createData: CreateMutationData<DT>) => {
      const pathParams = {
        organizationId,
        ...createData.pathParams,
      };
      return create({
        actionName: createData.actionName,
        data: createData.newEntity,
        dataType,
        pathParams,
      });
    },
    onError: (err, updateData) => {
      const { failureNotification } = updateData;
      if (failureNotification) {
        // Replace (optional) placeholder with the error message.
        toast({
          ...failureNotification,
          description: failureNotification.description.replace(
            '{error}',
            extractErrorMessage(err)
          ),
        });
      }
    },
    onSuccess: (createdEntity, createData) => {
      dispatch(dataCreated(dataType, [createdEntity]));
      queryClient.invalidateQueries({ queryKey: [dataType] });
      if (createData.successNotification) {
        toast(createData.successNotification);
      }
      if (createData.returnPath) {
        navigate(createData.returnPath);
      }
    },
  });

  return {
    createEntity,
    error,
    isSaving,
  };
};

export default useEntityCreateMutation;
