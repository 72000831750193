import { useMemo } from 'react';

import { BalanceChargeSchedule } from '@m3ter-com/m3ter-api';

import { CrudRetrieve } from '@/components/common/crud/CrudRetrieve/CrudRetrieve';
import { useCrudRetrieveContext } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';

export const BalanceChargeScheduleRetrieveRoute: React.FC = () => {
  const { balance } = useCrudRetrieveContext();
  const pathParams = useMemo(() => ({ balanceId: balance?.id }), [balance?.id]);

  return (
    <CrudRetrieve<BalanceChargeSchedule>
      pathParams={pathParams}
      titleAccessor="name"
    />
  );
};
