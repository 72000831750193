import { Fragment } from 'react';

import { Outlet, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { DataType } from '@m3ter-com/m3ter-api';
import { AppLayoutMain, LoadingScreen } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { dataTypeRetrieveQuery } from '@/queries/crud';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';

export const ProductsRetrieveRoute: React.FC = () => {
  const { t } = useTranslation();
  const { productId } = useParams();
  const { singularLower } = useEntityNamings(DataType.Product);

  const pathParams = useOrgPathParams();
  const {
    isLoading,
    data: product,
    error,
  } = useQuery(
    dataTypeRetrieveQuery({
      dataType: DataType.Product,
      id: productId,
      pathParams,
    })
  );

  if (error) {
    return (
      <AppLayoutMain>
        <ErrorAlert
          title={t('errors:generic.problemLoadingData', {
            entityName: singularLower,
          })}
          error={error}
        />
      </AppLayoutMain>
    );
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return product ? (
    <Fragment>
      <BreadcrumbItem title={product.name} />
      <Outlet />
    </Fragment>
  ) : null;
};
