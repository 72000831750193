import React, { ReactElement } from 'react';

import { Trans } from 'react-i18next';
import { TFunction } from 'i18next';

import { DataType } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, AlertLink } from '@m3ter-com/ui-components';

import useEntityNamings from '@/hooks/util/useEntityNamings';
import {
  FormEntitySelect,
  FormEntitySelectProps,
} from '@/components/forms/FormEntitySelect/FormEntitySelect';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import { CrudListLink } from '@/components/common/navigation/CrudListLink/CrudListLink';

export type PickListDataType =
  | DataType.Currency
  | DataType.CreditReason
  | DataType.DebitReason
  | DataType.TransactionType;

export interface FormPicklistSelectProps<DT extends PickListDataType>
  extends Omit<
    FormEntitySelectProps<DT>,
    'isClearable' | 'accessor' | 'optionValueAccessor' | 'params'
  > {
  isRequired?: boolean;
  useCodeAsValue?: boolean;
}

const params = {
  archived: false,
};

export function FormPicklistSelect<DT extends PickListDataType>({
  isRequired = false,
  useCodeAsValue = false,
  ...entitySelectProps
}: FormPicklistSelectProps<DT>): ReactElement<any, any> {
  const { t } = useTranslation();
  const { dataType, emptyContent } = entitySelectProps;

  const entityNamings = useEntityNamings(dataType);

  return (
    <FormEntitySelect<DT>
      {...entitySelectProps}
      emptyContent={
        isRequired && !emptyContent ? (
          <Alert status="info">
            <Trans
              t={t as TFunction}
              i18nKey="features:picklists.noEntityForSelection"
              values={{
                entityName: entityNamings.singularLower,
                entities: entityNamings.pluralLower,
              }}
              components={{
                archivedEntitiesLink: (
                  <AlertLink as={CrudListLink} dataType={dataType} />
                ),
                createEntityLink: (
                  <AlertLink
                    as={CrudCreateLink}
                    addReturnPath
                    dataType={dataType}
                  />
                ),
              }}
            />
          </Alert>
        ) : (
          emptyContent
        )
      }
      isRequired={isRequired}
      isClearable={!isRequired}
      accessor="name"
      detailAccessor="code"
      optionValueAccessor={useCodeAsValue ? 'code' : 'id'}
      params={params}
    />
  );
}
