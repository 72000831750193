import { PropsWithChildren, useMemo } from 'react';

import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

import {
  DataType,
  IntegrationConfigDestinationSchema,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Button } from '@m3ter-com/ui-components';

import { getAvailableIntegrationEntitiesByDestination } from '@/util/integrations';
import useFeatureFlags, { Feature } from '@/hooks/util/useFeatureFlags';
import { useIntegrationSystemsContext } from '@/components/features/integrations/IntegrationSystemsContext/IntegrationSystemsContext';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';

export interface IntegrationSystemCreateConfigMenuProps {
  systemSchema: IntegrationConfigDestinationSchema;
}

export const IntegrationSystemCreateConfigMenu: React.FC<
  PropsWithChildren<IntegrationSystemCreateConfigMenuProps>
> = ({ systemSchema }) => {
  const { t } = useTranslation();

  const { isFeatureEnabled } = useFeatureFlags();

  const { configSchema } = useIntegrationSystemsContext();
  const availableIntegrationEntities = useMemo(
    () =>
      getAvailableIntegrationEntitiesByDestination(
        configSchema,
        systemSchema.apiName
      ).filter((entity) =>
        // Temporarily hiding the transformations-based netsuite integration behind the transformations
        // feature flag because the integrations schema API is unable to mark integrations as enabled/disabled
        // on a per-org basis
        systemSchema.destination.toLowerCase() === 'netsuite' &&
        entity.type.toLowerCase() === 'notification'
          ? isFeatureEnabled(Feature.Transformations)
          : true
      ),
    [configSchema, isFeatureEnabled, systemSchema]
  );

  if (availableIntegrationEntities.length === 0) {
    return null;
  }

  return (
    <Menu>
      <MenuButton as={Button} size="sm">
        {t('features:integrations.configureNewIntegration')}
      </MenuButton>
      <MenuList>
        {availableIntegrationEntities.map((entity) => (
          <MenuItem
            key={entity.type}
            fontSize="sm"
            as={CrudCreateLink}
            addReturnPath
            dataType={DataType.Integration}
            queryParams={{
              destination: systemSchema.destination,
              entityType: entity.type,
            }}
          >
            {t('features:integrations.configureEntityIntegration', {
              entityType: (
                entity.schema.readableName || entity.type
              ).toLowerCase(),
            })}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
