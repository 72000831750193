import { DataType } from '../../types';
import { getOrganizationPath } from '../../util/path';

import { ActionType, HttpMethod } from './types';

interface Action {
  method: HttpMethod;
  path: string;
  type: ActionType;
}

type Actions = Record<string, Action>;

interface ActionConfig {
  path: string;
  actions: Actions;
}

const immutableDataActions: Actions = {
  list: {
    method: HttpMethod.Get,
    path: '',
    type: ActionType.List,
  },
  retrieve: {
    method: HttpMethod.Get,
    path: '/:id',
    type: ActionType.Retrieve,
  },
  create: {
    method: HttpMethod.Post,
    path: '',
    type: ActionType.Create,
  },
};

const commonSingletonActions: Actions = {
  retrieve: {
    method: HttpMethod.Get,
    path: '',
    type: ActionType.Retrieve,
  },
  update: {
    method: HttpMethod.Put,
    path: '',
    type: ActionType.Update,
  },
};

const commonDataActions: Actions = {
  ...immutableDataActions,
  update: {
    method: HttpMethod.Put,
    path: '/:id',
    type: ActionType.Update,
  },
  delete: {
    method: HttpMethod.Delete,
    path: '/:id',
    type: ActionType.Delete,
  },
};

const commonDataActionsWithSearch: Actions = {
  ...commonDataActions,
  search: {
    method: HttpMethod.Get,
    path: '/search',
    type: ActionType.Search,
  },
};

export const dataTypeActions: Record<DataType, ActionConfig> = {
  [DataType.Account]: {
    path: getOrganizationPath('/accounts'),
    actions: {
      ...commonDataActionsWithSearch,
      listChildren: {
        method: HttpMethod.Get,
        path: '/:accountId/children',
        type: ActionType.List,
      },
    },
  },
  [DataType.AccountPlan]: {
    path: getOrganizationPath('/accountplans'),
    actions: commonDataActions,
  },
  [DataType.Aggregation]: {
    path: getOrganizationPath('/aggregations'),
    actions: commonDataActions,
  },
  [DataType.Alert]: {
    path: getOrganizationPath('/alerts'),
    actions: commonDataActions,
  },
  [DataType.AnalyticsJob]: {
    path: getOrganizationPath('/analytics/jobs'),
    actions: commonDataActions,
  },
  [DataType.Balance]: {
    path: getOrganizationPath('/balances'),
    actions: commonDataActions,
  },
  [DataType.BalanceCharge]: {
    path: getOrganizationPath('/charges'),
    actions: commonDataActions,
  },
  [DataType.BalanceTransactionSchedule]: {
    path: getOrganizationPath(
      '/balances/:balanceId/scheduledbalancetransactions'
    ),
    actions: commonDataActions,
  },
  [DataType.BalanceTransaction]: {
    path: getOrganizationPath('/balances/:balanceId/transactions'),
    actions: {
      create: {
        method: HttpMethod.Post,
        path: '',
        type: ActionType.Create,
      },
      list: {
        method: HttpMethod.Get,
        path: '',
        type: ActionType.List,
      },
    },
  },
  [DataType.BalanceChargeSchedule]: {
    path: getOrganizationPath('/balances/:balanceId/scheduledbalancecharges'),
    actions: commonDataActions,
  },
  [DataType.Bill]: {
    path: getOrganizationPath('/bills'),
    actions: {
      ...commonDataActionsWithSearch,
      getAllBillsForAccount: {
        method: HttpMethod.Get,
        path: '/accountid/:accountId',
        type: ActionType.List,
      },
      getAllBillsInPeriod: {
        method: HttpMethod.Get,
        path: '/billingperiod/:lastDateInBillingPeriod/:billingFrequency',
        type: ActionType.List,
      },
      updateBillStatus: {
        method: HttpMethod.Put,
        path: '/:id/status',
        type: ActionType.Update,
      },
      lockBill: {
        method: HttpMethod.Put,
        path: '/:id/lock',
        type: ActionType.Update,
      },
    },
  },
  [DataType.BillConfig]: {
    path: getOrganizationPath('/billconfig'),
    actions: commonSingletonActions,
  },
  [DataType.BillJob]: {
    path: getOrganizationPath('/billjobs'),
    actions: {
      ...immutableDataActions,
      generateBills: {
        method: HttpMethod.Post,
        path: '',
        type: ActionType.Create,
      },
      recalculateBills: {
        method: HttpMethod.Post,
        path: '/recalculate',
        type: ActionType.Create,
      },
      cancelBillJob: {
        method: HttpMethod.Post,
        path: '/:id/cancel',
        type: ActionType.Update,
      },
    },
  },
  [DataType.BillLineItem]: {
    path: getOrganizationPath('/lineitems'),
    actions: {
      list: {
        method: HttpMethod.Get,
        path: '',
        type: ActionType.List,
      },
    },
  },
  [DataType.Charge]: {
    path: getOrganizationPath('/charges'),
    actions: commonDataActions,
  },
  [DataType.Commitment]: {
    path: getOrganizationPath('/commitments'),
    actions: commonDataActions,
  },
  [DataType.CompoundAggregation]: {
    path: getOrganizationPath('/compoundaggregations'),
    actions: commonDataActions,
  },
  [DataType.Contract]: {
    path: getOrganizationPath('/contracts'),
    actions: commonDataActions,
  },
  [DataType.Counter]: {
    path: getOrganizationPath('/counters'),
    actions: commonDataActions,
  },
  [DataType.CounterAdjustment]: {
    path: getOrganizationPath('/counteradjustments'),
    actions: commonDataActions,
  },
  [DataType.CounterPricing]: {
    path: getOrganizationPath('/counterpricings'),
    actions: commonDataActions,
  },
  [DataType.CreditLineItem]: {
    path: getOrganizationPath('/bills/:billId/creditlineitems'),
    actions: commonDataActions,
  },
  [DataType.CreditReason]: {
    path: getOrganizationPath('/picklists/creditreasons'),
    actions: commonDataActions,
  },
  [DataType.Currency]: {
    path: getOrganizationPath('/picklists/currency'),
    actions: commonDataActions,
  },
  [DataType.CurrentUser]: {
    path: '/user',
    actions: {
      ...commonSingletonActions,
      acceptTerms: {
        method: HttpMethod.Put,
        path: '/accept-terms-and-conditions',
        type: ActionType.Update,
      },
    },
  },
  [DataType.Customer]: {
    path: '/customers',
    actions: commonDataActions,
  },
  [DataType.DataExplorerSavedQuery]: {
    path: getOrganizationPath('/dataexplorer/selections'),
    actions: commonDataActions,
  },
  [DataType.DebitLineItem]: {
    path: getOrganizationPath('/bills/:billId/debitlineitems'),
    actions: commonDataActions,
  },
  [DataType.DebitReason]: {
    path: getOrganizationPath('/picklists/debitreasons'),
    actions: commonDataActions,
  },
  [DataType.ExportDestination]: {
    path: getOrganizationPath('/dataexports/destinations'),
    actions: commonDataActions,
  },
  [DataType.ExportJob]: {
    path: getOrganizationPath('/dataexports/jobs'),
    actions: {
      list: {
        method: HttpMethod.Get,
        path: '',
        type: ActionType.List,
      },
      retrieve: {
        method: HttpMethod.Get,
        path: '/:id',
        type: ActionType.Retrieve,
      },
    },
  },
  [DataType.ExportSchedule]: {
    path: getOrganizationPath('/dataexports/schedules'),
    actions: commonDataActions,
  },
  [DataType.ExternalMapping]: {
    path: getOrganizationPath('/externalmappings'),
    actions: {
      ...commonDataActions,
      getEntityMappings: {
        method: HttpMethod.Get,
        path: `/external/:entityType/:entityId`,
        type: ActionType.List,
      },
    },
  },
  [DataType.ExternalMappingConfig]: {
    path: getOrganizationPath('/externalmappingconfiguration'),
    actions: commonSingletonActions,
  },
  [DataType.IdentityProvider]: {
    path: '/identityproviders/saml',
    actions: commonDataActions,
  },
  [DataType.Integration]: {
    path: getOrganizationPath('/integrationconfigs'),
    actions: {
      ...commonDataActions,
      getEntityIntegrations: {
        method: HttpMethod.Get,
        path: '/entity/:entityType',
        type: ActionType.List,
      },
      reenableIntegration: {
        method: HttpMethod.Post,
        path: '/:id/enable',
        type: ActionType.Update,
      },
    },
  },
  [DataType.IntegrationCredential]: {
    path: getOrganizationPath('/integrationcredentials'),
    actions: commonDataActions,
  },
  [DataType.IntegrationRun]: {
    path: getOrganizationPath('/integrationruns'),
    actions: {
      retrieve: commonDataActions.retrieve,
      getEntityRuns: {
        method: HttpMethod.Get,
        path: '/:entityType/:entityId',
        type: ActionType.Retrieve,
      },
      getLatestEntityRun: {
        method: HttpMethod.Get,
        path: '/:entityType/latest/:entityId',
        type: ActionType.Retrieve,
      },
    },
  },
  [DataType.IntegrationTransformation]: {
    path: getOrganizationPath('/integrationtransformations'),
    actions: commonDataActions,
  },
  [DataType.IntegrationWorkflow]: {
    path: getOrganizationPath('/integrationdestinations/workflows'),
    actions: commonDataActions,
  },
  [DataType.Invitation]: {
    path: getOrganizationPath('/invitations'),
    actions: immutableDataActions,
  },
  [DataType.M3terEvent]: {
    path: getOrganizationPath('/events'),
    actions: commonDataActions,
  },
  [DataType.MeasurmentsDeletion]: {
    path: getOrganizationPath('/measurementsDeletions'),
    actions: immutableDataActions,
  },
  [DataType.Meter]: {
    path: getOrganizationPath('/meters'),
    actions: commonDataActions,
  },
  [DataType.NotificationRule]: {
    path: getOrganizationPath('/notifications/configurations'),
    actions: commonDataActions,
  },
  [DataType.Organization]: {
    path: '/user/organizations',
    actions: commonDataActions,
  },
  [DataType.OrganizationAdmin]: {
    path: '/management/organizations',
    actions: commonDataActions,
  },
  [DataType.OrganizationConfig]: {
    path: getOrganizationPath('/organizationconfig'),
    actions: commonSingletonActions,
  },
  [DataType.OrganizationCustomFields]: {
    path: getOrganizationPath('/customfields'),
    actions: commonSingletonActions,
  },
  [DataType.OutboundWebhook]: {
    path: getOrganizationPath('/integrationdestinations/webhooks'),
    actions: commonDataActions,
  },
  [DataType.PermissionPolicy]: {
    path: getOrganizationPath(),
    actions: {
      create: {
        method: HttpMethod.Post,
        path: '/permissionpolicies',
        type: ActionType.Create,
      },
      delete: {
        method: HttpMethod.Delete,
        path: '/permissionpolicies/:id',
        type: ActionType.Delete,
      },
      list: {
        method: HttpMethod.Get,
        path: '/permissionpolicies',
        type: ActionType.List,
      },
      retrieve: {
        method: HttpMethod.Get,
        path: '/permissionpolicies/:id',
        type: ActionType.Retrieve,
      },
      update: {
        method: HttpMethod.Put,
        path: '/permissionpolicies/:id',
        type: ActionType.Update,
      },
      listServiceUserPermissions: {
        method: HttpMethod.Get,
        path: '/serviceusers/:id/permissions',
        type: ActionType.List,
      },
      listSupportAccessPermissions: {
        method: HttpMethod.Get,
        path: '/support-permissions',
        type: ActionType.List,
      },
      listUserPermissions: {
        method: HttpMethod.Get,
        path: '/users/:id/permissions',
        type: ActionType.List,
      },
      listUserGroupPermissions: {
        method: HttpMethod.Get,
        path: '/resourcegroups/user/:id/permissions',
        type: ActionType.List,
      },
    },
  },
  [DataType.Plan]: {
    path: getOrganizationPath('/plans'),
    actions: commonDataActions,
  },
  [DataType.PlanGroup]: {
    path: getOrganizationPath('/plangroups'),
    actions: commonDataActions,
  },
  [DataType.PlanGroupLink]: {
    path: getOrganizationPath('/plangrouplinks'),
    actions: commonDataActions,
  },
  [DataType.PlanTemplate]: {
    path: getOrganizationPath('/plantemplates'),
    actions: commonDataActions,
  },
  [DataType.Pricing]: {
    path: getOrganizationPath('/pricings'),
    actions: commonDataActions,
  },
  [DataType.Product]: {
    path: getOrganizationPath('/products'),
    actions: commonDataActions,
  },
  [DataType.ServiceUser]: {
    path: getOrganizationPath('/serviceusers'),
    actions: commonDataActions,
  },
  [DataType.StatementDefinition]: {
    path: getOrganizationPath('/statementdefinitions'),
    actions: commonDataActions,
  },
  [DataType.StatementJob]: {
    path: getOrganizationPath('/statementjobs'),
    actions: {
      ...immutableDataActions,
      cancelStatementJob: {
        method: HttpMethod.Post,
        path: '/:id/cancel',
        type: ActionType.Update,
      },
    },
  },
  [DataType.SupportAccess]: {
    path: getOrganizationPath('/support'),
    actions: commonSingletonActions,
  },
  [DataType.SupportOrganization]: {
    path: '',
    actions: {
      list: {
        method: HttpMethod.Get,
        path: '/support',
        type: ActionType.List,
      },
    },
  },
  [DataType.TransactionType]: {
    path: getOrganizationPath('/picklists/transactiontypes'),
    actions: commonDataActions,
  },
  [DataType.UserAdmin]: {
    path: '/management/users',
    actions: {
      ...immutableDataActions,
      update: {
        method: HttpMethod.Put,
        path: '/:id',
        type: ActionType.Update,
      },
    },
  },
  [DataType.UploadJob]: {
    path: getOrganizationPath('/fileuploads/measurements/jobs'),
    actions: immutableDataActions,
  },
  [DataType.UsageSavedQuery]: {
    path: getOrganizationPath('/usage/savedqueries'),
    actions: commonDataActions,
  },
  [DataType.User]: {
    path: getOrganizationPath('/users'),
    actions: commonDataActions,
  },
  [DataType.UserGroup]: {
    path: getOrganizationPath(),
    actions: {
      create: {
        method: HttpMethod.Post,
        path: '/resourcegroups/user',
        type: ActionType.Create,
      },
      delete: {
        method: HttpMethod.Delete,
        path: '/resourcegroups/user/:id',
        type: ActionType.Delete,
      },
      list: {
        method: HttpMethod.Get,
        path: '/resourcegroups/user',
        type: ActionType.List,
      },
      retrieve: {
        method: HttpMethod.Get,
        path: '/resourcegroups/user/:id',
        type: ActionType.Retrieve,
      },
      update: {
        method: HttpMethod.Put,
        path: '/resourcegroups/user/:id',
        type: ActionType.Update,
      },
      listByUserId: {
        method: HttpMethod.Get,
        path: '/users/:id/usergroups',
        type: ActionType.List,
      },
      addUserToUserGroup: {
        method: HttpMethod.Post,
        path: '/resourcegroups/user/:id/addresource',
        type: ActionType.Create,
      },
      removeUserFromUserGroup: {
        method: HttpMethod.Post,
        path: '/resourcegroups/user/:id/removeresource',
        type: ActionType.Update,
      },
    },
  },
};
