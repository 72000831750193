import i18next from 'i18next';

import { SearchComparator } from '@m3ter-com/m3ter-api';
import { SelectOption } from '@m3ter-com/ui-components';

import { SearchFieldType } from '@/types/search';

const validComparators: Record<SearchFieldType, Array<SearchComparator>> = {
  [SearchFieldType.Id]: [SearchComparator.Equal, SearchComparator.NotEqual],
  [SearchFieldType.Number]: [
    SearchComparator.Equal,
    SearchComparator.NotEqual,
    SearchComparator.GreaterThan,
    SearchComparator.GreaterThanOrEqual,
    SearchComparator.LessThan,
    SearchComparator.LessThanOrEqual,
  ],
  [SearchFieldType.String]: [
    SearchComparator.Contains,
    SearchComparator.Equal,
    SearchComparator.NotEqual,
  ],
};

const comparatorTranslations: Record<SearchComparator, string> = {
  [SearchComparator.Contains]: 'common:searchComparator.contains',
  [SearchComparator.Equal]: 'common:searchComparator.equal',
  [SearchComparator.NotEqual]: 'common:searchComparator.notEqual',
  [SearchComparator.GreaterThan]: 'common:searchComparator.greaterThan',
  [SearchComparator.GreaterThanOrEqual]:
    'common:searchComparator.greaterThanOrEqual',
  [SearchComparator.LessThan]: 'common:searchComparator.lessThan',
  [SearchComparator.LessThanOrEqual]: 'common:searchComparator.lessThanOrEqual',
};

export const getValidComparators = (type: SearchFieldType) =>
  validComparators[type];

export const getComparatorSelectOptions = (
  type: SearchFieldType
): Array<SelectOption<SearchComparator>> =>
  getValidComparators(type).map((c) => ({
    value: c,
    label: i18next.t(comparatorTranslations[c]),
    secondaryLabel: c,
  }));
