export type Accessor<D, ReturnType = any> = keyof D | ((item: D) => ReturnType);

export const getAccessorValue = <D, ReturnType = any>(
  data: D,
  accessor: Accessor<D, ReturnType>
): ReturnType => {
  return typeof accessor === 'function'
    ? accessor(data)
    : (data[accessor] as ReturnType);
};
