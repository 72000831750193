import React, { ReactNode, ReactElement, PropsWithChildren } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Icon, Spinner, Tooltip } from '@chakra-ui/react';
import { TriangleAlert } from 'lucide-react';

import { DataType, DataTypeToEntity } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { Accessor, getAccessorValue } from '@/util/data';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useEntityNamings from '@/hooks/util/useEntityNamings';

export interface ReferenceLabelProps<DT extends DataType> {
  dataType: DT;
  id: string;
  accessor: Accessor<DataTypeToEntity[DT], ReactNode>;
}

export function ReferenceLabel<DT extends DataType>({
  dataType,
  id,
  accessor,
}: PropsWithChildren<ReferenceLabelProps<DT>>): ReactElement<any, any> | null {
  const { t } = useTranslation();
  const pathParams = useOrgPathParams();
  const { singularLower: entityName } = useEntityNamings(dataType);
  const { isLoading, error, data } = useQuery(
    dataTypeRetrieveQuery({
      dataType,
      id,
      pathParams,
    })
  );

  if (isLoading) {
    return (
      <Spinner
        data-testid="reference-label-spinner"
        color="chakra-body-text"
        size="sm"
      />
    );
  }

  if (error) {
    return (
      <Tooltip
        hasArrow
        label={t('errors:generic.problemLoadingData', { entityName })}
      >
        <span>
          <Icon
            color="red"
            data-testid="reference-label-error-tooltip"
            as={TriangleAlert}
          />
        </span>
      </Tooltip>
    );
  }

  if (!data) return null;

  return (
    <span data-testid="reference-label-accessor">
      {getAccessorValue(data, accessor)}
    </span>
  );
}
