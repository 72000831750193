import { DateTimeISOString, ExportAdhoc, Id, UnsavedEntity } from '../types';

import { getOrganizationPath } from '../util/path';
import { get, post } from '../client';

interface DownloadResponse {
  url: string;
  expirationTime: DateTimeISOString;
}

export const createAdhocDataExport = (
  organizationId: Id,
  body: UnsavedEntity<ExportAdhoc>
) =>
  post({
    body,
    path: getOrganizationPath('/dataexports/adhoc'),
    pathParams: {
      organizationId,
    },
  }) as Promise<ExportAdhoc>;

export const getExportJobDownloadUrl = async (
  organizationId: Id,
  exportJobId: Id
) => {
  const response = (await get({
    path: getOrganizationPath('/dataexports/jobs/:exportJobId/getdownloadurl'),
    pathParams: { organizationId, exportJobId },
  })) as DownloadResponse;
  return response.url;
};
