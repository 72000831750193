import React, { useMemo } from 'react';

import { Card, CardBody, Heading, Link, Text } from '@chakra-ui/react';

import {
  Account,
  Charge,
  ChargeEntityType,
  DataType,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { Alert, Button, CardActionsHeader } from '@m3ter-com/ui-components';
import { EntityWithRelationships } from '@m3ter-com/console-core/types';

import { OtherListIds } from '@/types/lists';

import {
  ColumnDefinition,
  CrudList,
  CrudListFooter,
  CrudListHeader,
  CrudListTable,
} from '@/components/common/crud/CrudList';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import useEntityDelete from '@/hooks/data/crud/useEntityDelete';
import usePreference from '@/hooks/data/usePreference';
import useCrudRouteNames from '@/hooks/navigation/useCrudRouteNames';
import useCurrencies from '@/hooks/util/useCurrencies';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import { getReference } from '@/util/billing';

const relationships = ['bill'];

export interface ChargesListProps {
  account: Account;
}

const ChargesPreviewBanner: React.FC = () => {
  const { t } = useTranslation();
  const [dismissed, setDismissed] = usePreference(
    'charges-banner-dismissed',
    false
  );

  const alertButtonProps = useMemo(
    () => ({
      size: 'sm',
      onClick: () => setDismissed(true),
    }),
    [setDismissed]
  );

  return !dismissed ? (
    <Alert
      mb={4}
      status="info"
      alertTitle={t('features:charges.previewBannerTitle')}
      actionButtonText={t('common:close')}
      actionButtonProps={alertButtonProps}
    >
      <Text>{t('features:charges.previewBannerDescription')}</Text>
    </Alert>
  ) : null;
};

export const ChargesList: React.FC<ChargesListProps> = ({ account }) => {
  const { t } = useTranslation();
  const { toLongDate, toLongDateTime } = useDateFormatter();
  const { editRouteName } = useCrudRouteNames(DataType.Charge);
  const { formatCurrency } = useCurrencies();
  const { deleteItem } = useEntityDelete(DataType.Charge);
  const entityNamings = useEntityNamings(DataType.Charge);

  const columns = useMemo<
    Array<ColumnDefinition<EntityWithRelationships<Charge>>>
  >(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (item) => (
          <Link as={CrudDetailsLink} dataType={DataType.Charge} id={item.id}>
            {item.name}
          </Link>
        ),
      },
      {
        id: 'servicePeriodStartDate',
        header: t('forms:labels.servicePeriodStart'),
        accessor: (item) => toLongDateTime(item.servicePeriodStartDate),
      },
      {
        id: 'servicePeriodEndDate',
        header: t('forms:labels.servicePeriodEnd'),
        accessor: (item) => toLongDateTime(item.servicePeriodEndDate),
      },
      {
        id: 'billDate',
        header: t('features:billing.billDate'),
        accessor: (item) => item.billDate && toLongDate(item.billDate),
      },
      {
        id: 'bill',
        header: t('common:bill'),
        accessor: (item) =>
          item.billId &&
          item.bill && (
            <Link
              as={CrudDetailsLink}
              dataType={DataType.Bill}
              id={item.billId}
            >
              {getReference(item.bill)}
            </Link>
          ),
      },
      {
        id: 'amount',
        header: t('forms:labels.amount'),
        accessor: (item) => formatCurrency(item.amount, item.currency),
      },
      {
        id: 'actions',
        header: '',
        accessor: (item) => (
          <EntityCrudActions<Charge>
            addReturnPath
            dataType={DataType.Charge}
            item={item}
            editRouteName={editRouteName}
            onDelete={deleteItem}
          />
        ),
      },
    ],
    [deleteItem, editRouteName, formatCurrency, t, toLongDate, toLongDateTime]
  );

  const queryParams = useMemo(
    () => ({ accountId: account.id, entityType: ChargeEntityType.AdHoc }),
    [account]
  );

  return (
    <React.Fragment>
      <ChargesPreviewBanner />
      <Card>
        <CardActionsHeader
          actions={
            <Button
              size="sm"
              addReturnPath
              as={CrudCreateLink}
              dataType={DataType.Charge}
            >
              {t('forms:buttons.createEntity', {
                entityName: entityNamings.singularLower,
              })}
            </Button>
          }
        >
          <Heading size="md">{entityNamings.plural}</Heading>
        </CardActionsHeader>
        <CardBody>
          <CrudList<Charge>
            dataType={DataType.Charge}
            listId={OtherListIds.AccountCharges}
            params={queryParams}
            relationships={relationships}
          >
            <CrudListHeader hideCreateLink />
            <CrudListTable columns={columns} />
            <CrudListFooter />
          </CrudList>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};
