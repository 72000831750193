import { DataType } from '@m3ter-com/m3ter-api';

import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { createCrudRoute } from '@/routes/crud';
import { CompoundAggregationsRoute } from '@/components/routes/compound-aggregations/CompoundAggregationsRoute';
import { CompoundAggregationsListRoute } from '@/components/routes/compound-aggregations/CompoundAggregationsListRoute';

const LazyCompoundAggregationDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CompoundAggregationDetailsRoute" */ '@/components/routes/compound-aggregations/CompoundAggregationDetailsRoute'
  ).then((module) => ({ default: module.CompoundAggregationDetailsRoute }))
);

const LazyCompoundAggregationCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CompoundAggregationCreateRoute" */ '@/components/routes/compound-aggregations/CompoundAggregationCreateRoute'
  ).then((module) => ({ default: module.CompoundAggregationCreateRoute }))
);

const LazyCompoundAggregationEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "CompoundAggregationEditRoute" */ '@/components/routes/compound-aggregations/CompoundAggregationEditRoute'
  ).then((module) => ({ default: module.CompoundAggregationEditRoute }))
);

export default () =>
  createCrudRoute({
    path: 'compound-aggregations',
    dataType: DataType.CompoundAggregation,
    element: <CompoundAggregationsRoute />,
    list: <CompoundAggregationsListRoute />,
    details: <LazyCompoundAggregationDetailsRoute />,
    create: <LazyCompoundAggregationCreateRoute />,
    edit: <LazyCompoundAggregationEditRoute />,
  });
