import React, { useCallback, useMemo, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';
import { HStack, Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';

import { DataType, AccountPlan, ChildBillingMode } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack, RadioTabs } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormField,
  FormRadioGroup,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import planAttachmentSchema from '@/validation/planAttachment';
import planGroupAttachmentSchema from '@/validation/planGroupAttachment';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { FormDatePicker } from '@/components/forms/FormDatePicker/FormDatePicker';
import {
  DataTypeFormEntitySelect,
  FormEntitySelect,
} from '@/components/forms/FormEntitySelect/FormEntitySelect';
import { FormCustomFieldsEditorDefaults } from '@/components/forms/FormCustomFieldsEditorDefaults/FormCustomFieldsEditorDefaults';
import { AccountPlanDateFields } from '@/components/features/accounts/AccountPlanDateFields/AccountPlanDateFields';
import { AccountPlanGroupDateFields } from '@/components/features/accounts/AccountPlanGroupDateFields/AccountPlanGroupDateFields';
import { AccountContractField } from '@/components/features/accounts/AccountContractField/AccountContractField';
import { dataTypeRetrieveQuery } from '@/queries/crud';
import { ErrorAlert } from '@/components/common/errors/ErrorAlert/ErrorAlert';

interface AccountPlanFormValues extends AccountPlan {
  productId: string;
}

export interface AccountPlanFormProps
  extends BaseFormProps<AccountPlanFormValues> {}

enum AttachType {
  Plan = 'plan',
  PlanGroup = 'planGroup',
}

interface AccountPlanPlanSelectProps {
  isEdit: boolean;
  attachType: AttachType;
  onAttachTypeChange: (newType: AttachType) => void;
}

// Must be a separate component so that we can use useWatch
const AccountPlanPlanSelect: React.FC<AccountPlanPlanSelectProps> = ({
  isEdit,
  attachType,
  onAttachTypeChange,
}) => {
  const { t } = useTranslation();

  const { setValue } = useFormContext();

  const accountId = useWatch({ name: 'accountId' });
  const productId = useWatch({ name: 'productId' });

  const onTypeChange = useCallback(
    (newType: AttachType) => {
      // When switching type, we need to clear the other selection.
      if (newType === AttachType.Plan) {
        setValue('planGroupId', null);
      } else {
        setValue('planId', null);
        setValue('productId', null);
      }
      onAttachTypeChange(newType);
    },
    [onAttachTypeChange, setValue]
  );

  const planParams = useMemo(
    () => ({
      productId,
      accountId: ['', accountId], // Matches the account ID or global. Order seems to matter.
    }),
    [productId, accountId]
  );

  const planGroupParams = useMemo(
    () => ({
      // Matches the account ID or global. Order seems to matter.
      accountId: ['', accountId],
    }),
    [accountId]
  );

  const typeOptions = [
    {
      value: AttachType.Plan,
      label: t('common:plan'),
      content: (
        <FormSection
          heading={t('common:entityDetails', {
            entityName: t('features:plans.planAttachment'),
          })}
        >
          <HStack w="100%" spacing={4} align="flex-start">
            <FormField
              isRequired
              name="productId"
              label={t('common:product')}
              control={
                FormEntitySelect as DataTypeFormEntitySelect<DataType.Product>
              }
              dataType={DataType.Product}
              accessor="name"
              detailAccessor="code"
            />
            <FormField
              isRequired
              name="planId"
              label={t('common:plan')}
              control={
                FormEntitySelect as DataTypeFormEntitySelect<DataType.Plan>
              }
              dataType={DataType.Plan}
              params={planParams}
              isDisabled={!productId}
              accessor="name"
              detailAccessor="code"
            />
          </HStack>
          <AccountPlanDateFields checkOverlap={!isEdit} />
        </FormSection>
      ),
    },
    {
      value: AttachType.PlanGroup,
      label: t('common:planGroup'),
      content: (
        <FormSection
          heading={t('common:entityDetails', {
            entityName: t('features:planGroups.planGroupAttachment'),
          })}
        >
          <FormField
            isRequired
            name="planGroupId"
            label={t('common:planGroup')}
            control={
              FormEntitySelect as DataTypeFormEntitySelect<DataType.PlanGroup>
            }
            dataType={DataType.PlanGroup}
            params={planGroupParams}
            accessor="name"
            detailAccessor="code"
          />
          <AccountPlanGroupDateFields />
        </FormSection>
      ),
    },
  ];

  return (
    <RadioTabs
      options={typeOptions}
      value={attachType}
      onChange={onTypeChange}
      isDisabled={isEdit}
      w="100%"
    />
  );
};

const defaultInitialValues: Partial<AccountPlan> = {};

export const AccountPlanForm: React.FC<AccountPlanFormProps> = ({
  initialValues = defaultInitialValues,
  isEdit,
  isSaving,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();
  const pathParams = useOrgPathParams();
  const [attachType, setAttachType] = useState<AttachType>(
    initialValues.planGroupId ? AttachType.PlanGroup : AttachType.Plan
  );

  const { contractId } = initialValues;
  const isPlanAttachType = attachType === AttachType.Plan;

  const { singular, singularLower } = useMemo(() => {
    const name = isPlanAttachType
      ? t('features:plans.planAttachment')
      : t('features:planGroups.planGroupAttachment');
    return { singularLower: name.toLowerCase(), singular: name };
  }, [t, isPlanAttachType]);

  const {
    data: contract,
    error: contractLoadingError,
    isLoading: isLoadingContract,
  } = useQuery(
    dataTypeRetrieveQuery(
      { dataType: DataType.Contract, id: contractId, pathParams },
      { enabled: !!contractId }
    )
  );

  const childBillingModeOptions = useMemo(
    () =>
      Object.values(ChildBillingMode).map((value) => ({
        value,
        label: t(`features:account.childBillingMode.${value}`),
      })),
    [t]
  );

  return (
    <React.Fragment>
      {contractLoadingError && <ErrorAlert error={contractLoadingError} />}
      <Form
        initialValues={initialValues}
        onSubmit={onSave}
        validationSchema={
          isPlanAttachType ? planAttachmentSchema : planGroupAttachmentSchema
        }
      >
        <FormStack>
          <AccountPlanPlanSelect
            isEdit={!!isEdit}
            attachType={attachType}
            onAttachTypeChange={setAttachType}
          />
          <FormSection
            isOptional
            heading={t('forms:labels.entitySettings', { entityName: singular })}
          >
            <FormField
              stripTime
              name="billEpoch"
              label={t('forms:labels.billingCycleDate')}
              helpText={t('forms:helpText.accountPlanBillEpoch')}
              control={FormDatePicker}
            />
            {isLoadingContract ? (
              <Spinner />
            ) : (
              <AccountContractField name="contractId" contract={contract} />
            )}
            <FormField
              stacked
              name="childBillingMode"
              label={t('forms:labels.accountChildBillingMode')}
              helpText={t('forms:helpText.childBillingMode')}
              control={FormRadioGroup}
              options={childBillingModeOptions}
            />
          </FormSection>
          <FormCustomFieldsEditorDefaults dataType={DataType.AccountPlan} />
          <FormActions
            cancelText={t('common:cancel')}
            submitText={
              isEdit
                ? t('forms:buttons.updateEntity', { entityName: singularLower })
                : t('forms:buttons.createEntity', { entityName: singularLower })
            }
            isSaving={isSaving}
            onCancel={onCancel}
          />
        </FormStack>
      </Form>
    </React.Fragment>
  );
};
