import { LoadingErrorContentSwitch } from '@/components/common/errors/LoadingErrorContentSwitch/LoadingErrorContentSwitch';
import { useIntegrationSystemsContext } from '@/components/features/integrations/IntegrationSystemsContext/IntegrationSystemsContext';
import { IntegrationSystemsGrid } from '@/components/features/integrations/IntegrationSystemsGrid/IntegrationSystemsGrid';

export const IntegrationSystemsListRoute: React.FC = () => {
  const { error, isLoading } = useIntegrationSystemsContext();

  return (
    <LoadingErrorContentSwitch error={error} isLoading={isLoading}>
      <IntegrationSystemsGrid />
    </LoadingErrorContentSwitch>
  );
};
