import { Fragment } from 'react';

import { Outlet } from 'react-router-dom';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { RouteWrapper } from '@/components/routes/helpers/RouteWrapper/RouteWrapper';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { IntegrationsNavigation } from '@/components/common/navigation/sections/IntegrationsNavigation/IntegrationsNavigation';

export const IntegrationsRoute: React.FC = () => {
  const { t } = useTranslation();

  return (
    <RouteWrapper
      element={
        <Fragment>
          <BreadcrumbItem title={t('common:integrations')} />
          <Outlet />
        </Fragment>
      }
      navigation={<IntegrationsNavigation />}
    />
  );
};
