import { Fragment, useCallback, useMemo } from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BoltIcon, KeyRoundIcon } from 'lucide-react';

import {
  DataType,
  IntegrationConfig,
  IntegrationConfigDestinationSchema,
  IntegrationCredential,
} from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import {
  Button,
  CopyToClipboard,
  DataTable,
  DataTableColumnDefinition,
} from '@m3ter-com/ui-components';

import { CrudRouteType, getCrudRouteName } from '@/routes/crud';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import useEntityDeleteMutation from '@/hooks/data/useEntityDeleteMutation';
import { useIntegrationSystemsContext } from '@/components/features/integrations/IntegrationSystemsContext/IntegrationSystemsContext';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { EntityCrudActions } from '@/components/common/data/EntityCrudActions';

import { IntegrationSystemCreateConfigMenu } from './IntegrationSystemCreateConfigMenu';

export interface IntegrationSystemDetailsProps {
  systemSchema: IntegrationConfigDestinationSchema;
}

export const IntegrationSystemDetails: React.FC<
  IntegrationSystemDetailsProps
> = ({ systemSchema }) => {
  const { t } = useTranslation();
  const credentialNamings = useEntityNamings(DataType.IntegrationCredential);
  const { toShortDate } = useDateFormatter();

  const { configs, credentials } = useIntegrationSystemsContext();

  const systemCredentials = useMemo(
    () =>
      credentials.filter(
        (credential) => credential.destination === systemSchema.apiName
      ),
    [credentials, systemSchema]
  );

  const systemConfigs = useMemo(
    () =>
      configs.filter(
        (config) =>
          config.destination.toLowerCase() ===
          systemSchema.destination.toLowerCase()
      ),
    [configs, systemSchema]
  );

  const { deleteEntity: deleteCredential } = useEntityDeleteMutation(
    DataType.IntegrationCredential
  );
  const onDeleteCredential = useCallback(
    (credential: IntegrationCredential) => {
      deleteCredential({
        entity: credential,
        pathParams: { destination: credential.destination.toLowerCase() },
      });
    },
    [deleteCredential]
  );

  const credentialsColumnDefinitions = useMemo<
    Array<DataTableColumnDefinition<IntegrationCredential>>
  >(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (credential) => (
          <Fragment>
            <Text as="span">{credential.name}</Text>
            <br />
            <Text as="span" variant="annotation">
              {t('common:id')}:{' '}
              <CopyToClipboard value={credential.id}>
                {credential.id}
              </CopyToClipboard>
            </Text>
          </Fragment>
        ),
      },
      {
        id: 'dtCreated',
        header: t('common:createdDate'),
        accessor: (item) => toShortDate(item.dtCreated),
      },
      {
        id: 'delete',
        header: '',
        accessor: (item) => (
          <EntityCrudActions<IntegrationCredential>
            dataType={DataType.IntegrationCredential}
            item={item}
            onDelete={onDeleteCredential}
          />
        ),
      },
    ],
    [onDeleteCredential, t, toShortDate]
  );

  const { deleteEntity: deleteConfig } = useEntityDeleteMutation(
    DataType.Integration
  );
  const onDeleteConfig = useCallback(
    (integration: IntegrationConfig) => {
      deleteConfig({ entity: integration });
    },
    [deleteConfig]
  );
  const configsColumnDefinitions = useMemo<
    Array<DataTableColumnDefinition<IntegrationConfig>>
  >(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (integration) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.Integration}
            id={integration.id}
          >
            {integration.name || '-'}
          </Link>
        ),
      },
      {
        id: 'entity-type',
        header: t('forms:labels.entityType'),
        accessor: 'entityType',
      },
      {
        id: 'actions',
        header: '',
        accessor: (integration) => (
          <EntityCrudActions
            addReturnPath
            dataType={DataType.Integration}
            editRouteName={getCrudRouteName(
              DataType.Integration,
              CrudRouteType.Edit
            )}
            item={integration}
            onDelete={onDeleteConfig}
          />
        ),
      },
    ],
    [onDeleteConfig, t]
  );

  return (
    <VStack alignItems="stretch" justifyContent="flex-start" spacing={4}>
      <Card>
        <CardHeader
          alignItems="center"
          display="flex"
          flexFlow="row nowrap"
          justifyContent="space-between"
        >
          <HStack alignItems="center" spacing={2} justifyContent="flex-start">
            <KeyRoundIcon size={20} />
            <Heading as="h3" size="md">
              {credentialNamings.plural}
            </Heading>
          </HStack>
          <Button
            size="sm"
            as={CrudCreateLink}
            addReturnPath
            dataType={DataType.IntegrationCredential}
            queryParams={{ destination: systemSchema.apiName }}
          >
            {t('features:integrations.configureNewCredentials')}
          </Button>
        </CardHeader>
        <CardBody>
          {systemCredentials.length > 0 ? (
            <DataTable
              columnDefinitions={credentialsColumnDefinitions}
              idAccessor="id"
              items={systemCredentials}
            />
          ) : (
            <Text textAlign="center" variant="annotation">
              {t('errors:generic.noDataToDisplay', {
                entityName: credentialNamings.pluralLower,
              })}
            </Text>
          )}
        </CardBody>
      </Card>
      <Card>
        <CardHeader
          alignItems="center"
          display="flex"
          flexFlow="row nowrap"
          justifyContent="space-between"
        >
          <HStack alignItems="center" spacing={2} justifyContent="flex-start">
            <BoltIcon size={20} />
            <Heading as="h3" size="md">
              {t('features:integrations.configurations')}
            </Heading>
          </HStack>
          <IntegrationSystemCreateConfigMenu systemSchema={systemSchema} />
        </CardHeader>
        <CardBody>
          {systemConfigs.length > 0 ? (
            <DataTable
              columnDefinitions={configsColumnDefinitions}
              idAccessor="id"
              items={systemConfigs}
            />
          ) : (
            <Text textAlign="center" variant="annotation">
              {t('errors:generic.noDataToDisplay', {
                entityName: t(
                  'features:integrations.configurations'
                ).toLowerCase(),
              })}
            </Text>
          )}
        </CardBody>
      </Card>
    </VStack>
  );
};
