import { PropsWithChildren } from 'react';

import { Page, PageProps } from '@m3ter-com/ui-components';

import { Breadcrumbs } from '@/components/common/breadcrumbs/Breadcrumbs/Breadcrumbs';

export interface PageWithBreadcrumbsProps
  extends Omit<PageProps, 'breadcrumbs'> {}

export const PageWithBreadcrumbs: React.FC<
  PropsWithChildren<PageWithBreadcrumbsProps>
> = (pageProps) => {
  return <Page {...pageProps} breadcrumbs={<Breadcrumbs />} />;
};
