import { ReactElement, ReactNode, useMemo } from 'react';

import {
  StyleProps,
  useMultiStyleConfig,
  chakra,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import { Columns3Icon } from 'lucide-react';

import type {
  ColumnDisplay,
  ColumnDisplayProps,
  SelectionProps,
  SortProps,
} from '../../../types/tables';
import type { ColumnDefinition, DataGridTranslations } from './types';

import { getAccessorValue, type Accessor } from '../../../utils/data';
import useSelection from '../../../hooks/useSelection';
import useColumnDisplay from '../../../hooks/useColumnDisplay';
import { Button } from '../../controls/Button/Button';
import { SelectionControl } from '../../controls/SelectionControl/SelectionControl';

import { getColumnJustify, getColumnWidth } from './utils';
import { DataGridColumnSettings } from './DataGridColumnSettings';
import { DataGridColumnHeader } from './DataGridColumnHeader';
import { DataGridRow } from './DataGridRow';
import { DataGridCell } from './DataGridCell';

const defaultTranslations: DataGridTranslations = {
  columns: 'Columns',
  expand: 'Expand',
  collapse: 'Collapse',
};

export interface DataGridProps<D>
  extends SelectionProps<D>,
    ColumnDisplayProps,
    SortProps,
    StyleProps {
  items: ReadonlyArray<D>;
  columnDefinitions: Array<ColumnDefinition<D>>;
  idAccessor: Accessor<D, string>;
  isLoading?: boolean;
  emptyContent?: ReactNode;
  detailsContent?: Accessor<D, ReactNode>;
  translations?: DataGridTranslations;
}

export { ColumnDisplay, ColumnDefinition };

export function DataGrid<D>({
  columnDefinitions,
  columnDisplay,
  idAccessor,
  items,
  isLoading = false,
  emptyContent,
  selectionType,
  selectedItems,
  enableColumnOrdering,
  enableColumnToggling,
  sortColumn,
  sortDescending,
  detailsContent,
  translations = defaultTranslations,
  onSelectedItemsChange,
  isItemDisabled,
  onColumnDisplayChange,
  onSortChange,
  ...styleProps
}: DataGridProps<D>): ReactElement<any, any> {
  const styles = useMultiStyleConfig('DataGrid', {});

  const { getSelectAllControlProps, getSelectItemControlProps } = useSelection({
    items,
    idAccessor,
    selectionType,
    selectedItems,
    onSelectedItemsChange,
    isItemDisabled,
  });

  const { visibleColumns, updatedColumnDisplay, columnOptions } =
    useColumnDisplay(columnDefinitions, columnDisplay);

  const gridColumns = useMemo(
    () =>
      [
        ...(selectionType !== undefined || detailsContent !== undefined
          ? ['max-content']
          : []),
        ...visibleColumns.map(getColumnWidth),
        'auto', // Final column to take up any remaining space.
      ].join(' '),
    [visibleColumns, selectionType, detailsContent]
  );

  const { isOpen: isColumnSettingsOpen, onToggle: onToggleColumnSettings } =
    useDisclosure();

  return (
    <chakra.div __css={styles.wrapper} {...styleProps}>
      <chakra.div
        __css={styles.grid}
        gridTemplateColumns={gridColumns}
        role="grid"
      >
        <chakra.div __css={styles.head} role="row">
          {selectionType !== undefined && (
            <DataGridCell isPinnedFirst role="columnheader">
              {selectionType === 'multi' && (
                <SelectionControl
                  selectionType={selectionType}
                  {...getSelectAllControlProps()}
                />
              )}
            </DataGridCell>
          )}
          {detailsContent !== undefined && <DataGridCell role="columnheader" />}
          {visibleColumns.map((columnDefinition) => (
            <DataGridColumnHeader
              key={columnDefinition.id}
              columnDefinition={columnDefinition}
              sortColumn={sortColumn}
              sortDescending={sortDescending}
              onSortChange={onSortChange}
              isPinnedFirst={columnDefinition.isPinnedFirst}
              isPinnedLast={columnDefinition.isPinnedLast}
              justifyContent={getColumnJustify(columnDefinition)}
            />
          ))}
          {/* Extra cell to take up remaining space */}
          <DataGridCell p={0} border={0} role="columnheader" />
        </chakra.div>
        {!isLoading && items.length === 0 && emptyContent && (
          <chakra.div __css={styles.empty}>{emptyContent}</chakra.div>
        )}
        {items.map((item) => {
          const id = getAccessorValue(item, idAccessor);

          return (
            <DataGridRow
              key={id}
              columnDefinitions={visibleColumns}
              item={item}
              selectionType={selectionType}
              selectionControlProps={getSelectItemControlProps(item)}
              detailsContent={detailsContent}
              translations={translations}
            />
          );
        })}
      </chakra.div>
      {isLoading && (
        <chakra.div __css={styles.loading}>
          <Spinner size="lg" />
        </chakra.div>
      )}
      {(enableColumnOrdering || enableColumnToggling) && (
        <chakra.div __css={styles.sidebar}>
          <Button
            size="sm"
            sx={styles.sidebarButton}
            isActive={isColumnSettingsOpen}
            onClick={onToggleColumnSettings}
            leftIcon={<Columns3Icon size={16} />}
          >
            {translations.columns}
          </Button>
          {isColumnSettingsOpen && (
            <chakra.div __css={styles.settings}>
              <DataGridColumnSettings
                columnOptions={columnOptions}
                enableOrdering={enableColumnOrdering}
                enableToggling={enableColumnToggling}
                value={updatedColumnDisplay}
                onChange={onColumnDisplayChange}
              />
            </chakra.div>
          )}
        </chakra.div>
      )}
    </chakra.div>
  );
}
