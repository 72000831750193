import React, { ChangeEventHandler, useCallback, useMemo } from 'react';

import {
  Box,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Spinner,
  useMultiStyleConfig,
  useDisclosure,
  useFormControl,
} from '@chakra-ui/react';

import { Input, InputProps } from '../Input/Input';

export interface AutocompleteInputProps
  extends Omit<InputProps, 'onChange' | 'value'> {
  isLoading?: boolean;
  onChange: (newValue: string) => void;
  options: Array<string>;
  value?: string;
}

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  id,
  isLoading = false,
  onChange,
  options,
  value = '',
  ...inputProps
}) => {
  const {
    isOpen: isMenuOpen,
    onClose: closeMenu,
    onOpen: openMenu,
  } = useDisclosure();

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  const visibleOptions = useMemo(() => {
    if (!value) {
      return options;
    }

    return options.filter((option) =>
      option.toLowerCase().startsWith(value.toLowerCase())
    );
  }, [options, value]);

  const { id: formControlId } = useFormControl({});
  const inputId = id || formControlId;
  const listId = `${inputId}-list`;
  const showMenu = isMenuOpen && visibleOptions.length > 0;
  const styles = useMultiStyleConfig('AutocompleteInput', {});

  return (
    <Box sx={styles.wrapper}>
      <InputGroup>
        <Input
          aria-autocomplete="list"
          aria-controls={listId}
          aria-expanded={showMenu}
          autoComplete="off"
          id={id}
          onBlur={closeMenu}
          onChange={handleChange}
          onFocus={openMenu}
          role="combobox"
          value={value}
          {...inputProps}
        />
        {isLoading && (
          <InputRightElement>
            <Spinner
              size="sm"
              color="chakra-subtle-text"
              data-testid="loading"
            />
          </InputRightElement>
        )}
      </InputGroup>
      <List id={listId} role="listbox" sx={showMenu ? styles.list : undefined}>
        {showMenu &&
          visibleOptions.map((option) => (
            <ListItem
              key={option}
              onMouseDown={() => {
                onChange(option);
                closeMenu();
              }}
              role="option"
              sx={styles.listItem}
            >
              {option}
            </ListItem>
          ))}
      </List>
    </Box>
  );
};
