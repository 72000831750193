import { useCallback, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import {
  buildSearchQuery,
  parseSearchQuery,
  SearchCriterion,
  SearchOperator,
  SearchSortOrder,
} from '@m3ter-com/m3ter-api';

const OPERATOR_PARAM = 'operator';
const SORT_BY_PARAM = 'sortBy';
const SORT_ORDER_PARAM = 'sortOrder';
const QUERY_PARAM = 'query';

// If we move to a router that supports param validation and type-safety
// we can hoist this up to the route level (where it belongs), and make the
// search params the source of truth. In the meantime, this stores and
// retrieves search and sort query params.
const useEntitySearchListQueryParams = (
  defaultSortBy: string | undefined,
  defaultSortOrder: SearchSortOrder
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortBy, setSortBy] = useState(
    searchParams.get(SORT_BY_PARAM) ?? defaultSortBy
  );
  const [sortOrder, setSortOrder] = useState(
    (searchParams.get(SORT_ORDER_PARAM) as SearchSortOrder) ?? defaultSortOrder
  );
  const [criteria, setCriteriaInternal] = useState<Array<SearchCriterion>>(
    () => {
      const param = searchParams.get(QUERY_PARAM);
      return param ? parseSearchQuery(param) : [];
    }
  );
  const [operator, setOperatorInternal] = useState<SearchOperator>(
    (searchParams.get(OPERATOR_PARAM) as SearchOperator) ?? SearchOperator.Or
  );

  const setSort = useCallback(
    (newSortBy: string, newSortOrder: SearchSortOrder) => {
      setSortBy(newSortBy);
      setSortOrder(newSortOrder);

      setSearchParams(
        (prev) => {
          prev.set(SORT_BY_PARAM, newSortBy);
          prev.set(SORT_ORDER_PARAM, newSortOrder);
          return prev;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const setCriteria = useCallback(
    (newCriteria: Array<SearchCriterion>) => {
      setCriteriaInternal(newCriteria);

      setSearchParams(
        (prev) => {
          if (newCriteria.length > 0) {
            prev.set(QUERY_PARAM, buildSearchQuery(newCriteria));
          } else {
            prev.delete(QUERY_PARAM);
          }
          return prev;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const setOperator = useCallback(
    (newOperator: SearchOperator) => {
      setOperatorInternal(newOperator);
      setSearchParams(
        (prev) => {
          prev.set(OPERATOR_PARAM, newOperator);
          return prev;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  return {
    criteria,
    operator,
    sortBy,
    sortOrder,
    setCriteria,
    setOperator,
    setSort,
  };
};

export default useEntitySearchListQueryParams;
