import { useMemo } from 'react';

import sortBy from 'lodash/sortBy';

import { ExternalMappingConfig } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { SelectOption } from '@m3ter-com/ui-components';
import {
  FormCreatableSelect,
  FormField,
  FormSelect,
} from '@m3ter-com/console-core/components';

export interface IntegrationsTableSelectProps {
  externalMappingConfig: ExternalMappingConfig;
  label: string;
  name: string;
  selectedSystem?: string;
}

export const IntegrationsTableSelect: React.FC<
  IntegrationsTableSelectProps
> = ({ externalMappingConfig, label, name, selectedSystem }) => {
  const { t } = useTranslation();

  const allowCustomTable = useMemo<boolean>(() => {
    if (!selectedSystem || selectedSystem === 'm3ter') {
      return false;
    }

    return (
      (externalMappingConfig.externalSystems || []).find(
        (system) => system.name === selectedSystem
      )?.allowCustomExternalTables || false
    );
  }, [externalMappingConfig, selectedSystem]);

  const options = useMemo<Array<SelectOption>>(() => {
    if (!selectedSystem) {
      return [];
    }

    let result: Array<SelectOption>;
    if (selectedSystem === 'm3ter') {
      result = externalMappingConfig.m3terEntities.map<SelectOption>(
        (m3terTable) => ({
          label: t(`features:externalMappings.entityTypes.${m3terTable}`),
          value: m3terTable,
        })
      );
    } else {
      result =
        (externalMappingConfig.externalSystems || [])
          .find((system) => system.name === selectedSystem)
          ?.tables.map<SelectOption>((tableName) => ({
            label: tableName,
            value: tableName,
          })) || [];
    }

    return sortBy(result, (option) => option.label.toLowerCase());
  }, [externalMappingConfig, selectedSystem, t]);

  return (
    <FormField
      isRequired
      name={name}
      label={label}
      isDisabled={!selectedSystem}
      control={allowCustomTable ? FormCreatableSelect : FormSelect}
      options={options}
    />
  );
};
