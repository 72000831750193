import { Fragment, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { getAvailableIntegrationDestinationSchemas } from '@/util/integrations';
import { useIntegrationSystemsContext } from '@/components/features/integrations/IntegrationSystemsContext/IntegrationSystemsContext';
import { BreadcrumbItem } from '@/components/common/breadcrumbs/BreadcrumbItem/BreadcrumbItem';
import { IntegrationSystemDetails } from '@/components/features/integrations/IntegrationSystemDetails/IntegrationSystemDetails';
import { ExternalSystemLogo } from '@/components/common/brand/ExternalSystemLogo/ExternalSystemLogo';

export const IntegrationSystemDetailsRoute: React.FC = () => {
  const { system } = useParams();
  const { configSchema } = useIntegrationSystemsContext();

  const systemSchema = useMemo(
    () =>
      !!system &&
      getAvailableIntegrationDestinationSchemas(configSchema).find(
        (destinationSchema) => destinationSchema.apiName === system
      ),
    [configSchema, system]
  );

  if (!system || !systemSchema) {
    return null;
  }

  return (
    <Fragment>
      <BreadcrumbItem hasNoRoute title="" />
      <ExternalSystemLogo
        height="40px"
        mb={4}
        mt={-3}
        system={systemSchema.apiName}
      />
      <IntegrationSystemDetails systemSchema={systemSchema} />
    </Fragment>
  );
};
