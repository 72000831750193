import { useCallback } from 'react';

import { Entity } from '@m3ter-com/m3ter-api';

import { CrudQueryParams } from '@/types/routes';

import useQueryString from '@/hooks/navigation/useQueryString';

export type RedirectTo<E extends Entity> = string | ((entity: E) => string);

const useEntityRedirect = <E extends Entity>(
  defaultRedirectTo: RedirectTo<E>
) => {
  const { returnPath: queryParamsReturnPath } =
    useQueryString<CrudQueryParams>();

  // Returns either a return path from the query string or one specified
  // by `defaultReturnTo`, which is passed the entity if it's a function.
  const getReturnPath = useCallback(
    (entity: E) =>
      queryParamsReturnPath ??
      (typeof defaultRedirectTo === 'function'
        ? defaultRedirectTo(entity)
        : defaultRedirectTo),
    [queryParamsReturnPath, defaultRedirectTo]
  );

  return { queryParamsReturnPath, getReturnPath };
};

export default useEntityRedirect;
