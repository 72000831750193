import uniqBy from 'lodash/uniqBy';

import {
  IntegrationConfig,
  IntegrationConfigDestinationSchema,
  IntegrationConfigEntitySchema,
  IntegrationConfigParameterSchema,
  IntegrationConfigParameterType,
  IntegrationConfigSchema,
  IntegrationRunLogEntry,
  IntegrationRunLogEntryType,
  IntegrationRunLogHttpEntry,
  IntegrationRunLogMessageEntry,
} from '@m3ter-com/m3ter-api';

import { isProd } from '@/config';

export const getAvailableIntegrationEntities = (
  integrationConfigSchema: IntegrationConfigSchema
) => {
  // Integrations for some entity types are a WIP and are marked as such with a
  // 'released' field.
  // We show those as options when not in prod.
  const showUnreleased = !isProd();
  return Object.entries(integrationConfigSchema.supportedEntities)
    .filter(
      (entries): entries is [string, IntegrationConfigEntitySchema] =>
        !!entries[1] && (entries[1].released || showUnreleased)
    )
    .map(([entityType, entitySchema]) => ({
      type: entityType,
      schema: entitySchema,
    }));
};

export const getAvailableIntegrationEntitiesByDestination = (
  integrationConfigSchema: IntegrationConfigSchema,
  destinationName: string
) => {
  // Integrations for some entity types/ destinations are a WIP and are marked as such with a
  // 'released' field.
  // We show those as options when not in prod.
  const showUnreleased = !isProd();
  return getAvailableIntegrationEntities(integrationConfigSchema).filter(
    (entity) => {
      if (!entity.schema.released && !showUnreleased) {
        return false;
      }
      const mathcingEntityDestinations = entity.schema.destinations.filter(
        (destinationSchema) =>
          destinationSchema.apiName === destinationName &&
          (destinationSchema.released || showUnreleased)
      );
      return mathcingEntityDestinations.length > 0;
    }
  );
};

export const getAvailableIntegrationDestinationSchemas = (
  integrationConfigSchema: IntegrationConfigSchema
): Array<IntegrationConfigDestinationSchema> => {
  // Integrations for some entity types/ destinations are a WIP and are marked as such with a
  // 'released' field.
  // We show those as options when not in prod.
  // Destinations are also repeated for each supported entity type so we de-duplicate by the
  // 'apiName' field.
  const showUnreleased = !isProd();
  return uniqBy(
    getAvailableIntegrationEntities(integrationConfigSchema)
      .flatMap((entity) => entity.schema.destinations)
      .filter(
        (destinationSchema) =>
          destinationSchema.destination.toLowerCase() !== 'webhook' &&
          destinationSchema.destination.toLowerCase() !== 'workflow' &&
          (destinationSchema.released || showUnreleased)
      ),
    'apiName'
  );
};

export const getRenderableIntegrationParameterSchemas = (
  allParameterSchemas: Array<IntegrationConfigParameterSchema>
): Array<IntegrationConfigParameterSchema> => {
  // If the backend is updated to add new parameter types, we don't want the UI to try handling them
  // until we've added support for them so we filter them out here.
  const renderableParameterTypes = Object.values(
    IntegrationConfigParameterType
  );
  return allParameterSchemas.filter((parameterSchema) =>
    renderableParameterTypes.includes(parameterSchema.type)
  );
};

export const integrationAccessor = (integration: IntegrationConfig) =>
  integration.name || '-';

export const integrationDetailsAccessor = (integration: IntegrationConfig) =>
  integration.name || `${integration.entityType} - ${integration.destination}`;

export const isIntegrationRunLogHttpEntry = (
  runLogEntry: IntegrationRunLogEntry
): runLogEntry is IntegrationRunLogHttpEntry =>
  runLogEntry.type === IntegrationRunLogEntryType.Http;

export const isIntegrationRunLogMessageEntry = (
  runLogEntry: IntegrationRunLogEntry
): runLogEntry is IntegrationRunLogMessageEntry =>
  runLogEntry.type === IntegrationRunLogEntryType.Message;
