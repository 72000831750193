import React from 'react';

import { Badge, StyleProps } from '@chakra-ui/react';

import { IntegrationRunStatus } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';

export interface IntegrationRunStatusBadgeProps
  extends Omit<StyleProps, 'color' | 'colorScheme'> {
  status: IntegrationRunStatus;
}

export const IntegrationRunStatusBadge: React.FC<
  IntegrationRunStatusBadgeProps
> = ({ status, ...styleProps }) => {
  const { t } = useTranslation();

  let colorSchema: string | undefined;
  switch (status) {
    case IntegrationRunStatus.Complete:
      colorSchema = 'green';
      break;
    case IntegrationRunStatus.AwaitingRetry:
    case IntegrationRunStatus.Started:
    case IntegrationRunStatus.Waiting:
      colorSchema = 'yellow';
      break;
    case IntegrationRunStatus.AccountingPeriodClosed:
    case IntegrationRunStatus.AuthFailed:
    case IntegrationRunStatus.Disabled:
    case IntegrationRunStatus.Error:
    case IntegrationRunStatus.InvoiceAlreadyPaid:
      colorSchema = 'red';
      break;
    default:
    // No-op
  }

  return (
    <Badge colorScheme={colorSchema} {...styleProps}>
      {t(`features:integrations.integrationRunStatus.${status}`)}
    </Badge>
  );
};
