import { Fragment, useMemo } from 'react';

import { useWatch } from 'react-hook-form';

import { ExternalMappingConfig } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormStack } from '@m3ter-com/ui-components';
import {
  Form,
  FormActions,
  FormSection,
} from '@m3ter-com/console-core/components';

import { BaseFormProps } from '@/types/forms';

import { createStepOne } from '@/validation/externalMappings';
import { IntegrationsSystemSelect } from '@/components/features/integrations/IntegrationsSystemSelect/IntegrationsSystemSelect';
import { IntegrationsTableSelect } from '@/components/features/integrations/IntegrationsTableSelect/IntegrationsTableSelect';

import { ExternalMappingsCreateFormValues } from './useExternalMappingsCreate';

export type ExternalMappingsCreateFormSettingsStepValues = Pick<
  ExternalMappingsCreateFormValues,
  'externalSystem' | 'externalTable' | 'm3terEntity'
>;

interface ExternalMappingsCreateFormSettingsStepFieldsProps {
  externalMappingConfig: ExternalMappingConfig;
}

export interface ExternalMappingsCreateFormSettingsStepProps
  extends BaseFormProps<ExternalMappingsCreateFormSettingsStepValues> {
  externalMappingConfig: ExternalMappingConfig;
}

const ExternalMappingsCreateFormSettingsStepFields: React.FC<
  ExternalMappingsCreateFormSettingsStepFieldsProps
> = ({ externalMappingConfig }) => {
  const { t } = useTranslation();

  const selectedExternalSystem: string | undefined = useWatch({
    name: 'externalSystem',
  });

  return (
    <Fragment>
      <IntegrationsTableSelect
        name="m3terEntity"
        label={t('forms:labels.entityType')}
        externalMappingConfig={externalMappingConfig}
        selectedSystem="m3ter"
      />
      <IntegrationsSystemSelect
        name="externalSystem"
        label={t('forms:labels.externalSystem')}
        externalMappingConfig={externalMappingConfig}
      />
      <IntegrationsTableSelect
        name="externalTable"
        label={t('forms:labels.externalTable')}
        externalMappingConfig={externalMappingConfig}
        selectedSystem={selectedExternalSystem}
      />
    </Fragment>
  );
};

export const ExternalMappingsCreateFormSettingsStep: React.FC<
  ExternalMappingsCreateFormSettingsStepProps
> = ({ externalMappingConfig, initialValues, onCancel, onSave }) => {
  const { t } = useTranslation();

  const defaultedInitialValues = useMemo(
    () => ({ ...initialValues }),
    [initialValues]
  );

  return (
    <Form
      initialValues={defaultedInitialValues}
      onSubmit={onSave}
      validationSchema={createStepOne}
    >
      <FormStack>
        <FormSection
          heading={t('forms:labels.entitySettings', {
            entityName: t('common:externalMapping'),
          })}
        >
          <ExternalMappingsCreateFormSettingsStepFields
            externalMappingConfig={externalMappingConfig}
          />
        </FormSection>
        <FormActions
          cancelText={t('common:cancel')}
          onCancel={onCancel}
          submitText={t('common:next')}
        />
      </FormStack>
    </Form>
  );
};
