import React, { useCallback, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useFormContext, useWatch } from 'react-hook-form';
import { HStack } from '@chakra-ui/react';

import { DataType, Bill } from '@m3ter-com/m3ter-api';
import { useTranslation } from '@m3ter-com/console-core/hooks';
import { FormField, FormSelect } from '@m3ter-com/console-core/components';

import { dataTypeListAllQuery } from '@/queries/crud';
import useDateFormatter from '@/hooks/util/useDateFormatter';
import useCurrencies from '@/hooks/util/useCurrencies';
import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import { useCrudRetrieveData } from '@/components/common/crud/CrudRetrieveContext/CrudRetrieveContext';

interface BillLineItemSelectProps {
  updateAmount?: boolean;
}

export const BillLineItemSelect: React.FC<BillLineItemSelectProps> = ({
  updateAmount = true,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useCurrencies();
  const { toLongDate } = useDateFormatter();

  const invoiceDateAccessor = useCallback(
    (bill: Bill) => toLongDate(bill.externalInvoiceDate),
    [toLongDate]
  );

  const bill = useCrudRetrieveData<Bill>(DataType.Bill);
  const { accountId } = bill;

  const pathParams = useOrgPathParams();

  const { data: bills, isLoading } = useQuery(
    dataTypeListAllQuery({
      dataType: DataType.Bill,
      pathParams,
      queryParams: { accountId },
    })
  );

  const billOptions = useMemo(
    () =>
      bills
        ? bills.map((b) => ({ value: b.id, label: invoiceDateAccessor(b) }))
        : [],
    [bills, invoiceDateAccessor]
  );

  const referencedBillId = useWatch({ name: 'referencedBillId' });

  const referencedBill = useMemo(
    () =>
      referencedBillId && bills
        ? bills.find(({ id }) => id === referencedBillId)
        : undefined,
    [referencedBillId, bills]
  );

  const lineItemOptions = useMemo(
    () =>
      referencedBill
        ? (referencedBill.lineItems || []).map((item) => ({
            value: item.id,
            label: `${item.description} - ${formatCurrency(
              item.convertedSubtotal,
              referencedBill.currency
            )}`,
          }))
        : [],
    [referencedBill, formatCurrency]
  );

  const { setValue } = useFormContext();

  const referencedLineItemId = useWatch({ name: 'referencedLineItemId' });
  useEffect(() => {
    // When the line item changes, update the amount value.
    if (updateAmount && referencedBill && referencedLineItemId) {
      const lineItem = referencedBill.lineItems?.find(
        ({ id }) => id === referencedLineItemId
      );
      if (lineItem) {
        setValue('amount', lineItem.convertedSubtotal);
      }
    }
  }, [updateAmount, referencedBill, referencedLineItemId, setValue]);

  return (
    <HStack w="100%" alignItems="flex-start">
      <FormField
        isRequired
        name="referencedBillId"
        label={t('common:bill')}
        isLoading={isLoading}
        control={FormSelect}
        options={billOptions}
      />
      <FormField
        isRequired
        name="referencedLineItemId"
        label={t('features:billing.lineItem')}
        isDisabled={!referencedBillId}
        control={FormSelect}
        options={lineItemOptions}
      />
    </HStack>
  );
};
