import { NamedRoute } from '@/types/routes';

import { lazyWithRetry } from '@/routes/lazyWithRetry';

const LazyPricingSegmentsEditorRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "PricingSegmentsEditor" */ '@/components/routes/pricing/editor/PricingSegmentsEditorRoute'
  ).then((module) => ({ default: module.PricingSegmentsEditorRoute }))
);

export default (): NamedRoute => ({
  path: 'segments',
  name: 'pricing.editor.segments',
  element: <LazyPricingSegmentsEditorRoute />,
});
