import React, { useMemo } from 'react';

import { Heading, HStack, Tag, Tooltip } from '@chakra-ui/react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { ReleaseStatus, useBreadcrumbsState } from '@/state/breadcrumbs';

export const PageTitle: React.FC = () => {
  const { t } = useTranslation();
  const breadcrumbs = useBreadcrumbsState();

  const lastBreadcrumb = useMemo(() => {
    // Filter out hidden breadcrumbs because we don't want to show them as the title either.
    const breadcrumbsToShow = breadcrumbs.filter(
      ({ hideInTrail }) => !hideInTrail
    );
    return breadcrumbsToShow.length > 0
      ? breadcrumbsToShow[breadcrumbsToShow.length - 1]
      : undefined;
  }, [breadcrumbs]);

  return lastBreadcrumb ? (
    <HStack spacing={4}>
      <Heading size="lg">{lastBreadcrumb.title}</Heading>
      {lastBreadcrumb.releaseStatus === ReleaseStatus.Preview && (
        <Tooltip hasArrow label={t('common:previewReleaseDescription')}>
          <Tag colorScheme="blue" verticalAlign="middle" borderRadius="full">
            {t('common:preview')}
          </Tag>
        </Tooltip>
      )}
    </HStack>
  ) : null;
};
