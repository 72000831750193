import { create, enforce, test } from 'vest';
import i18next from 'i18next';

import { Charge } from '@m3ter-com/m3ter-api';

const suite = create('charge', (data: Partial<Charge>) => {
  test('name', i18next.t('forms:validations.common.nameRequired'), () => {
    enforce(data.name).isNotEmpty();
  });

  test('code', i18next.t('forms:validations.common.codeRequired'), () => {
    enforce(data.code).isNotEmpty();
  });

  test(
    'servicePeriodEndDate',
    i18next.t('forms:validations.common.servicePeriodEndDateRequired'),
    () => {
      enforce(data.servicePeriodEndDate).isNotEmpty();
    }
  );

  test(
    'servicePeriodStartDate',
    i18next.t('forms:validations.common.servicePeriodStartDateRequired'),
    () => {
      enforce(data.servicePeriodStartDate).isNotEmpty();
    }
  );

  test(
    'currency',
    i18next.t('forms:validations.common.currencyRequired'),
    () => {
      enforce(data.currency).isNotEmpty();
    }
  );

  test(
    'currency',
    i18next.t('forms:validations.common.currencyRequired'),
    () => {
      enforce(data.currency).isNotEmpty();
    }
  );

  test('amount', i18next.t('forms:validations.common.amountRequired'), () => {
    enforce(data.amount).isNotEmpty();
  });
});

export default suite;
