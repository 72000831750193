import { DataType } from '@m3ter-com/m3ter-api';

import { createCrudRoute } from '@/routes/crud';
import { lazyWithRetry } from '@/routes/lazyWithRetry';
import { AggregationsListRoute } from '@/components/routes/aggregations/AggregationsListRoute';
import { AggregationsRoute } from '@/components/routes/aggregations/AggregationsRoute';

const LazyAggregationDetailsRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "AggregationDetailsRoute" */ '@/components/routes/aggregations/AggregationDetailsRoute'
  ).then((module) => ({ default: module.AggregationDetailsRoute }))
);

const LazyAggregationCreateRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "AggregationCreateRoute" */ '@/components/routes/aggregations/AggregationCreateRoute'
  ).then((module) => ({ default: module.AggregationCreateRoute }))
);

const LazyAggregationEditRoute = lazyWithRetry(() =>
  import(
    /* webpackChunkName: "AggregationEditRoute" */ '@/components/routes/aggregations/AggregationEditRoute'
  ).then((module) => ({ default: module.AggregationEditRoute }))
);

export default () =>
  createCrudRoute({
    path: 'aggregations',
    dataType: DataType.Aggregation,
    element: <AggregationsRoute />,
    list: <AggregationsListRoute />,
    details: <LazyAggregationDetailsRoute />,
    create: <LazyAggregationCreateRoute />,
    edit: <LazyAggregationEditRoute />,
  });
