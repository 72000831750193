import { AnyAction } from '@reduxjs/toolkit';
import { call, put, StrictEffect, take, takeEvery } from 'redux-saga/effects';

import { DataType, AccountPlan, Commitment } from '@m3ter-com/m3ter-api';

import { OtherListIds } from '@/types/lists';

import { retrieveData } from '@/store/data/data.saga';
import { refreshList, updateItem } from '@/store/crud';
import { updateItemSaga } from '@/store/crud/crud.saga';
import {
  addEntityLink,
  addEntityLinkFailure,
  AddEntityLinkFailureAction,
  addEntityLinkSuccess,
  AddEntityLinkSuccessAction,
} from '@/store/utils/linkEntity';

import {
  addAccountPlansToContract,
  addAccountPlansToContractComplete,
  addCommitmentsToContract,
  addCommitmentsToContractComplete,
  AddAccountPlansToContractAction,
  AddCommitmentsToContractAction,
  RemoveAccountPlanFromContractAction,
  RemoveCommitmentFromContractAction,
  removeAccountPlanFromContract,
  removeCommitmentFromContract,
} from './accountContracts';

export function* addAccountPlansToContractSaga(
  action: AddAccountPlansToContractAction
): Generator<StrictEffect, void, any> {
  const { contractId, accountPlanIds } = action.payload;
  const successNotification = action.meta.onSuccess.notification;

  yield put(
    addEntityLink(
      DataType.Contract,
      contractId,
      DataType.AccountPlan,
      accountPlanIds,
      successNotification
    )
  );

  // Wait for the failure / success action from linking the account plans and contract.
  const addEntityLinkResultAction:
    | AddEntityLinkFailureAction
    | AddEntityLinkSuccessAction = yield take(
    (awaitedAction: AnyAction) =>
      (awaitedAction.type === addEntityLinkFailure.type ||
        awaitedAction.type === addEntityLinkSuccess.type) &&
      awaitedAction.payload.parentDataType === DataType.Contract &&
      awaitedAction.payload.childDataType === DataType.AccountPlan
  );

  // If we succesfully linked the account plans to the contract, refresh the contract plans list
  if (addEntityLinkResultAction.type === addEntityLinkSuccess.type) {
    yield put(
      refreshList(DataType.AccountPlan, OtherListIds.ContractAccountPlans)
    );
  }

  yield put(addAccountPlansToContractComplete());
}

export function* addCommitmentsToContractSaga(
  action: AddCommitmentsToContractAction
): Generator<StrictEffect, void, any> {
  const { contractId, commitmentIds } = action.payload;
  const successNotification = action.meta.onSuccess.notification;
  yield put(
    addEntityLink(
      DataType.Contract,
      contractId,
      DataType.Commitment,
      commitmentIds,
      successNotification
    )
  );

  // Wait for the failure / success action from linking the commitments and contract.
  const addEntityLinkResultAction:
    | AddEntityLinkFailureAction
    | AddEntityLinkSuccessAction = yield take(
    (awaitedAction: AnyAction) =>
      (awaitedAction.type === addEntityLinkFailure.type ||
        awaitedAction.type === addEntityLinkSuccess.type) &&
      awaitedAction.payload.parentDataType === DataType.Contract &&
      awaitedAction.payload.childDataType === DataType.Commitment
  );

  // If we succesfully linked the commitments to the contract, refresh the contract commitments list.
  if (addEntityLinkResultAction.type === addEntityLinkSuccess.type) {
    yield put(
      refreshList(DataType.Commitment, OtherListIds.ContractCommitments)
    );
  }

  yield put(addCommitmentsToContractComplete());
}

export function* removeAccountPlanFromContractSaga(
  action: RemoveAccountPlanFromContractAction
): Generator<StrictEffect, void, any> {
  const { accountPlanId } = action.payload;
  const successNotification = action.meta.onSuccess.notification;
  const failureNotification = action.meta.onFailure.notification;

  try {
    const accountPlan: AccountPlan = yield call(
      retrieveData,
      DataType.AccountPlan,
      accountPlanId
    );
    // Call the updateItemSaga directly so that this saga is blocked until the update is complete
    yield call(
      updateItemSaga,
      updateItem(
        DataType.AccountPlan,
        accountPlanId,
        {
          ...accountPlan,
          contractId: null,
        },
        successNotification,
        failureNotification
      )
    );

    yield put(
      refreshList(DataType.AccountPlan, OtherListIds.ContractAccountPlans)
    );
  } catch {
    // No-op
  }
}

export function* removeCommitmentFromContractSaga(
  action: RemoveCommitmentFromContractAction
): Generator<StrictEffect, void, any> {
  const { commitmentId } = action.payload;
  const successNotification = action.meta.onSuccess.notification;
  const failureNotification = action.meta.onFailure.notification;

  try {
    const commitment: Commitment = yield call(
      retrieveData,
      DataType.Commitment,
      commitmentId
    );
    // Call the updateItemSaga directly so that this saga is blocked until the update is complete
    yield call(
      updateItemSaga,
      updateItem(
        DataType.Commitment,
        commitmentId,
        {
          ...commitment,
          contractId: null,
        },
        successNotification,
        failureNotification
      )
    );

    yield put(
      refreshList(DataType.Commitment, OtherListIds.ContractCommitments)
    );
  } catch {
    // No-op
  }
}

export default function* accountContractsSaga() {
  yield takeEvery(
    addAccountPlansToContract.type,
    addAccountPlansToContractSaga
  );
  yield takeEvery(addCommitmentsToContract.type, addCommitmentsToContractSaga);
  yield takeEvery(
    removeAccountPlanFromContract.type,
    removeAccountPlanFromContractSaga
  );
  yield takeEvery(
    removeCommitmentFromContract.type,
    removeCommitmentFromContractSaga
  );
}
