import React, { ComponentProps } from 'react';

import { IconButton } from '@chakra-ui/react';
import { RefreshCwIcon } from 'lucide-react';

import { useTranslation } from '@m3ter-com/console-core/hooks';

import { useCrudListContext } from './CrudListContext';

export interface CrudListRefreshButtonProps
  extends Omit<ComponentProps<typeof IconButton>, 'aria-label' | 'icon'> {}

export const CrudListRefreshButton: React.FC<CrudListRefreshButtonProps> = ({
  ...styleProps
}) => {
  const { t } = useTranslation();
  const { isLoading, refreshList } = useCrudListContext();

  return (
    <IconButton
      aria-label={t('common:refresh')}
      icon={<RefreshCwIcon size={16} />}
      isDisabled={isLoading}
      onClick={refreshList}
      {...styleProps}
    />
  );
};
