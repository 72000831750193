import { useCallback, KeyboardEvent, useState, useMemo } from 'react';

import {
  Box,
  Flex,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  StackProps,
} from '@chakra-ui/react';

import {
  SearchComparator,
  SearchCriterion,
  SearchOperator,
} from '@m3ter-com/m3ter-api';
import { Button, Select, SelectOption } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { SearchFieldDefinition, SearchFieldType } from '@/types/search';

import { getComparatorSelectOptions, getValidComparators } from '@/util/search';
import { EntitySearchListFilter } from '@/components/common/data/EntitySeachList/EntitySearchListFilter';

export interface EntitySearchListSearchProps extends StackProps {
  criteria: Array<SearchCriterion>;
  onCriteriaChange: (criteria: Array<SearchCriterion>) => void;
  operator: SearchOperator;
  onOperatorChange: (operator: SearchOperator) => void;
  placeholder: string;
  searchFieldDefinitions: Array<SearchFieldDefinition>;
}

const QUICK_SEARCH = '__qs';

export const EntitySearchListSearch: React.FC<EntitySearchListSearchProps> = ({
  criteria,
  onCriteriaChange,
  operator,
  onOperatorChange,
  placeholder,
  searchFieldDefinitions,
  ...stackProps
}) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const quickSearchFields = useMemo(
    () =>
      searchFieldDefinitions
        .filter(({ includeInQuickSearch }) => includeInQuickSearch)
        .map(({ field }) => field),
    [searchFieldDefinitions]
  );

  const onInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    []
  );

  const quickSearch = useCallback(
    (query: string) => {
      onCriteriaChange(
        quickSearchFields.map((field) => ({
          field,
          comparator: SearchComparator.Contains,
          value: query,
        }))
      );
    },
    [onCriteriaChange, quickSearchFields]
  );

  const [field, setField] = useState(QUICK_SEARCH);
  const [comparator, setComparator] = useState(SearchComparator.Contains);

  const getSearchFieldType = useCallback(
    (fieldName: string) =>
      searchFieldDefinitions.find(
        (definition) => definition.field === fieldName
      )?.type ?? SearchFieldType.String,
    [searchFieldDefinitions]
  );

  const onFieldChange = useCallback(
    (fieldName: string | null) => {
      if (fieldName) {
        setField(fieldName);
        // Reset the comparator to the first of the valid options.
        setComparator(getValidComparators(getSearchFieldType(fieldName))[0]);
      }
    },
    [getSearchFieldType]
  );

  const comparatorOptions = useMemo(
    () => getComparatorSelectOptions(getSearchFieldType(field)),
    [getSearchFieldType, field]
  );

  const fieldOptions = useMemo<Array<SelectOption>>(
    () => [
      {
        value: QUICK_SEARCH,
        label: t('features:search.quickSearch'),
      },
      ...searchFieldDefinitions.map((definition) => ({
        value: definition.field,
        label: definition.label,
      })),
    ],
    [searchFieldDefinitions, t]
  );

  const onInputKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter' && inputValue !== '') {
        if (field === QUICK_SEARCH) {
          quickSearch(inputValue);
        } else {
          onCriteriaChange([
            ...criteria,
            { field, comparator, value: inputValue },
          ]);
        }
        setInputValue('');
      }
    },
    [inputValue, field, comparator, criteria, onCriteriaChange, quickSearch]
  );

  const onRemove = useCallback(
    (index: number) => {
      onCriteriaChange(criteria.filter((_criterion, i) => i !== index));
    },
    [onCriteriaChange, criteria]
  );

  const onUpdate = useCallback(
    (index: number, updatedCriterion: SearchCriterion) => {
      onCriteriaChange(
        criteria.map((criterion, i) =>
          i === index ? updatedCriterion : criterion
        )
      );
    },
    [onCriteriaChange, criteria]
  );

  const onClearAll = useCallback(() => {
    onCriteriaChange([]);
  }, [onCriteriaChange]);

  const isQuickSearch = field === QUICK_SEARCH;

  return (
    <Stack {...stackProps}>
      <HStack spacing={1}>
        <Box w="225px" flexShrink={0}>
          <Select
            options={fieldOptions}
            value={field}
            onChange={onFieldChange}
            aria-label={t('features:search.field')}
          />
        </Box>
        {!isQuickSearch && (
          <Box w="225px" flexShrink={0}>
            <Select
              options={comparatorOptions}
              value={comparator}
              onChange={
                setComparator as (value: SearchComparator | null) => void
              }
              aria-label={t('features:search.comparator')}
            />
          </Box>
        )}
        <Input
          placeholder={isQuickSearch ? placeholder : undefined}
          value={inputValue}
          onChange={onInputChange}
          onKeyDown={onInputKeyDown}
          aria-label={t('common:query')}
        />
      </HStack>
      {criteria.length > 0 && (
        <Flex flexWrap="wrap" gap={2} alignItems="center">
          <RadioGroup
            value={operator}
            onChange={onOperatorChange}
            mr={4}
            isDisabled={criteria.length < 2}
          >
            <HStack>
              <Radio value={SearchOperator.Or}>{t('features:search.or')}</Radio>
              <Radio value={SearchOperator.And}>
                {t('features:search.and')}
              </Radio>
            </HStack>
          </RadioGroup>
          {criteria.map((criterion, i) => (
            <EntitySearchListFilter
              key={i} // eslint-disable-line react/no-array-index-key
              criterion={criterion}
              searchFieldDefinitions={searchFieldDefinitions}
              onRemove={() => {
                onRemove(i);
              }}
              onUpdate={(updatedCriterion) => {
                onUpdate(i, updatedCriterion);
              }}
            />
          ))}
          <Button
            onClick={onClearAll}
            size="sm"
            colorScheme="blue"
            variant="subtle"
            marginLeft={4}
          >
            {t('features:search.clearAllFilters')}
          </Button>
        </Flex>
      )}
    </Stack>
  );
};
