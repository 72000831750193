import React from 'react';

import { Charge } from '@m3ter-com/m3ter-api';

import { CrudEdit } from '@/components/common/crud/CrudEdit';
import { ChargeForm } from '@/components/features/accounts/ChargeForm/ChargeForm';

export const ChargesEditRoute: React.FC = () => (
  <CrudEdit<Charge> form={ChargeForm} />
);
