import { useCallback, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { DataType, ListOptions } from '@m3ter-com/m3ter-api';

import useListSizePreference from '@/hooks/data/useListSizePreference';
import useEntityListQuery from '@/hooks/data/useEntityListQuery';

const QUERY_PARAM = 'query';

interface UseEntityListOptions<DT extends DataType> extends ListOptions<DT> {
  searchFields?: Array<string>;
}

const useEntityList = <DT extends DataType>({
  dataType,
  actionName,
  pathParams,
  queryParams,
  relationships,
  searchFields,
}: UseEntityListOptions<DT>) => {
  const listId = `${dataType}-list`;
  const { listSize: pageSize, onListSizeChange: onPageSizeChange } =
    useListSizePreference(listId);

  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState<string | undefined>(
    () => searchParams.get(QUERY_PARAM) ?? undefined
  );

  const search = useCallback(
    (newQuery: string | undefined) => {
      setQuery(newQuery);
      setSearchParams(
        (prev) => {
          if (newQuery) {
            prev.set(QUERY_PARAM, newQuery);
          } else {
            prev.delete(QUERY_PARAM);
          }
          return prev;
        },
        { replace: true }
      );
    },
    [setSearchParams]
  );

  const {
    currentPage,
    currentPageData = [],
    error,
    goToNextPage,
    goToPage,
    hasMore,
    isFetching,
    isFetchingNextPage,
    isLoading,
    knownPageCount,
    refetch,
  } = useEntityListQuery({
    dataType,
    actionName,
    pathParams,
    queryParams,
    pageSize,
    query,
    relationships,
    searchFields,
  });

  return {
    currentPage,
    currentPageData,
    error,
    goToNextPage,
    goToPage,
    hasMore,
    isFetching,
    isFetchingNextPage,
    isLoading,
    knownPageCount,
    onPageSizeChange,
    pageSize,
    refetch,
    search,
    query,
  };
};

export default useEntityList;
