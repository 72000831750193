import { keepPreviousData } from '@tanstack/react-query';

import {
  DataType,
  DataTypeToEntity,
  list,
  listAll,
  ListOptions,
  ListResponse,
  UnknownEntity,
} from '@m3ter-com/m3ter-api';

import useOrgPathParams from '@/hooks/data/useOrgPathParams';
import useCursorPaginatedQuery from '@/hooks/data/useCursorPaginatedQuery';

interface SearchListOptions<DT extends DataType> extends ListOptions<DT> {
  query?: string;
  searchFields?: Array<string>;
}

interface UseEntityListOptions<DT extends DataType>
  extends SearchListOptions<DT> {
  pageSize?: number;
}

const listOrFrontEndSearch = async <DT extends DataType>({
  query,
  searchFields,
  ...listOptions
}: SearchListOptions<DT>): Promise<ListResponse<DataTypeToEntity[DT]>> => {
  if (query && searchFields && searchFields.length > 0) {
    // Front-end search.

    // Remove page size because we want to use the maximum when using list all.
    const { pageSize: _, ...queryParams } = listOptions.queryParams ?? {};

    const allData = await listAll({ ...listOptions, queryParams });
    const matchingData = allData.data.filter((item) =>
      searchFields.some((field) => {
        const value = (item as UnknownEntity)[field]
          ? `${(item as UnknownEntity)[field]}`
          : undefined;
        return !!value && value.toLowerCase().includes(query.toLowerCase());
      })
    );

    return {
      data: matchingData,
    };
  }

  // Default to just using `list`.
  return list(listOptions);
};

const useEntityListQuery = <DT extends DataType>({
  dataType,
  actionName,
  pathParams,
  queryParams,
  relationships,
  pageSize,
  query,
  searchFields,
}: UseEntityListOptions<DT>) => {
  const pathParamsWithOrg = useOrgPathParams(pathParams);

  const queryParamsWithPageSize = {
    ...queryParams,
    pageSize,
  };

  return useCursorPaginatedQuery({
    queryFn: ({ pageParam }) =>
      listOrFrontEndSearch({
        dataType,
        pathParams: pathParamsWithOrg,
        queryParams: { ...queryParamsWithPageSize, nextToken: pageParam },
        relationships,
        query,
        searchFields,
      }),
    queryKey: [
      dataType,
      'list',
      actionName,
      pathParamsWithOrg,
      queryParamsWithPageSize,
      query,
      relationships,
      searchFields,
    ],
    placeholderData: keepPreviousData,
  });
};

export default useEntityListQuery;
