import { create, enforce, omitWhen, test } from 'vest';
import i18next from 'i18next';

import { AggregationFrequency, SourceType } from '@m3ter-com/m3ter-api';

import { ExportAdhocFormValues } from '@/components/features/data-exports/ExportScheduleForm/ExportScheduleForm';

const suite = create(
  'adHocExportSchedule',
  (data: Partial<ExportAdhocFormValues>) => {
    omitWhen(!data.dateRange?.startDate || !data.dateRange?.endDate, () => {
      test(
        'dateRange',
        i18next.t('forms:validations.dataExport.startDateBeforeEndDate'),
        () => {
          enforce(new Date(data.dateRange?.startDate!).getTime()).lessThan(
            new Date(data.dateRange?.endDate!).getTime()
          );
        }
      );
    });

    omitWhen(data.sourceType === SourceType.Usage, () => {
      test(
        'operationalDataTypes',
        i18next.t('forms:validations.dataExport.operationalDataTypesRequired'),
        () => {
          enforce(data.operationalDataTypes).isNotEmpty();
        }
      );

      omitWhen(!data.dateRange?.endDate, () => {
        test(
          'dateRange',
          i18next.t('forms:validations.dataExport.endDateBeforeNow'),
          () => {
            enforce(
              new Date(data.dateRange?.endDate!).getTime()
            ).lessThanOrEquals(Date.now());
          }
        );
      });
    });

    omitWhen(
      data.sourceType === SourceType.Operational ||
        data.aggregationFrequency === AggregationFrequency.Original,
      () => {
        test(
          'aggregation',
          i18next.t('forms:validations.dataExport.aggregationRequired'),
          () => {
            enforce(data.aggregation).isNotEmpty();
          }
        );
      }
    );

    omitWhen(data.sourceType === SourceType.Operational, () => {
      test(
        'dateRange',
        i18next.t('forms:validations.common.startDateRequired'),
        () => {
          enforce(data.dateRange?.startDate).isNotNull();
        }
      );

      test(
        'dateRange',
        i18next.t('forms:validations.common.endDateRequired'),
        () => {
          enforce(data.dateRange?.endDate).isNotNull();
        }
      );
    });
  }
);

export default suite;
