import { NamedRoute } from '@/types/routes';

import { IntegrationSystemsRoute } from '@/components/routes/integrations/systems/IntegrationSystemsRoute';
import { IntegrationSystemsListRoute } from '@/components/routes/integrations/systems/IntegrationSystemsListRoute';
import { IntegrationSystemDetailsRoute } from '@/components/routes/integrations/systems/IntegrationSystemDetailsRoute';

export default (): NamedRoute => ({
  name: 'integrations.systems',
  path: 'systems',
  element: <IntegrationSystemsRoute />,
  children: [
    {
      name: 'integrations.systems-list',
      index: true,
      element: <IntegrationSystemsListRoute />,
    },
    {
      name: 'integrations.system',
      path: ':system',
      element: <IntegrationSystemDetailsRoute />,
    },
  ],
});
