import { useMemo } from 'react';

import { Link } from '@chakra-ui/react';

import {
  Account,
  DataType,
  EntityWithRelationships,
} from '@m3ter-com/m3ter-api';
import { Button } from '@m3ter-com/ui-components';
import { useTranslation } from '@m3ter-com/console-core/hooks';

import { SearchFieldDefinition, SearchFieldType } from '@/types/search';

import useDateFormatter from '@/hooks/util/useDateFormatter';
import useEntityNamings from '@/hooks/util/useEntityNamings';
import {
  EntitySearchList,
  ColumnDefinition,
} from '@/components/common/data/EntitySeachList/EntitySearchList';
import { CrudDetailsLink } from '@/components/common/navigation/CrudDetailsLink/CrudDetailsLink';
import { EntityActions } from '@/components/common/data/EntityActions/EntityActions';
import { CrudCreateLink } from '@/components/common/navigation/CrudCreateLink/CrudCreateLink';

const relationships = ['parentAccount'];

export const AccountsBrowseRoute: React.FC = () => {
  const { t } = useTranslation();
  const { singularLower } = useEntityNamings(DataType.Account);

  const { toLongDateTime } = useDateFormatter();

  const columns = useMemo<Array<ColumnDefinition<Account>>>(
    () => [
      {
        id: 'name',
        header: t('forms:labels.name'),
        accessor: (account) => (
          <Link
            as={CrudDetailsLink}
            dataType={DataType.Account}
            id={account.id}
          >
            {account.name}
          </Link>
        ),
        isSortable: true,
        alwaysVisible: true,
      },
      {
        id: 'code',
        header: t('forms:labels.code'),
        accessor: 'code',
        isSortable: true,
      },
      {
        id: 'currency',
        header: t('common:currency'),
        accessor: 'currency',
        isSortable: true,
        defaultVisible: false,
      },
      {
        id: 'purchaseOrderNumber',
        header: t('forms:labels.purchaseOrderNumber'),
        accessor: 'purchaseOrderNumber',
        isSortable: true,
        defaultVisible: false,
      },
      {
        id: 'parentAccount',
        header: t('forms:labels.parentAccount'),
        accessor: (account: EntityWithRelationships<DataType.Account>) =>
          !!account.parentAccount && (
            <Link
              as={CrudDetailsLink}
              dataType={DataType.Account}
              id={account.parentAccountId}
            >
              {account.parentAccount.name}
            </Link>
          ),
        defaultVisible: false,
      },
      {
        id: 'dtCreated',
        header: t('common:created'),
        accessor: (account) => toLongDateTime(account.dtCreated),
        isSortable: true,
      },
      {
        id: 'dtLastModified',
        header: t('common:lastModified'),
        accessor: (account) => toLongDateTime(account.dtLastModified),
        isSortable: true,
      },
      {
        id: 'actions',
        header: t('common:actions'),
        accessor: (account) => (
          <EntityActions
            dataType={DataType.Account}
            entity={account}
            nameAccessor="name"
          />
        ),
        shrink: true,
        isPinnedLast: true,
        alwaysVisible: true,
      },
    ],
    [t, toLongDateTime]
  );

  const searchFieldDefinitions = useMemo<Array<SearchFieldDefinition>>(
    () => [
      { type: SearchFieldType.Id, field: 'id', label: t('common:id') },
      {
        field: 'name',
        label: t('forms:labels.name'),
        includeInQuickSearch: true,
      },
      {
        field: 'code',
        label: t('forms:labels.code'),
        includeInQuickSearch: true,
      },
      { field: 'currency', label: t('forms:labels.currency') },
      {
        field: 'purchaseOrderNumber',
        label: t('forms:labels.purchaseOrderNumber'),
      },
    ],
    [t]
  );

  return (
    <EntitySearchList
      dataType={DataType.Account}
      columnDefinitions={columns}
      relationships={relationships}
      searchFieldDefinitions={searchFieldDefinitions}
      defaultSortBy="name"
      headerActions={
        <Button
          intent="primary"
          as={CrudCreateLink}
          dataType={DataType.Account}
        >
          {t('forms:buttons.createEntity', {
            entityName: singularLower,
          })}
        </Button>
      }
      styleProps={{ height: 'calc(100vh - 150px)' }}
    />
  );
};
