import type { ColumnDefinition } from './DataGrid';

export const getColumnWidth = (
  columnDefinition: ColumnDefinition<any>
): string => {
  if (columnDefinition.shrink) {
    return 'max-content';
  }
  if (columnDefinition.width) {
    // Create similar behaviour to a table cell, where the width is the suggested
    // width but it can grow if the content does to prevent overflow if wrapping
    // is enabled.
    return columnDefinition.wrap
      ? `${columnDefinition.width}px`
      : `minmax(${columnDefinition.width}px, max-content)`;
  }

  // Fall back to flexible columns, assuming equal widths if `flex` isn't set.
  // If wrapping is enabled this is set as the width. If not then it's effectively
  // the maximum width, with the content determining the minimum.
  const flex = columnDefinition.flex ?? 1;
  return columnDefinition.wrap ? `${flex}fr` : `minmax(max-content, ${flex}fr)`;
};

// Maps a column alignment to the required flex `justify-content` value.
export const getColumnJustify = (
  columnDefinition: ColumnDefinition<any>
): string | undefined => {
  switch (columnDefinition.align) {
    case 'left':
      return 'start';
    case 'center':
      return 'center';
    case 'right':
      return 'end';
    default:
      return undefined;
  }
};
